import { CMS_Content_Module_Anchor } from './types';
import React from 'react';

type AnchorContentModuleProps = CMS_Content_Module_Anchor['data'];

export const AnchorContentModule: React.FC<AnchorContentModuleProps> = (props) => {
  const { anchor } = props;
  if (!anchor) {
    console.warn('Anchor: No anchor defined');
    return null;
  }

  return <a id={anchor} className="cue-content-module-anchor"></a>;
};
