import { CMS_Content_Module_ACSData } from './types';
import { ACS } from '@cue/organisms';
import { useUser } from '@project/hooks/use-user';

export const ACSModule = (props: ACSModuleProps) => {
  const { token, meeting_link } = props;
  const { user } = useUser();

  if (!token) return <>NO TOKEN</>;
  if (!meeting_link) return <>NO MEETING LINK</>;

  return (
    <ACS
      userId={{ communicationUserId: '' }}
      token={token}
      displayName={user ? `${user.firstName} ${user.lastName}` : 'Unknown'}
      locator={meeting_link}
    />
  );
};

export type ACSModuleProps = CMS_Content_Module_ACSData;
