import { SurveyItemProps, SurveyQuestion } from './types';
import { ThemedIcon } from '@cue/atoms';
import { useTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react';

export const IconCards: React.FC<SurveyItemProps> = (props) => {
  const { options, selected, onChange } = props;

  return (
    <div className="cue-survey-cards-wrapper">
      {options.map((o, i) => (
        <IconCard
          options={o}
          key={i}
          selected={selected !== undefined && selected?.includes(o.value)}
          onChange={() => {
            onChange({
              value: o.value,
            });
          }}
        />
      ))}
    </div>
  );
};

const IconCard: React.FC<{ options: SurveyQuestion; selected: boolean; onChange: () => void }> = ({
  options,
  onChange,
  selected,
}) => {
  const [active, setActive] = useState(selected);
  const theme = useTheme();

  useEffect(() => {
    setActive(selected);
  }, [selected]);

  return (
    <div
      className={`cue-survey-icon-card${active ? ' is-selected' : ''}`}
      onClick={() => {
        onChange();
        setActive(!active);
      }}>
      {options.icon && (
        <div className="cue-survey-icon-card-icon">
          {ThemedIcon({ icon: options.icon.component, theme })}
        </div>
      )}
      <div className="cue-survey-icon-card-title">{options.title}</div>
      {options.sub_title ? (
        <div className="cue-survey-icon-card-sub-title">{options.sub_title}</div>
      ) : null}
    </div>
  );
};
