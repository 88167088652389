import { Badge, Heading, Text } from '@cue/atoms';
import { ChatMessageProps as IChatMessageProps } from '@cue/hooks';
import styled, { CSSObject } from '@emotion/styled';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const ChatMessage: React.FC<ChatMessageProps> = (rest) => {
  const { t } = useTranslation();
  const { user, username, text, pending, rejectedReason, answers = [], isAnswer = false } = rest;
  const classes = ['cue-chat-message'];
  const isOwner = user === username;
  if (pending) classes.push('cue-chat-message-pending');
  if (rejectedReason) classes.push('cue-chat-message-rejected');
  if (isAnswer) classes.push('cue-chat-message-answer');
  if (isOwner) classes.push('cue-chat-message-owner');
  if (parent) classes.push('cue-chat-message-reply');

  return (
    <div
      className={`cue-chat-message-wrapper ${
        isOwner && !isAnswer ? 'cue-chat-message-wrapper-owner' : ''
      }`}>
      <ChatMessageContainer className={classes.join(' ')}>
        <div className="cue-chat-message-content">
          <div className="cue-chat-message-user">{username}</div>
          <div className="cue-chat-message-text">{text}</div>
          {answers.length > 0 ? (
            <div className="cue-chat-message-answers">
              {answers?.map((answer) => (
                <ChatMessage user={user} key={answer.id} {...answer} isAnswer></ChatMessage>
              ))}
            </div>
          ) : (
            <></>
          )}
          {(pending || (rejectedReason && !isAnswer)) && (
            <div
              className={`cue-chat-message-status ${
                pending ? 'cue-chat-message-status-pending' : ''
              } ${rejectedReason ? 'cue-chat-message-status-rejected' : ''}`}>
              <Badge
                value={
                  pending
                    ? t('chat.message.is-pending')
                    : rejectedReason
                    ? t(rejectedReason)
                    : 'Rejected'
                }
                styling={rejectedReason ? 'warning' : 'pending'}
                relativePosition
              />
            </div>
          )}
        </div>
      </ChatMessageContainer>
    </div>
  );
};

export type ChatMessageProps = Omit<IChatMessageProps, 'rev'> & {
  children?: ReactNode;
  user?: string;
};

export type ChatMessageStyleOverride = {
  '.cue-chat-message'?: CSSObject;
  '.cue-chat-message-text'?: CSSObject;
  '.cue-chat-message-pending'?: CSSObject;
  '.cue-chat-message-pending-text'?: CSSObject;
  '.cue-chat-message-rejected'?: CSSObject;
  '.cue-chat-message-higlight'?: CSSObject;
  '.cue-chat-message-owner'?: CSSObject;
  '.cue-chat-message-reply'?: CSSObject;
};

const ChatMessageContainer = styled.div``;

const PendingMessageContainer = styled.div({
  marginTop: '0.25rem',
});

// just for semantics
const RejectedReasonContainer = styled(PendingMessageContainer)({});
