import { ProgramEvent } from '@cue/api';
import { Dayjs, TimeUtil } from '@cue/utility';
import { LocationCategory } from '@project/cms/modules';
import { useEvents } from '@project/hooks/use-events';
import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

interface eventCallbackProps {
  slugs?: string | Array<string>;
}

interface currentEventProps extends eventCallbackProps {
  category?: LocationCategory['category']['slug'];
  categories?: LocationCategory['category']['slug'][];
  offset?: number;
}

type EventSlugs = string | Array<string> | undefined;
interface nextEventsProps extends currentEventProps, eventCallbackProps {
  number_of_events?: number;
  skip_events?: number;
  only_today?: boolean;
}

export const _useEventDataImpl = () => {
  const [inter, setInter] = useState(0);
  const { events: rawData, isLoading } = useEvents();

  // ADD startDisplay/endDisplay

  /*  const rawData = _rawData?.map((event) => ({
    ...event,
    startDisplay: event.start,
    endDisplay: event.end,
  })); */

  const now = TimeUtil.getNowUtc();

  const updateSession = () => {
    setInter(Math.random());
  };

  useEffect(() => {
    if (!isLoading) {
      updateSession();
      // Set here event program refresh time in milliseconds: (30 seconds = 30000 ms)
      const interval = setInterval(() => {
        updateSession();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  useEffect(() => {
    updateSession();
  }, [rawData]);

  const currentEvent = ({ slugs, offset, category, categories }: currentEventProps) => {
    if (!rawData) return null;

    return (
      filterSlug(
        rawData.filter(
          (entry: ProgramEvent) =>
            now.subtract(offset || 0).isAfter(entry.start) &&
            now.subtract(offset || 0).isBefore(entry.end)
        ),
        slugs,
        category || categories
      ) || null
    );
  };

  const nextEvents = ({
    slugs,
    number_of_events,
    skip_events,
    category,
    categories,
    only_today = true,
    offset = 0,
  }: nextEventsProps) => {
    if (!rawData) return null;

    const eventsDataFiltered = filterSlug(rawData, slugs, category || categories)
      .filter(
        (entry) =>
          (only_today
            ? TimeUtil.isToday(entry.start) && now.subtract(offset || 0).isBefore(entry.end)
            : true) && now.subtract(offset || 0).isBefore(entry.start)
      )
      .sort((s1, s2) => {
        if (s1.start > s2.start) {
          return 1;
        }
        if (s1.start < s2.start) {
          return -1;
        }
        return 0;
      });

    if (number_of_events) {
      return (
        eventsDataFiltered.slice(skip_events || 0, number_of_events + (skip_events || 0)) || null
      );
    }
    return eventsDataFiltered || null;
  };

  const firstEventToday = ({ slugs }: { slugs: EventSlugs }) => {
    const eventsToday: ProgramEvent[] | null = getEventsToday({ slugs });
    if (!eventsToday) return null;
    return eventsToday[0] || null;
  };

  const lastEventToday = ({ slugs }: { slugs: EventSlugs }) => {
    const eventsToday: ProgramEvent[] | null = getEventsToday({ slugs });
    if (!eventsToday) return null;
    return eventsToday[eventsToday.length - 1] || null;
  };

  const todayRange = ({
    slugs,
    category,
  }: {
    slugs: EventSlugs;
    category?: LocationCategory['category']['slug'] | LocationCategory['category']['slug'][];
  }) => {
    const eventsToday: ProgramEvent[] | null = getEventsToday({ slugs, category });
    if (!eventsToday) return null;

    if (!eventsToday[0]?.start || !eventsToday[eventsToday.length - 1]?.end) return null;

    return (
      [
        TimeUtil.getUtc(eventsToday[0].start),
        TimeUtil.getUtc(eventsToday[eventsToday.length - 1].end),
      ] || null
    );
  };

  const getEventsToday = ({
    slugs,
    category,
  }: {
    slugs: EventSlugs;
    category?: LocationCategory['category']['slug'] | LocationCategory['category']['slug'][];
  }) => {
    if (!rawData) return null;

    return (
      filterSlug(rawData, slugs, category)
        .filter((entry) => TimeUtil.isToday(entry.start))
        .sort((s1, s2) => {
          if (s1.start > s2.start) {
            return 1;
          }
          if (s1.start < s2.start) {
            return -1;
          }
          return 0;
        }) || null
    );
  };

  const filterSlug = (
    data: ProgramEvent[],
    slugs: EventSlugs,
    category?: LocationCategory['category']['slug'] | LocationCategory['category']['slug'][]
  ): ProgramEvent[] => {
    if (!slugs) return data;

    let ret = [];
    if (typeof slugs === 'string') {
      ret = data.filter((ev) => ev.eventSlug === slugs) || null;
    } else {
      ret = data.filter((ev) => slugs.includes(ev?.eventSlug as string)) || null;
    }
    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (category && category.length > 0) {
      if (Array.isArray(category)) {
        ret = ret.filter((ev) =>
          ev?.categories?.some((e) => {
            return category.includes(e.slug);
          })
        );
      } else {
        ret = ret.filter((ev) => ev?.categories?.some((e) => e.slug === category));
      }
    }

    return ret;
  };

  const getLastEvent = ({ slugs }: { slugs: EventSlugs }) => {
    if (!rawData) return null;
    return (
      filterSlug(rawData, slugs)
        .sort((s1, s2) => {
          if (s1.start > s2.start) {
            return 1;
          }
          if (s1.start < s2.start) {
            return -1;
          }
          return 0;
        })
        .pop() || null
    );
  };

  const getEvents = ({ slugs }: { slugs: EventSlugs }) => {
    if (!rawData) return null;
    return (
      filterSlug(rawData, slugs).sort((s1, s2) => {
        if (s1.start > s2.start) {
          return 1;
        }
        if (s1.start < s2.start) {
          return -1;
        }
        return 0;
      }) || null
    );
  };

  const getEventById = (id: string): ProgramEvent | null =>
    rawData?.find((event) => event.id === id) || null;

  return {
    rawData: rawData || [],
    inter,
    isLoading,
    currentEvent,
    nextEvents,
    firstEventToday,
    lastEventToday,
    getLastEvent,
    getEvents,
    getEventById,
    updateSession,
    loading: true,
    todayRange,
  };
};

const defaultState = {
  loading: true,
  updateSession: () => null,
  currentEvent: () => null,
  nextEvents: () => null,
  firstEventToday: () => null,
  lastEventToday: () => null,
  getLastEvent: () => null,
  getEvents: () => null,
  todayRange: () => null,
  getEventById: () => null,
  rawData: [],
  inter: 0,
};

export const useEventDataProvider = singletonHook<useEventDataReturn>(
  defaultState,
  _useEventDataImpl
);

export interface useEventDataReturn {
  rawData: ProgramEvent[];
  currentEvent(props?: currentEventProps): ProgramEvent[] | null;
  nextEvents(props?: nextEventsProps): ProgramEvent[] | null;
  firstEventToday(props?: eventCallbackProps): ProgramEvent | null;
  lastEventToday(props?: eventCallbackProps): ProgramEvent | null;
  getLastEvent({ slugs }: { slugs: string[] }): ProgramEvent | null;
  getEvents({ slugs }: { slugs: string[] }): ProgramEvent[] | null;
  getEventById(id: string): ProgramEvent | null;
  updateSession(): void;
  todayRange({
    slugs,
    category,
  }: {
    slugs: string[];
    category?: LocationCategory['category']['slug'] | LocationCategory['category']['slug'][];
  }): [Dayjs, Dayjs] | null;
  loading: boolean;
  inter: number;
}
