import { AgendaDisplayOptions } from '../agenda';
import { AgendaEventProps } from '../agenda-event';
import { fallBackImage } from './event-speaker';
import { SpeakerAgenda } from './speaker-agenda';
import { Avatar } from '@cue/atoms';
import { Modal, useModal } from '@cue/organisms';
import { Global } from '@emotion/react';
import { LocationCategory } from '@project/cms/modules/agenda';
import React from 'react';

interface SpeakerListProps {
  speakers: AgendaEventProps['persons'];
  agendaDisplayOptions?: AgendaDisplayOptions;
  locationCategories?: LocationCategory[];
  hideLocation?: boolean;
}

export const Speaker: React.FC<SpeakerProps> = ({
  speaker,
  agendaDisplayOptions,
  locationCategories,
  hideLocation,
}) => {
  const [modalRef] = useModal();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (isModalOpen) {
      modalRef.current?.open();
    } else {
      modalRef.current?.close();
    }
  }, [isModalOpen, modalRef]);
  if (!speaker) return null;

  return (
    <>
      {/* TODO: Move to core */}
      {isModalOpen && (
        <Global
          styles={{
            body: {
              overflowY: 'hidden',
            },
          }}
        />
      )}
      <Avatar
        speaker={{ ...speaker, image: speaker.profileImageURL || fallBackImage }}
        onClick={
          agendaDisplayOptions?.speakerAgenda
            ? () => {
                setIsModalOpen(true);
              }
            : undefined
        }
      />
      <Modal
        ref={modalRef}
        placement={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setIsModalOpen(false)}>
        <SpeakerAgenda
          speaker={speaker}
          locationCategories={locationCategories}
          hideLocation={hideLocation}
        />
      </Modal>
    </>
  );
};

interface SpeakerProps {
  // TODO: Fix Type
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  speaker: AgendaEventProps['persons'][0];
  agendaDisplayOptions?: AgendaDisplayOptions;
  locationCategories?: LocationCategory[];
  hideLocation?: boolean;
  /* eslint-enable @typescript-eslint/ban-ts-comment */
}

export const SpeakerList: React.FC<SpeakerListProps> = ({
  speakers,
  agendaDisplayOptions,
  locationCategories,
  hideLocation,
}) => {
  if (!speakers) return null;
  return (
    <ul>
      {speakers.map((s) => (
        <li key={s.id}>
          <Speaker
            speaker={s}
            agendaDisplayOptions={agendaDisplayOptions}
            locationCategories={locationCategories}
            hideLocation={hideLocation}
          />
        </li>
      ))}
    </ul>
  );
};
