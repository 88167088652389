import { LivestreamSourceProps } from '../livestream';
import { CMS_Content_Module_EventDisplayData } from './types';
import { Heading, LoadingSpinner } from '@cue/atoms';
import { StyledComponent } from '@cue/theme';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { useEventDataProvider } from '@project/hooks/use-event-data';
import {
  LivestreamStatusDefaultState,
  LivestreamStatusType,
  useLivestreamStatus,
} from '@project/hooks/use-livestream-status';
import { livestream_channels } from '@project/local/livestream.json';
import {
  AgendaDisplayOptions,
  AgendaEvent,
  AgendaEventProps,
  mergeCustomDisplayOptions,
} from '@project/site/modules/agenda';
import React, { useEffect, useMemo, useState } from 'react';

export type EventDisplayContentCSSOverride = {
  '.cue-event-display-wrapper'?: CSSObject;
  '.cue-event-display-title'?: CSSObject;
};

const livestreamData = livestream_channels as LivestreamSourceProps[];
export const EventDisplayContentModule: React.FC<
  EventDisplayContentModuleProps & StyledComponent<EventDisplayContentCSSOverride>
> = (props) => {
  const {
    title,
    number_of_events,
    readmore_open,
    display_options = [],
    only_today,
    skip_events,
    type,
    slot_pattern,
    categories = [],
    showTitle = true,
    sync_to_livestream = null,
    fallback,
  } = props;
  const [eventData, setEventData] = useState<AgendaEventProps[]>([]);

  const agendaDisplayOptions = React.useMemo(() => {
    const customDisplayOptions = display_options;
    try {
      return mergeCustomDisplayOptions(defaultAgendaOptions, customDisplayOptions);
    } catch (error) {
      console.error(error);
      return defaultAgendaOptions;
    }
  }, [display_options]);
  const livestream_data: LivestreamSourceProps | undefined = useMemo(
    () => livestreamData?.find((stream) => stream.id === sync_to_livestream?.id || ''),
    [sync_to_livestream?.id]
  );

  const { status: statusSEE } = useLivestreamStatus() || LivestreamStatusDefaultState;

  const { currentEvent, nextEvents, inter, updateSession } = useEventDataProvider();

  useEffect(() => {
    const slugs = slot_pattern?.map((s) => s?.slot_pattern_id.slug ?? '') ?? [];
    const cat = categories?.map((s) => s?.categories_id.slug ?? '') ?? [];
    let offset = 0;
    if (livestream_data) {
      const livestatus = statusSEE.find(({ channel }) =>
        livestream_data.categories?.map(({ slug }) => slug).includes(channel)
      );
      offset = livestatus?.status !== LivestreamStatusType.DEFAULT ? livestatus?.offset || 0 : 0;
    }

    const data = (
      type === EventDisplayContentModuleTypes.CURRENT_EVENT
        ? currentEvent({
            categories: cat,
            slugs,
            offset,
          })
        : nextEvents({
            categories: cat,
            slugs,
            skip_events,
            number_of_events,
            only_today,
            offset,
          })
    ) as AgendaEventProps[];
    setEventData(data);
  }, [statusSEE, inter]);

  if (eventData?.length < 1) return <></>;

  return (
    <Container className="cue-event-display-wrapper">
      {title && showTitle && (
        <Heading type="h2" className="cue-event-display-title">
          {title}
        </Heading>
      )}
      {eventData?.map((event) => (
        <AgendaEvent
          agendaDisplayOptions={agendaDisplayOptions}
          key={event.id}
          readmoreOpen={readmore_open}
          {...event}
        />
      ))}
    </Container>
  );
};
export interface EventDisplayContentModuleProps extends CMS_Content_Module_EventDisplayData {
  showTitle?: boolean;
  fallback?: React.ReactNode;
}
export enum EventDisplayContentModuleTypes {
  CURRENT_EVENT = 'current-event',
  NEXT_EVENT = 'next-event',
}

const defaultAgendaOptions: AgendaDisplayOptions = {
  filter: false,
  bookmarksFilterToggle: false,
  timezoneSwitch: false,
  search: false,
  timezone: false,
  locationTag: false,
  categories: true,
  speakerPreview: true,
  speakerList: true,
  speakerAgenda: true,
  readmoreOpen: true,
  signUpButtons: false,
  scheduleOpen: false,
  sideButtons: {
    documentDownload: false,
    bookmark: true,
    shareViaMail: true,
    play: false,
    iCal: true,
  },
};
const Container = styled.div``;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;
