import { ChatInput } from './chatInput';
import { ChatMessages } from './chatMessages';
import { LoadingSpinner } from '@cue/atoms';
import { StyledComponent, generateCss } from '@cue/theme';
import { useTheme } from '@emotion/react';
import styled, { CSSObject } from '@emotion/styled';
import { ChatClientConfig, useChat, useChatReturn } from '@project/hooks/use-chat';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

export const Chat: React.FC<ChatProps & StyledComponent<ChatStyleOverride>> = ({
  chat,
  config: { room, url } = { room: '', url: '' },
  user,
  username = '',
  showMessageInput = true,
  styling = 'default',
  overrideCSS,
  roomId,
}) => {
  const [scrollKey, setScrollKey] = useState<HTMLElement>();
  const chatMessagesContainer = useRef<HTMLDivElement>(null);
  /* const internal_chat = useChat({
    room,
    url,
    autoConnect: false,
  }); */
  const { connect, send, messages, history, pendingMessages, connected } = chat;

  const memoHistory = useMemo(() => history, [history]);
  useEffect(() => {
    if (user) {
      connect({ user, token: user.chatToken });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      //setUsername(`${user.firstName} ${user.lastName}`);
    }
  }, [user]);

  useEffect(() => {
    if (chatMessagesContainer?.current) {
      chatMessagesContainer.current.scrollTop = chatMessagesContainer?.current.scrollHeight;
    }
  }, [memoHistory]);

  useEffect(() => {
    setTimeout(() => {
      scrollKey?.scrollTo(0, 99999);

      console.log('SCROLLTO', memoHistory);
    }, 1000);
  }, [memoHistory]);

  const theme = useTheme();
  const _themeStyle = useMemo(() => {
    return generateCss({ type: 'organisms.chat', styling, theme }) || [];
  }, [styling, theme]);
  return (
    <ChatContainer css={[_themeStyle, overrideCSS]} className="cue-chat">
      {connected ? (
        <>
          <div className="cue-chat-messages-container" ref={chatMessagesContainer}>
            <PerfectScrollbar
              containerRef={(ref) => {
                setScrollKey(ref);
                // onScrollerInit && onScrollerInit(ref);
              }}>
              <ChatMessages history={history} pendingMessages={pendingMessages} user={username} />
            </PerfectScrollbar>
          </div>

          {showMessageInput && (
            <div className="cue-chat-input-container">
              <ChatInput onSend={(data) => send({ text: data.message, username })}></ChatInput>
            </div>
          )}
        </>
      ) : (
        <div className="cue-chat-loading">
          <LoadingSpinner />
        </div>
      )}
    </ChatContainer>
  );
};

export interface ChatProps {
  config?: ChatClientConfig;
  user?: ChatUser;
  username?: string;
  chat?: useChatReturn;
  showMessageInput?: boolean;
  roomId?: string;
}

export type ChatStyleOverride = {
  '.cue-chat-messages-container'?: CSSObject;
  '.cue-chat-input-container'?: CSSObject;
  '.cue-chat-loading'?: CSSObject;
};

export interface ChatUser {
  firstName: string;
  lastName: string;
  chatToken: string;
}

const ChatContainer = styled.div``;
