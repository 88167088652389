import { ProgramEvent } from '@cue/api';
import { Button, ThemedIcon } from '@cue/atoms';
import { Mdx, TimeUtil, mustache } from '@cue/utility';
import { LocalStorageUtil } from '@cue/utility';
import { useTheme } from '@emotion/react';
import { useEventDataProvider } from '@project/hooks/use-event-data';
import { useEvents } from '@project/hooks/use-events';
import {
  LivestreamStatus,
  LivestreamStatusDefaultState,
  LivestreamStatusType,
  useLivestreamStatus,
} from '@project/hooks/use-livestream-status';
import { useUserSubscriptions } from '@project/hooks/use-user-subscriptions';
import { livestreams_modules } from '@project/local/livestream.json';
import { navigate } from 'gatsby';
import { includes } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const alias = livestreams_modules.map((lm) => ({
  id: lm.id,
  channel: lm.livestream_channel.categories[0].categories_id.slug,
}));

export const UserStreamNotification = (props) => {
  const LIVESTREAM_NOTIFICATION_DISMISS = 'livestreamNotificationDismiss';
  const { excludeChannels, channels } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const [subscriptions, setSubscriptions] = useState([]);
  const [currentChannelState, setCurrentChannelState] = useState<LivestreamStatus | undefined>();
  const { status, statusByCategories, isLive } =
    useLivestreamStatus() || LivestreamStatusDefaultState;
  const { events, isLoading } = useEvents();
  const { bookmarks, participations } = useUserSubscriptions();
  const { currentEvent, nextEvents, inter, updateSession } = useEventDataProvider();

  const [dismissState, setDismissState] = useState(
    (LocalStorageUtil.get<string[]>(LIVESTREAM_NOTIFICATION_DISMISS) || []) as string[]
  );

  useEffect(() => {
    setCurrentChannelState(status?.find(({ channel }) => excludeChannels.includes(channel)));
  }, [status]);

  const activeSessions = useMemo(() => {
    const liveStatus =
      status
        .filter(
          ({ channel, event, status }) =>
            !excludeChannels.includes(channel) &&
            status === LivestreamStatusType.ACTIVE &&
            bookmarks.data?.includes(event) &&
            !dismissState.includes(event)
        )
        ?.map(({ event, channel }) => ({
          event,
          channel,
          streamId: alias.find((a) => a.channel === channel)?.id,
        })) || [];
    if (liveStatus.length > 0) return liveStatus;
    const agendaCategories = channels.filter((item) => !excludeChannels.includes(item));
    const agendaStatus =
      currentEvent({
        categories: channels.filter((item) => !excludeChannels.includes(item)),
        slugs: 'agenda',
      })
        ?.filter(({ id }) => bookmarks.data?.includes(id) && !dismissState.includes(event))
        .map(({ id, categories }) => {
          const channel = categories?.find((cat) => agendaCategories.includes(cat.slug))?.slug;
          return {
            event: id,
            channel,
            streamId: alias.find((a) => a.channel === channel)?.id,
          };
        }) || [];
    return agendaStatus;
  }, [status, bookmarks, isLoading]);

  if (activeSessions.length < 1 && currentChannelState?.status !== LivestreamStatusType.ACTIVE) {
    //return <pre>{JSON.stringify(status, null, 4)}</pre>;
    return (
      <>
        {status
          ?.filter(
            ({ event, channel, status }) =>
              !excludeChannels.includes(channel) && status === LivestreamStatusType.ACTIVE && event
          )
          ?.map((channel) => {
            const event = events?.find(({ id }) => id === channel.event) as ProgramEvent;
            const streamId = alias.find((a) => a.channel === channel.channel)?.id;
            return (
              <div key={channel.channel} className="cue-project-user-stream-notification">
                <div className="cue-project-user-stream-notification-info">
                  {ThemedIcon({ icon: 'information', theme })}
                </div>
                <div className="cue-project-user-stream-notification-message">
                  <Mdx>
                    {mustache(
                      t('livestream.user-notification.message-channel'),
                      event as unknown as Record<string, unknown>
                    ) || ''}
                  </Mdx>
                </div>
                <div className="cue-project-user-stream-notification-cta">
                  <Button
                    styling="secondary"
                    onClick={() => (document.location.href = `/live#${streamId}`)}>
                    {t('livestream.user-notification.action-channel')}
                  </Button>
                </div>
              </div>
            );
          })}
      </>
    );
  }

  return (
    <>
      {activeSessions
        ?.map((session) => ({
          events: events?.find(({ id }) => id === session.event) as ProgramEvent,
          session,
        }))
        ?.map((event_session) => {
          if (!event_session.events) return <></>;
          const { id } = event_session.events;
          const { streamId } = event_session.session;
          return (
            <div key={id} className="cue-project-user-stream-notification">
              <div className="cue-project-user-stream-notification-info">
                {ThemedIcon({ icon: 'information', theme })}
              </div>
              <div className="cue-project-user-stream-notification-message">
                <Mdx>
                  {mustache(
                    t('livestream.user-notification.message'),
                    event_session.events as unknown as Record<string, unknown>
                  ) || ''}
                </Mdx>
              </div>
              <div className="cue-project-user-stream-notification-cta">
                <Button
                  styling="secondary"
                  onClick={() => (document.location.href = `/live#${streamId}`)}>
                  {t('livestream.user-notification.action')}
                </Button>
              </div>
              <div className="cue-project-user-stream-notification-close">
                <Button
                  styling="iconOnly"
                  icon="close"
                  onClick={() => {
                    setDismissState((prev) => {
                      LocalStorageUtil.set<string[]>(LIVESTREAM_NOTIFICATION_DISMISS, [
                        id,
                        ...prev,
                      ]);
                      return [id, ...prev];
                    });
                  }}></Button>
              </div>
            </div>
          );
        })}
    </>
  );
};
