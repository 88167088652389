import { eventConfig } from '../../../config';
import { AgendaDateHeader } from '../agenda';
import { Schedule } from './schedule';
import { TimeUtil } from '@cue/utility';
import { useEvents } from '@project/hooks/use-events';
import { useUser } from '@project/hooks/use-user';
import { useUserSubscriptions } from '@project/hooks/use-user-subscriptions';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const PersonalSchedule: React.FC<PersonalScheduleProps> = (props) => {
  const { day, ...rest } = props;
  const { preferredTimezone } = useUser();
  const { events } = useEvents();
  const { participations, bookmarks } = useUserSubscriptions();
  const { data: p } = participations;
  const { data: b } = bookmarks;

  const { t } = useTranslation();
  const [activeDateIndex, setActiveDateIndex] = useState<number>(0);
  const [currentDay, setCurrentDay] = useState<string | undefined>(day);
  useEffect(() => {
    setCurrentDay(day);
  }, [day]);

  const personalEvents = React.useMemo(() => {
    if (!p || !b || !events) return;
    return events
      ?.filter((e) => e.start !== null && e.end !== null)
      ?.filter((e) => {
        return b.includes(e.id) || Object.keys(p).includes(e.id);
      })
      .sort((a, b) => a.start?.localeCompare(b.start));
  }, [events, p, b]);

  const displayTimezone = preferredTimezone || eventConfig.timezone;

  const dates = useMemo(
    () =>
      !props.day
        ? [
            ...new Set(
              personalEvents?.map((event) => TimeUtil.getUtc(event.start).format('YYYY-MM-DD'))
            ),
          ]
            .map((d) => TimeUtil.getUtc(d))
            .sort((a, b) => (a.isAfter(b) ? 1 : -1))
        : [] || [],
    [personalEvents, props.day]
  );

  useEffect(() => {
    if (dates[activeDateIndex]) setCurrentDay(dates[activeDateIndex]?.format());
  }, [activeDateIndex, dates]);

  return (
    <>
      {!dayjs(day).isValid() && (
        <AgendaDateHeader
          dates={dates}
          activeIndex={activeDateIndex}
          onDateChange={setActiveDateIndex}
        />
      )}

      <Schedule
        {...rest}
        day={currentDay}
        events={personalEvents}
        timezone={displayTimezone}
        title={props.title || t('schedule.personalScheduleTitle')}
      />
    </>
  );
};

export type PersonalScheduleProps = Omit<ScheduleProps, 'events'>;
