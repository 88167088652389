import { ProgramEvent } from '@cue/api';
import { LoadingSpinner } from '@cue/atoms';
import { Modal, useModal } from '@cue/organisms';
import { StyledComponent } from '@cue/theme';
import { Mdx, mustache } from '@cue/utility';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { CMS_Content_Module_EventTeaserData } from '@project/cms/modules';
import { Survey, SurveyProps } from '@project/cms/modules/survey';
import { useAgendaSettings } from '@project/hooks/use-agenda-settings';
import { useEventSuggestions } from '@project/hooks/use-event-suggestions';
import { useUser } from '@project/hooks/use-user';
import { AgendaEvent, AgendaEventLayout } from '@project/site/modules/agenda';
import { AgendaEventCtaTypes } from '@project/site/modules/agenda/agenda-event/event-cta';
// import { GridSlider } from '@project/site/modules/grid-slider/grid-slider';
import React, { useEffect, useState } from 'react';

export type EventTeaserContentCSSOverride = {
  '.cue-event-teaser'?: CSSObject;
  '.cue-event-teaser-mdx-before'?: CSSObject;
  '.cue-event-teaser-mdx-after'?: CSSObject;
};
export const EventTeaserContentModule: React.FC<
  EventTeaserContentModuleProps &
    StyledComponent<EventTeaserContentCSSOverride> & { regenerationTrigger?: number }
> = (props) => {
  const [currentEvent, setCurrentEvent] = useState<ProgramEvent>();
  const { user, loading: userLoading } = useUser();
  const [survey, setSurvey] = useState(
    props.survey ? <Survey onChange={() => setSurvey(null)} {...props.survey} /> : null
  );
  useEffect(() => {
    if (user) {
      if (user?.fields?.interests) {
        setSurvey(null);
      }
    }
  }, [user, userLoading]);

  const { eventSuggestions, debuggerElement, loading, regenerate } = useEventSuggestions({
    itemsNeeded: props?.items_needed,
    shuffled: props?.shuffled,
    filter: props.filter?.json || undefined,
    priority: props?.priority?.map((p) => ({ ...p.event_filter_id })).map((p) => p.json),
  });

  const [eventModal] = useModal();

  React.useEffect(() => {
    if (props.regenerationTrigger) {
      regenerate();
    }
  }, [props.regenerationTrigger, regenerate, user]);

  React.useEffect(() => {
    if (user) {
      regenerate();
    }
  }, [regenerate, user]);

  const openAgenda = (event: ProgramEvent) => {
    setCurrentEvent(event);
    eventModal?.current?.open();
  };

  const { getAgendaById } = useAgendaSettings();
  const agenda = React.useMemo(
    () => (props.agenda_settings?.id ? getAgendaById(props.agenda_settings?.id) : null),
    [props.agenda_settings?.id, getAgendaById]
  );

  const sliderContent = eventSuggestions?.map((event) => (
    <AgendaEvent
      layout={AgendaEventLayout.GRID}
      showSpeaker={false}
      showCategories={false}
      showTimeZone={false}
      includeCta={[AgendaEventCtaTypes.BOOKMARK]}
      locationCategories={agenda?.location_categories}
      hideLocation={agenda?.hide_location || false}
      onSelect={() => openAgenda(event)}
      key={event.id}
      {...event}
    />
  )) as React.ReactElement[];

  if (survey) return survey;

  return (
    <Container className="cue-event-teaser">
      {(loading || !sliderContent || userLoading) && <LoadingSpinner />}
      {props.mdx_before && (
        <Mdx css_class="cue-event-teaser-mdx-before">
          {mustache(props.mdx_before, user as unknown as Record<string, unknown>) || ''}
        </Mdx>
      )}
      {eventSuggestions?.map((event) => (
        <AgendaEvent
          layout={AgendaEventLayout.LIST}
          includeCta={[
            AgendaEventCtaTypes.BOOKMARK,
            AgendaEventCtaTypes.MAIL,
            AgendaEventCtaTypes.ICS,
          ]}
          // onSelect={() => openAgenda(event)}
          key={event.id}
          locationCategories={agenda?.location_categories}
          hideLocation={agenda?.hide_location || false}
          {...event}
        />
      ))}
      {/* <GridSlider>{sliderContent}</GridSlider> */}
      {props.mdx_after && (
        <Mdx css_class="cue-event-teaser-mdx-before">
          {mustache(props.mdx_after, user as unknown as Record<string, unknown>) || ''}
        </Mdx>
      )}
      <Modal
        ref={eventModal}
        overrideCSS={{
          '.cue-modal-content': { width: '95vw', maxWidth: 1280, background: 'transparent' },
          '.cue-modal-close-button-wrapper': { right: '30px', top: '30px' },
        }}>
        {currentEvent && (
          <AgendaEvent
            {...currentEvent}
            locationCategories={agenda?.location_categories}
            hideLocation={agenda?.hide_location || false}
          />
        )}
      </Modal>
      {debuggerElement}
    </Container>
  );
};

export interface EventTeaserContentModuleProps extends CMS_Content_Module_EventTeaserData {
  showTitle?: boolean;
  fallback?: React.ReactNode;
  survey?: SurveyProps;
  mdx_before?: string;
  mdx_after?: string;
}

const Container = styled.div``;
