import { CMS_Content_Module_Text_Image } from './types';
import { Heading, Image } from '@cue/atoms';
import { StyledComponent } from '@cue/theme';
import { Mdx } from '@cue/utility';
import styled, { CSSObject } from '@emotion/styled';
import React from 'react';

type TextImageContentModuleProps = CMS_Content_Module_Text_Image['data'];

export const TextImageContentModule: React.FC<
  TextImageContentModuleProps & StyledComponent<TextImageContentModuleCSSOverride>
> = (props) => {
  const { text, headline, image, label, link_text, link_url, customFooter } = props;
  return (
    <Container className="cue-text-image-content-module">
      {image && <Image src={image} className="cue-text-image-content-module-image" />}
      {headline && (
        <Heading type="h4" className="cue-text-image-content-module-headline">
          {headline}
        </Heading>
      )}
      {text && <Mdx css_class={'cue-text-image-content-module-text'}>{text}</Mdx>}
      {label || link_text || customFooter ? (
        <div className="cue-text-image-content-module-footer">
          {label && (
            <div className="cue-text-image-content-module-label">
              <Label className="cue-text-image-content-module-label">{label}</Label>
            </div>
          )}
          {link_text ? (
            <div className="cue-text-image-content-module-link">
              {link_url ? <a href={link_url}>{link_text}</a> : <span>{link_text}</span>}
            </div>
          ) : null}
          {customFooter ? customFooter() : null}
        </div>
      ) : null}
    </Container>
  );
};

const Container = styled.div``;
const Label = styled.div``;
export type TextImageContentModuleCSSOverride = {
  '.cue-text-image-content-module'?: CSSObject;
  '.cue-text-image-content-module-image'?: CSSObject;
  '.cue-text-image-content-module-headline'?: CSSObject;
  '.cue-text-image-content-module-text'?: CSSObject;
  '.cue-text-image-content-module-label'?: CSSObject;
};
