import { Button } from '@cue/atoms';
import { SearchBar, SearchBarProps, SearchBarRef } from '@cue/molecules';
import { Modal, useModal } from '@cue/organisms';
import { StyledComponent } from '@cue/theme';
import { AnalyticsTransformer } from '@cue/utility';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AgendaSearch = forwardRef(function AgendaSearch<T>(
  { search, onSearched }: AgendaSearchProps<T> & StyledComponent<AgendaSearchCSSOverride>,
  ref: React.ForwardedRef<unknown>
) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [modalRef] = useModal();
  const searchBarRef = useRef<SearchBarRef>(null);
  useImperativeHandle(
    ref,
    () => {
      const impReturn: AgendaSearchRef = {
        reset: () => resetSearch(),
      };
      return impReturn;
    },
    []
  );

  const resetSearch = () => {
    setSearchTerm('');
    searchBarRef.current?.reset();
  };

  const openSearchBar = () => {
    modalRef.current?.open();
    searchBarRef?.current?.inputRef?.focus();
  };

  const onResult = (result: T[]) => {
    if (result.length > 0) {
      searchBarRef.current?.reInit();
      const term = searchBarRef.current?.inputRef?.value || '';
      setSearchTerm(term);
      onSearched && onSearched({ searchTerm: term, result });
      modalRef.current?.close();
    }
  };

  return (
    <Container className="cue-agenda-search-wrapper">
      <Button
        icon="search"
        className="cue-agenda-search-button"
        styling="agendaActionButton"
        onClick={() => openSearchBar()}>
        {t('agenda.search')}
      </Button>
      <Modal
        ref={modalRef}
        onVisibilityChange={() => {
          resetSearch();
        }}
        customCloseRenderer={({ onClose }) => (
          <Button
            icon="close"
            styling="modalClosePlain"
            onClick={() => {
              AnalyticsTransformer.customEvent(['agendaSearch', `close`]);
              onClose && onClose();
            }}
          />
        )}>
        <SearchBar
          ref={searchBarRef}
          search={search}
          keys={['title', 'description', 'persons.name', 'categories.title']}
          placeholder={t('agenda.placeholder')}
          limit={10}
          // TODO: Fix type
          /* eslint-disable @typescript-eslint/ban-ts-comment */

          onResult={(result) => {
            onResult(result as T[]);
          }}
          /* eslint-enable @typescript-eslint/ban-ts-comment */
          initSearchValue={searchTerm}
        />
      </Modal>
    </Container>
  );
});

export type AgendaSearchCSSOverride = {
  '.cue-agenda-search-wrapper'?: CSSObject;
};

export interface AgendaSearchProps<T> extends Pick<SearchBarProps<T>, 'search' | 'onResult'> {
  onSearched?({ searchTerm, result }: { searchTerm: string; result: T[] }): void;
}
export interface AgendaSearchRef {
  reset?(): void;
}
const Container = styled.div``;

{
  /* <Button icon="search" styling="iconOnly"></Button>
        <Modal ref={modalRef} {...restOfProps}>
        {modalContent}
      </Modal> */
}
