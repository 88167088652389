import { SurveyItemProps, SurveyQuestion } from './types';
import { Heading, Image } from '@cue/atoms';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

export const ImageCards: React.FC<SurveyItemProps> = (props) => {
  const { options, selected, onChange } = props;
  return (
    <div className="cue-survey-cards-wrapper">
      {options.map((o, i) => (
        <ImageCard
          options={o}
          key={i}
          selected={selected !== undefined && selected?.includes(o.value)}
          onChange={() => {
            onChange({
              value: o.value,
            });
          }}
        />
      ))}
    </div>
  );
};

const ImageCard: React.FC<{ options: SurveyQuestion; selected: boolean; onChange: () => void }> = ({
  options,
  onChange,
  selected,
}) => {
  const [active, setActive] = useState(selected);

  useEffect(() => {
    setActive(selected);
  }, [selected]);
  return (
    <div
      className={`cue-survey-image-card${active ? ' is-selected' : ''}`}
      onClick={() => {
        onChange();
        setActive(!active);
      }}>
      <div>
        {options.image && (
          <div className="cue-survey-image-card-image">
            <Image src={options.image.src} srcSet={options.image.srcSet} />
          </div>
        )}

        {options.title && (
          <div className="cue-survey-image-card-title">
            <Heading type="h3">
              <ReactMarkdown>{options.title}</ReactMarkdown>
            </Heading>
          </div>
        )}

        {options.sub_title && (
          <div className="cue-survey-image-card-sub-title">
            <ReactMarkdown>{options.sub_title}</ReactMarkdown>
          </div>
        )}
      </div>
    </div>
  );
};
