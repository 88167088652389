import { eventConfig } from '../../../config';
import { CMS_Content_Module_LiveStreamData } from '../livestream';
import { LivestreamSourceProps } from '../livestream/types';
import { ProgramEvent } from '@cue/api';
import { Button, Heading, Image, Progress } from '@cue/atoms';
import { Video } from '@cue/molecules';
import { StyledComponent } from '@cue/theme';
import { Mdx, TimeUtil } from '@cue/utility';
import { CSSObject, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useAgendaSettings } from '@project/hooks/use-agenda-settings';
import { useEventDataProvider } from '@project/hooks/use-event-data';
import {
  LivestreamStatus,
  LivestreamStatusDefaultState,
  LivestreamStatusType,
  useLivestreamStatus,
} from '@project/hooks/use-livestream-status';
import { useUser } from '@project/hooks/use-user';
import { AgendaTag } from '@project/site/modules/agenda/agenda-event/tag-button';
import { LivestreamStatusOverlay } from '@project/site/modules/livestream/livestream-status-overlay';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

export const LivestreamTeaserElement = forwardRef<
  HTMLDivElement,
  LivestreamTeaserProps &
    StyledComponent<LivestreamTeaserElementCSSOverride> &
    React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const {
    id,
    display = LivestreamTeaserElementDisplay.ROW,
    small,
    onSelectStream,
    livestream,
    ...rest
  } = props;
  const {
    offset_start = 0,
    offset_end = 0,
    no_stream_fallback,
    no_stream_before,
    no_stream_after,
    show_countdown,
    agenda: { slug: agendaSlug },
    livestream_channel: { category_slugs: categories },
  } = livestream;
  const [nextEvent, setNextEvent] = useState<ProgramEvent>();
  const [isLive, setIsLive] = useState<boolean>(false);
  const [status, setStatus] = useState<LivestreamStatus>();
  const { getCategoryColor } = useAgendaSettings();
  const { nextEvents, currentEvent, getEventById, inter } = useEventDataProvider();
  const { statusByCategories } = useLivestreamStatus() || LivestreamStatusDefaultState;
  const { preferredTimezone } = useUser();
  const displayTimezone = preferredTimezone || eventConfig.timezone;
  const data: LivestreamSourceProps | undefined = useMemo(
    () => livestream.livestream_channel,
    [id]
  );

  useEffect(() => {
    setStatus(
      statusByCategories(categories || [], {
        offset_start,
        offset_end,
      })
    );
  }, [statusByCategories, categories, data, offset_start, offset_end]);

  useEffect(() => {
    setNextEvent(
      (nextEvents({
        slugs: agendaSlug,
        categories,
        only_today: false,
      })?.[0] as ProgramEvent) || null
    );
  }, [status]);

  const eventData: ProgramEvent | null = useMemo(() => {
    if (status?.event) {
      const event = getEventById(status.event);

      return {
        ...event,
        start: TimeUtil.getUtc(event?.start || '')
          .add(status?.offset || 0)
          .format(),
        end: TimeUtil.getUtc(event?.end || '')
          .add(status?.offset || 0)
          .format(),

        startDisplay: event?.start,
        endDisplay: event?.end,
      };
    }

    // CURRENT
    const current =
      currentEvent({
        slugs: agendaSlug,
        categories,
      })?.[0] || null;
    if (current) return current as ProgramEvent;

    return (
      (nextEvents({
        slugs: agendaSlug,
        categories,
        only_today: false,
      })?.[0] as ProgramEvent) || null
    );
  }, [status]);

  const percentageStatus = useMemo(() => {
    if (!eventData) return 0;

    const start = TimeUtil.getUtc(eventData.start);
    const end = TimeUtil.getUtc(eventData.end);
    if (!TimeUtil.getNowUtc().isBetween(start, end)) return 0;

    const diff = end?.diff(start);
    const current = TimeUtil.getNowUtc()?.diff(start);
    return current / diff;
  }, [inter, eventData]);

  return (
    <Container
      className={`cue-livestream-teaser-element ${
        display === LivestreamTeaserElementDisplay.COLUMN ? 'cue-livestream-teaser-column' : ''
      } ${small ? 'cue-livestream-teaser-small' : ''}`}
      {...rest}
      ref={ref}
      onClick={() => onSelectStream && onSelectStream(livestream)}>
      {livestream?.card_image && (
        <ImageContainer className={`cue-livestream-teaser-image`}>
          <LivestreamStatusOverlay
            status={status?.status}
            small={small}
            isThumb={small}
            showCountdown={show_countdown}
            nextEvent={nextEvent?.start}
            data={{
              no_stream_fallback,
              no_stream_before,
              no_stream_after,
            }}></LivestreamStatusOverlay>
          <Image
            src={livestream?.card_image?.src}
            icon={isLive ? 'play' : undefined}
            className={`cue-livestream-teaser-content-module-image `}
          />
          {(status?.status === LivestreamStatusType.ACTIVE ||
            status?.status === LivestreamStatusType.IDLE) &&
            data?.source && (
              <>
                <div className="cue-livestream-teaser-preview">
                  <Video url={data?.source} muted playing noUi />
                  <Button icon="play"></Button>
                </div>
                <Heading
                  type="h6"
                  iconAlign="right"
                  icon={'live'}
                  className="cue-livestream-teaser-live-tag">
                  Live
                </Heading>
              </>
            )}
        </ImageContainer>
      )}

      {/*<pre>{JSON.stringify(eventData, null, 4)}</pre>
      <pre>{JSON.stringify(livestream, null, 4)}</pre> */}
      {eventData && (
        <>
          <div className="cue-livestream-teaser-content">
            {small ? (
              <ResponsiveEllipsis
                className="cue-livestream-teaser-small"
                unsafeHTML={`<strong>${eventData.title}</strong> <br />${eventData.description}`}
                maxLine="3"
                ellipsis="..."
                basedOn="letters"
              />
            ) : (
              <>
                <ResponsiveEllipsis
                  className="cue-livestream-teaser-headline"
                  unsafeHTML={eventData.title}
                  maxLine="2"
                  ellipsis="..."
                  basedOn="letters"
                />
                <ResponsiveEllipsis
                  className="cue-livestream-teaser-description"
                  unsafeHTML={eventData.description}
                  maxLine="3"
                  ellipsis="..."
                  basedOn="letters"
                />
              </>
            )}
          </div>{' '}
          <div className="cue-livestream-teaser-footer">
            <TimeProgress className="cue-livestream-teaser-time">
              <Progress className="cue-livestream-teaser-progress" value={percentageStatus} />
              {TimeUtil.convertToTimeZone(
                TimeUtil.getUtc(eventData.startDisplay || eventData.start),
                displayTimezone
              ).format('HH:mm')}
              -
              {TimeUtil.convertToTimeZone(
                TimeUtil.getUtc(eventData.endDisplay || eventData.end),
                displayTimezone
              ).format('HH:mm')}{' '}
            </TimeProgress>

            {eventData?.categories
              ?.filter((category) => category.slug === status?.channel)
              .map((category) => (
                <AgendaTag
                  key={category.id}
                  className="cue-livestream-teaser-category-tag"
                  icon={'radio'}
                  color={getCategoryColor(category?.id) || undefined}
                  isInteractive={false}>
                  {category?.title}
                </AgendaTag>
              ))}
          </div>
        </>
      )}
    </Container>
  );
});
LivestreamTeaserElement.displayName = 'LivestreamTeaserElement';
export type LivestreamTeaserElementCSSOverride = {
  '.cue-livestream-teaser'?: CSSObject;
  '.cue-livestream-teaser-image'?: CSSObject;
  '.cue-livestream-teaser-live'?: CSSObject;
  '.cue-livestream-teaser-content'?: CSSObject;
  '.cue-livestream-teaser-footer'?: CSSObject;
  '.cue-livestream-teaser-time'?: CSSObject;
  '.cue-livestream-teaser-progress'?: CSSObject;
  '.cue-livestream-teaser-tag'?: CSSObject;
};

export enum LivestreamTeaserElementDisplay {
  ROW = 'ROW',
  COLUMN = 'COLUMN',
}

export interface LivestreamTeaserProps {
  id: string;
  display?: LivestreamTeaserElementDisplay;
  small?: boolean;
  source?: string;
  livestream: CMS_Content_Module_LiveStreamData;
  onLiveChange?(id: string, isLive: boolean): void;
  onSelectStream?(id: CMS_Content_Module_LiveStreamData): void;
}
const Container = styled.div``;

const ImageContainer = styled.div``;

const TimeProgress = styled.div`
  flex: 1;
  margin-right: 20px;
`;
const blink = keyframes` 
0%, 100% { 
  opacity: 0; 
}
50% { opacity: 1; }
`;

const Tag = styled(Button)`
  cursor: initial;
`;
