import { AnalyticsTransformer } from '@cue/utility';
import styled from '@emotion/styled';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import { useEffect, useState } from 'react';

type EmojiReactionsProps = {
  loading?: boolean;
  currentSessionId: string | null;
  additionalProps: any;
  recentEmoji?: {
    emojiId: string;
    time: number;
  };
  sendReaction: (emoji: string) => void;
};
const emojis = [
  {
    emojiId: '1f44d',
    trackingEvent: 'reaction-thumbsup',
  },
  {
    emojiId: '1f603',
    trackingEvent: 'reaction-laugh',
  },
  {
    emojiId: '1f4a1',
    trackingEvent: 'reaction-aha',
  },
  {
    emojiId: '2764-fe0f',
    trackingEvent: 'reaction-heart',
  },
  {
    emojiId: '1f44f',
    trackingEvent: 'reaction-clap',
  },
  {
    emojiId: '1f64f',
    trackingEvent: 'reaction-nice',
  },
];
export const EmojiReactions = (props: EmojiReactionsProps) => {
  const {
    recentEmoji,
    sendReaction,
    currentSessionId = null,
    additionalProps = {},
    loading = false,
  } = props;
  const [emotions, setEmotions] = useState<
    Array<{
      id: string;
      right: number;
      emojiId: string;
      scale: number;
    }>
  >([]);
  const handleAddEmotion = (emojiId: string) => {
    const MIN = 20;
    const MAX = 200;
    const SCALE_MAX = 20;
    const SCALE_MIN = -20;
    const randomId = Math.random().toString();
    const scale = (Math.floor(Math.random() * (SCALE_MAX - SCALE_MIN + 1)) + SCALE_MAX) / 100 + 1;
    const right = Math.floor(Math.random() * (MAX - MIN + 1)) + MIN;
    const payload = {
      id: randomId,
      right,
      emojiId,
      scale,
    };
    setEmotions((prev) => [...prev, payload]);
    setTimeout(() => {
      setEmotions((prev) => [...prev.filter((i) => i.id !== randomId)]);
    }, 3000);
  };

  useEffect(() => {
    if (recentEmoji) {
      handleAddEmotion(recentEmoji.emojiId);
    }
  }, [recentEmoji]);

  const onClickEmotion = (emojiId: string, trackingEvent: string) => {
    sendReaction(emojiId);
    handleAddEmotion(emojiId);
    AnalyticsTransformer.customEvent([
      trackingEvent,
      JSON.stringify({
        eventId: currentSessionId,
        ...additionalProps,
      }),
    ]);
  };

  return (
    <Container className="cue-emotion-reactions">
      <div className="cue-emotion-reactions-float">
        {emotions.map((i) => (
          <div className="cue-emotion-reactions-float-item" key={i.id} style={{ right: i.right }}>
            <Emoji unified={i.emojiId} emojiStyle={EmojiStyle.APPLE} size={22 * i.scale} />
          </div>
        ))}
      </div>
      <div
        className={`cue-emotion-reactions-items ${
          loading ? 'cue-emotion-reactions-items-loading' : ''
        }`}>
        {emojis.map((i) => (
          <div
            className="cue-emotion-reactions-item"
            key={i.emojiId}
            onClick={() => onClickEmotion(i.emojiId, i.trackingEvent)}>
            <Emoji unified={i.emojiId} emojiStyle={EmojiStyle.APPLE} size={22} />
          </div>
        ))}
      </div>
    </Container>
  );
};
const Container = styled.div``;
