import { AgendaReservationButtonsProps } from './reservation-buttons';
import { Button, Heading, LoadingSpinner, Paragraph } from '@cue/atoms';
import { useToastNotification } from '@cue/organisms';
import { AvailableLanguages, TimeUtil, getAbbr } from '@cue/utility';
import styled from '@emotion/styled';
import { useConfig } from '@project/hooks/use-config';
import { useEvents } from '@project/hooks/use-events';
import { useUser } from '@project/hooks/use-user';
import { useUserSubscriptions } from '@project/hooks/use-user-subscriptions';
import timezone from 'dayjs/plugin/timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';

// TODO: Move to core
const dayjs = TimeUtil.getDayjs();
dayjs.extend(timezone);

type AgendaReservationFromProps = AgendaReservationButtonsProps & {
  onCancel?: () => void;
  onReservation?: () => void;
  onCancelReservation?: () => void;
};

export const AgendaReservationFrom: React.FC<AgendaReservationFromProps> = (props) => {
  const { eventId, onReservation, onCancelReservation, onCancel } = props;
  const { events } = useEvents();
  const { participations } = useUserSubscriptions();
  const { userTimezone } = useUser();
  const {
    eventConfig: { timezone: eventTimezone },
  } = useConfig();
  const notification = useToastNotification();
  const { t, i18n } = useTranslation();

  const event = React.useMemo(() => {
    return events?.find((ev) => ev.id === eventId);
  }, [eventId, events]);

  const isSignedUp =
    Boolean(participations.data && event?.id) &&
    Object.keys(participations.data)?.includes(String(event?.id));

  function handleCancleReservation() {
    participations.cancelEvent(eventId);
  }

  function handleReservation() {
    participations.reserveEvent(eventId);
  }

  React.useEffect(() => {
    // Go Api return is just an empty string onSuccess
    if (participations.cancelEventResult.data !== undefined) {
      onCancelReservation && onCancelReservation();
      notification.success({ message: t(`agenda.cancel-reservation.success`) });
    }
    if (participations.reserveEventResult.data !== undefined) {
      onReservation && onReservation();
      notification.success({ message: t(`agenda.signup.success`) });
    }
  }, [
    participations.error,
    participations.cancelEventResult,
    participations.reserveEventResult,
    onReservation,
    onCancelReservation,
    notification,
    t,
  ]);

  if (!event) return null;
  const tKey = isSignedUp ? 'cancel-reservation' : 'signup';

  const userTz =
    userTimezone && getAbbr(userTimezone, i18n.language as AvailableLanguages, dayjs(event.start));
  const eventTz =
    userTimezone && getAbbr(eventTimezone, i18n.language as AvailableLanguages, dayjs(event.start));

  return (
    <ReservationStyleWrapper>
      <div className="cue-agenda-reservation-form">
        <Heading type="h4">{t(`agenda.${tKey}.headline`)}</Heading>
        <Paragraph>
          {t(`agenda.${tKey}.copy`, {
            title: event.title,
          })}
        </Paragraph>
        <div className="cue-agenda-reservation-event-info">
          <Paragraph>
            <strong>{event.title}</strong>
          </Paragraph>
          <Paragraph>
            <strong>
              <span style={{ marginRight: 10 }}>{t('agenda.venueTime')}:</span>
            </strong>
            {TimeUtil.getUtc(event.start)?.tz(eventTimezone)?.format('HH:mm')} -{' '}
            {TimeUtil.getUtc(event.end)?.tz(eventTimezone)?.format('HH:mm')} ({eventTz})
          </Paragraph>
          {userTz !== eventTz && userTimezone ? (
            <Paragraph>
              <strong>
                <span style={{ marginRight: 10 }}>{t('agenda.yourTime')}:</span>
              </strong>
              {TimeUtil.getUtc(event.start)?.tz(userTimezone)?.format('HH:mm')} -{' '}
              {TimeUtil.getUtc(event.end)?.tz(userTimezone)?.format('HH:mm')} ({userTz})
            </Paragraph>
          ) : null}
        </div>
        <div className="cue-agenda-reservation-form-buttons">
          {participations.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {participations.error ? (
                <>
                  <p className="error">{t(`agenda.${tKey}.error`)}</p>
                </>
              ) : (
                <>
                  <Button
                    disabled={participations.isLoading}
                    styling="secondary"
                    onClick={() => {
                      onCancel && onCancel();
                    }}>
                    {t(`agenda.${tKey}.cancel`)}
                  </Button>
                  <Button
                    disabled={
                      participations.cancelEventResult.isLoading ||
                      participations.reserveEventResult.isLoading
                    }
                    styling="primary"
                    onClick={() => (isSignedUp ? handleCancleReservation() : handleReservation())}>
                    {t(`agenda.${tKey}.confirm`)}
                  </Button>
                  {participations.cancelEventResult.isLoading ||
                    (participations.reserveEventResult.isLoading && <LoadingSpinner />)}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </ReservationStyleWrapper>
  );
};
const ReservationStyleWrapper = styled.div`
  .cue-agenda-reservation-form-buttons {
    display: flex;

    gap: 10px;
    margin: 20px 0;
  }
`;
