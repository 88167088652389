import { CMS_Content_Module_LiveStreamData } from '../livestream';
import { LivestreamTeaserElement } from './livestream-teaser-element';
import { Button, Heading } from '@cue/atoms';
import { useHash } from '@cue/hooks';
import { Mdx } from '@cue/utility';
import styled from '@emotion/styled';
import { CMS_Content_Module_LivestreamTeaserData, CardDeck } from '@project/cms/modules';
import { useLivestreamStatus } from '@project/hooks/use-livestream-status';
import { Livestream } from '@project/site/modules/livestream';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const LivestreamTeaser = (props: LivestreamTeaserProps) => {
  const { livestream_content_modules, headline, headline_live, description, description_live } =
    props;
  const { t } = useTranslation();
  const [currentChannel, setCurrentChannel] = useState<CMS_Content_Module_LiveStreamData | null>(
    null
  );

  const { hash, updateHash } = useHash();

  useEffect(() => {
    setCurrentChannel(livestream_content_modules?.find((lcm) => lcm.id === hash) || null);
  }, [hash, livestream_content_modules]);

  /*   'ecde5a3a-6ca7-4b57-a63b-43e6a1b9c299'
   */

  const { isLive } = useLivestreamStatus() || { isLive: false };

  return (
    <Container>
      {!currentChannel ? (
        <>
          <div className="cue-livestream-teaser-headline">
            <Heading type="h2" iconAlign="right" icon={isLive ? 'live' : undefined}>
              <strong>{isLive ? headline_live : headline}</strong>
            </Heading>
            <Mdx>{isLive ? description_live : description}</Mdx>
          </div>
          <CardDeck
            cards={livestream_content_modules?.map((livestream) => (
              <>
                <LivestreamTeaserElement
                  id={livestream.livestream_channel.id}
                  key={livestream.livestream_channel.id}
                  livestream={livestream}
                  onSelectStream={(stream) => {
                    updateHash(stream.id);
                  }}
                />
              </>
            ))}
          />
        </>
      ) : (
        <>
          <Button
            className="cue-livestream-back"
            styling="iconSeparated"
            onClick={() => updateHash('overview')}
            icon="arrowLeft">
            {t('livestream.back-to-overview')}
          </Button>
          <Livestream
            onSelect={(id) => setCurrentChannel(id)}
            {...currentChannel}
            otherStreams={livestream_content_modules}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div``;
export type LivestreamTeaserProps = CMS_Content_Module_LivestreamTeaserData;

/* 
export const CardDeck: React.FC<CardDeckProps & StyledComponent<CardDeckCSSOverride>> = ({
  card_modules = [],
  as_slider = false, */
