export function getNestedProperty(
  obj: {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  },
  propertyPath: string
  // TODO
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { value: any; prop: string } | undefined {
  const properties = propertyPath.split('.');

  let value = obj;

  for (let i = 0; i < properties.length - 1; i++) {
    const prop = properties[i];

    if (value && typeof value === 'object' && prop in value) {
      value = value[prop];
    } else {
      return;
    }
  }

  return {
    value,
    prop: properties[properties.length - 1],
  };
}

// TODO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseJSON = (input: any) => {
  try {
    return JSON.parse(input);
  } catch (e) {
    return input;
  }
};
