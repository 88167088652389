import { NavigateFunction } from '../agenda';
import { Button, ButtonProps } from '@cue/atoms';
import { useQueryParams } from '@cue/hooks';
import styled from '@emotion/styled';
import React from 'react';

type AgendaTagProps = ButtonProps & {
  isInteractive?: boolean;
  children?: React.ReactNode;
  color?: string;
  icon?: string;
  link?: string | null;
  className?: string;
  navigate?: NavigateFunction;
};

export const AgendaTag: React.FC<AgendaTagProps> = (props) => {
  const [params, setParams] = useQueryParams(/*{ navigate: navigate }*/);

  function openLink(link?: string | null) {
    if (!link) return;
    const hashSplit = link ? link.split('#') : undefined;
    // const hash = hashSplit ? hashSplit?.[1] : undefined;
    const paramsSplit = hashSplit ? hashSplit[0].split('?') : undefined;
    const linkParamsString = paramsSplit ? paramsSplit?.[1] : undefined;
    const linkParamsObject = linkParamsString
      ? Object.fromEntries(new URLSearchParams(linkParamsString))
      : undefined;
    const linkWithoutParamsOrHash = paramsSplit ? paramsSplit[0] : undefined;

    if (linkWithoutParamsOrHash) {
      props.navigate ? props.navigate(link) : window.open(link);
    }
    if (linkParamsObject) {
      setParams({ ...params, ...linkParamsObject });
    }
  }

  return (
    <StyleTagButton
      {...props}
      isInteractive={Boolean(props.link)}
      onClick={() => Boolean(props.link) && openLink(props.link)}
      styling="tag"
      className={`cue-agenda-event-location-tag ${props.className ? props.className : ''}`}>
      {props.children}
    </StyleTagButton>
  );
};

const StyleTagButton = styled(Button)<{ color?: string; isInteractive?: boolean }>(
  ({ color, isInteractive }) => ({
    cursor: isInteractive ? 'pointer' : 'initial',
    color: color ? `${color}!important` : undefined,
    borderColor: color ? `${color}!important` : undefined,
    backgroundColor: color ? 'transparent!important' : undefined,
    svg: {
      path: { fill: color ? `${color}!important` : undefined },
    },
  })
);
