import { CMS_Content_Module_CardDeckData } from './types';
import { StyledComponent } from '@cue/theme';
import styled, { CSSObject } from '@emotion/styled';
import { ContentBlock } from '@project/cms/content-sections/content-block';
import { GridSlider } from '@project/site/modules/grid-slider/grid-slider';
import React from 'react';

export const CardDeck: React.FC<CardDeckProps & StyledComponent<CardDeckCSSOverride>> = ({
  card_modules = [],
  cards = [],
  as_slider = false,
}) => {
  const modules =
    cards.length > 0
      ? cards.map((contentBlock, i) => (
          <Card className="cue-project-cards-card" key={`card-${i}`}>
            {contentBlock}
          </Card>
        ))
      : card_modules.map((contentBlock, i) => (
          <Card className="cue-project-cards-card" key={`card-${i}`}>
            <ContentBlock wrapper={false} key={`contentBlock-${i}`} {...contentBlock} />
          </Card>
        ));

  if (as_slider) return <GridSlider>{modules}</GridSlider>;
  return (
    <CardDeckContainer
      className="cue-project-cards-wrapper"
      maxCardsPerRow={cards?.length || card_modules?.length || 3}>
      {modules}
    </CardDeckContainer>
  );
};
export type CardDeckCSSOverride = {
  '.cue-project-cards-wrapper'?: CSSObject;
  '.cue-project-cards-card'?: CSSObject;
};
export interface CardDeckProps extends CMS_Content_Module_CardDeckData {
  cards?: React.ReactElement[];
}
const CardDeckContainer = styled.div<{ maxCardsPerRow?: number }>(
  ({ maxCardsPerRow = 3, theme: { mq } }) => ({
    display: 'grid',
    rowGap: '25px',
    columnGap: '25px',
    gridTemplateColumns: '1fr',
    [mq?.['sm']]: {
      gridTemplateColumns:
        maxCardsPerRow && maxCardsPerRow <= 1 ? `repeat(${maxCardsPerRow}, 1fr)` : '1fr 1fr',
    },
    [mq?.['lg']]: {
      gridTemplateColumns:
        maxCardsPerRow && maxCardsPerRow <= 3
          ? `repeat(${maxCardsPerRow}, 1fr)`
          : '1fr 1fr 1fr 1fr',
    },
  })
);

const Card = styled.div<{ clickable?: boolean }>(
  () => `  container-type: inline-size;
`
);
