import { Button, Input } from '@cue/atoms';
import styled from '@emotion/styled';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ButtonProps = Omit<JSX.IntrinsicElements['button'], 'ref'>;

export const ChatInput: React.FC<ChatInputProps> = ({ onSend, buttonProps }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm<ChatInputFormData>();

  return (
    <Container
      className="cue-chat-input"
      onSubmit={handleSubmit((data) => {
        onSend && onSend(data);
        reset();
      })}>
      <InputContainer className="cue-chat-input-container">
        <Input {...register('message', { required: true })} name="message" />
      </InputContainer>
      <ButtonContainer className="cue-chat-input-send">
        <Button type="submit" {...buttonProps}>
          {t('chat.send')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export interface ChatInputFormData {
  message: string;
}

export interface ChatInputProps {
  onSend?(data: ChatInputFormData): void;
  buttonProps?: ButtonProps;
}
const Container = styled.form`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div({
  marginRight: '1rem',
  flex: 1,
  '& > input': { width: '100%' },
});

const ButtonContainer = styled.div({});
