import { FlagsContext } from '../../providers/flags-provider';
import { ButtonProps } from '@cue/atoms';
import { MenuItemData } from '@cue/molecules';
import { useUser } from '@project/hooks/use-user';
import { availableLanguages } from '@project/i18n/config';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

type DirectusMenu = {
  id: string;
  slug: string;
  name: string;
  target: string | null;
  menu_items: DirectusMenuItem[];
};

type DirectusMenuItem = {
  menu_items_id: {
    id: string;
    open_in_new_tab: boolean;
    i18n_key: {
      key: string;
    };
    translations: {
      title: string;
      url: string | null;
      languages_code: {
        code: string;
      };
    }[];
    link: string;
    as_hash: boolean;
    is_active: boolean;
    is_front_page: boolean;
    is_public: boolean;
    flag: {
      id: string;
      slug: string;
    };
    user_status: string | null;
    icon_align: string | null;
    livestream_indicator?: boolean;
    custom_action: string | null;
    icon: {
      component: string;
    };
  };
};

type NavMenu = {
  id: string;
  slug: string;
  name: string;
  menu_items: MenuItem[];
};

type MenuItem = {
  id: string;
  open_in_new_tab: boolean;
  i18n_key: string | null;
  translations: Record<string, string>;
  title: string | null;
  link: string;
  as_hash: boolean;
  icon: string | null;
  iconAlign?: ButtonProps['iconAlign'];
  is_active: boolean;
  is_front_page: boolean;
  is_public: boolean;
  flag: string;
  isActiveNavItem?: boolean;
  user_status?: string;
  custom_action?: string;
  livestream_indicator?: boolean;
};

export const useNavMenu = (menuSlug: string): useNavMenuReturn => {
  const buildTarget = process.env.TYPE || 'live';
  const {
    directus: { menus },
  } = useStaticQuery(
    graphql`
      query headerMenu {
        directus {
          menus(filter: { active: { _eq: true } }) {
            id
            slug
            name
            active
            target
            menu_items(filter: { menu_items_id: { is_active: { _eq: true } } }) {
              menu_items_id {
                id
                open_in_new_tab
                is_public
                i18n_key {
                  key
                }
                translations {
                  title
                  url
                  languages_code {
                    code
                  }
                }
                link
                as_hash
                icon {
                  component
                }
                icon_align
                user_status
                custom_action
                is_front_page
                livestream_indicator
                flag {
                  id
                  slug
                }
              }
            }
          }
        }
      }
    `
  );

  const location = useLocation();
  const flagsContext = React.useContext(FlagsContext);

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { user } = useUser();
  const isAuthenticated = Boolean(user);
  const user_state = isAuthenticated ? 'registered' : 'not_registered';

  const navData: Record<string, NavMenu> | undefined = React.useMemo(() => {
    if (!menus) return;
    const filteredMenus = menus
      // Filter menus by target
      .filter((menu: DirectusMenu) => menu.target === buildTarget || menu.target === 'all')

      // Filter menus by userstate
      .map((menu: DirectusMenu) => {
        return {
          ...menu,
          menu_items: menu.menu_items.filter(
            (item: DirectusMenuItem) =>
              item.menu_items_id.user_status === user_state ||
              item.menu_items_id.user_status === 'always'
          ),
        };
      });

    return (filteredMenus as DirectusMenu[]).reduce((res, menu) => {
      return {
        ...res,
        [menu.slug]: {
          ...menu,
          menu_items: menu.menu_items
            .map((menuItem) => {
              if (menuItem.menu_items_id.flag?.slug) {
                const currentFlagState = flagsContext?.[menuItem.menu_items_id.flag?.slug];
                if (currentFlagState === false) {
                  return;
                }
              }

              const translationsForCurrentLanguage = menuItem.menu_items_id.translations.find(
                (trans) => trans.languages_code.code.startsWith(language)
              );

              const { title, url: explicitlySetUrlForLanguage } =
                translationsForCurrentLanguage || {};

              const { is_public, link, ...restOfPRops } = menuItem.menu_items_id;

              if (!is_public && !isAuthenticated) {
                return;
              }

              const as_hash = menuItem.menu_items_id.as_hash;
              const explicitLanguageLink =
                explicitlySetUrlForLanguage &&
                addLanguageIfNecessary(
                  explicitlySetUrlForLanguage,
                  language,
                  as_hash || availableLanguages.length < 2
                );
              const regularLanguageLink =
                link &&
                addLanguageIfNecessary(link, language, as_hash || availableLanguages?.length < 2);
              const finalLink = explicitLanguageLink || regularLanguageLink;

              const isActiveNavItem = isBestChoiceForActiveNavItem(
                location.pathname,
                menu,
                menuItem
              );

              return {
                // get rid of weird CMS API output
                ...restOfPRops,
                link: as_hash
                  ? location.pathname === '/' ||
                    location.pathname === '/en/' ||
                    location.pathname === '/de/'
                    ? finalLink
                    : `/${availableLanguages.length < 2 ? '' : 'en/'}#${finalLink}`
                  : finalLink,
                as_hash:
                  as_hash &&
                  (location.pathname === '/' ||
                    location.pathname === '/en/' ||
                    location.pathname === '/de/'),
                title,
                iconAlign: menuItem.menu_items_id?.icon_align,
                user_status: menuItem.menu_items_id?.user_status,
                custom_action: menuItem.menu_items_id?.custom_action,
                icon: menuItem.menu_items_id?.icon?.component || null,
                i18n_key: menuItem.menu_items_id.i18n_key?.key || null,
                flag: menuItem.menu_items_id.flag ? menuItem.menu_items_id.flag.slug : null,
                isActiveNavItem: !as_hash && isActiveNavItem,
                livestream_indicator: menuItem.menu_items_id?.livestream_indicator,
              };
            })
            .filter(Boolean),
        },
      };
    }, {});
  }, [menus, buildTarget, user_state, isAuthenticated, language, location.pathname, flagsContext]);

  const data = navData?.[menuSlug];
  const menuItems =
    data?.menu_items.map(
      ({
        title,
        link,
        icon,
        isActiveNavItem,
        i18n_key,
        open_in_new_tab,
        is_front_page,
        as_hash,
        user_status,
        livestream_indicator,
        iconAlign,
        custom_action,
      }) => ({
        label: !is_front_page ? (i18n_key ? t(i18n_key) : title) : '' || '',
        link,

        isActive: isActiveNavItem,
        target: open_in_new_tab ? '_blank' : '',
        icon: icon || undefined,
        custom: {
          as_hash,
        },
        user_status,
        livestream_indicator,
        iconAlign,
        custom_action,
      })
    ) || [];

  return { data, menuItems };
};

export interface useNavMenuReturn {
  data: NavMenu | undefined;
  menuItems: MenuItemData[];
}

function addLanguageIfNecessary(link: string, language: string, is_hash?: boolean) {
  if (is_hash) return link;
  return /^(f|ht)tps?:\/\//i.test(link) ? link : `/${language}${link}`;
}

function isBestChoiceForActiveNavItem(
  currentPath: string,
  menu: DirectusMenu,
  menuItem: DirectusMenuItem
) {
  const allMenuPaths = menu.menu_items.map((m) => {
    return m.menu_items_id.link;
  });

  // finde menuItem with most overlap with currentPath from all menuItems in menu
  const bestActiveChoice = allMenuPaths
    .filter((p) => currentPath?.includes(p))
    ?.reduce((res: string | undefined, s: string) => {
      if (!res) return s;
      return res.length < s.length ? s : res;
    }, undefined);

  return menuItem.menu_items_id.link === bestActiveChoice;
}
