import { Button } from '@cue/atoms';
import { useOnClickOutside } from '@cue/hooks';
import { AvailableLanguages, TimeUtil, getAbbr } from '@cue/utility';
import { useConfig } from '@project/hooks/use-config';
import { useUser } from '@project/hooks/use-user';
import React from 'react';
import { useTranslation } from 'react-i18next';

const dayjs = TimeUtil.getDayjs();

type TimezoneSwitchProps = {
  onTimezoneChange?: (timezone: string) => void;
};

export const TimezoneSwitch: React.FC<TimezoneSwitchProps> = ({ onTimezoneChange }) => {
  const { t, i18n } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(tooltipRef, () => setTooltipOpen(false));
  const config = useConfig();
  const { userTimezone, preferredTimezone, setPreferredTimezone } = useUser();

  const eventTimezone = config?.eventConfig?.timezone;

  const userAbbr =
    userTimezone && getAbbr(userTimezone, i18n.language as AvailableLanguages, dayjs());
  const venueAbbr = eventTimezone
    ? getAbbr(eventTimezone, i18n.language as AvailableLanguages, dayjs())
    : '';

  const yourTimeZone = `${t('agenda.yourTime')} (${userAbbr})`;
  const venueTimeZone = `${t('agenda.venueTime')} (${venueAbbr})`;
  if (userAbbr === venueAbbr) return null;
  const selectedTimeZone = preferredTimezone
    ? getAbbr(preferredTimezone, i18n.language as AvailableLanguages, dayjs())
    : venueAbbr;

  return (
    <div ref={tooltipRef} className={`cue-agenda-tz-switch`}>
      <Button
        icon="clock"
        onClick={() => setTooltipOpen(!tooltipOpen)}
        styling="iconOnly"
        className={`cue-agenda-tz-switch-trigger`}>
        {selectedTimeZone}
      </Button>
      {tooltipOpen && (
        <div
          className={`cue-agenda-tz-switch-options${
            preferredTimezone === userTimezone ? ' first-active' : ''
          }`}>
          <Button
            className={preferredTimezone === userTimezone ? 'is-active' : ''}
            onClick={() => {
              setTooltipOpen(false);
              onTimezoneChange
                ? userTimezone && onTimezoneChange(userTimezone)
                : userTimezone && setPreferredTimezone(userTimezone);
            }}>
            {yourTimeZone}
          </Button>
          <Button
            className={!preferredTimezone || preferredTimezone === eventTimezone ? 'is-active' : ''}
            onClick={() => {
              setTooltipOpen(false);
              onTimezoneChange
                ? eventTimezone && onTimezoneChange(eventTimezone)
                : eventTimezone && setPreferredTimezone(eventTimezone);
            }}>
            {venueTimeZone}
          </Button>
        </div>
      )}
    </div>
  );
};
