import { AgendaDisplayOptions, defaultAgendaDisplayOptions } from '../agenda';
import { AgendaReservationButtons } from '../reservation/reservation-buttons';
import { AgendaEventCategories } from './event-categories';
import { AgendaEventCta, AgendaEventCtaProps } from './event-cta';
import { AgendaEventSpeakersPreview } from './event-speaker';
import { AgendaEventTime, AgendaEventTimeProps } from './event-time';
import { SpeakerList } from './speaker-list';
import { AgendaTag } from './tag-button';
import { ProgramEvent } from '@cue/api';
import { Button, Heading } from '@cue/atoms';
import { StyledComponent } from '@cue/theme';
import { AnalyticsTransformer, Mdx, TimeUtil } from '@cue/utility';
import styled, { CSSObject } from '@emotion/styled';
import { LocationCategory } from '@project/cms/modules/agenda';
import { useConfig } from '@project/hooks/use-config';
import { useUser } from '@project/hooks/use-user';
import { Flag } from '@project/providers/flags-provider/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// TODO: move to agenda settings
const DEFAULT_ICONS = { ['virtual']: 'virtual', ['on-site']: 'buildings' };

export const AgendaEvent: React.FC<AgendaEventProps & StyledComponent<AgendaEventCSSOverride>> = (
  props
) => {
  const {
    id,
    start,
    end,
    startDisplay,
    endDisplay,
    persons,
    title,
    description,
    categories,
    location,
    locationCategories,
    includeCta,
    hideLocation,
    customTimeRenderer,
    isMarked,
    isVodVisibleFlag,
    links,
    participants,
    disabled,
    agendaDisplayOptions,
    layout = AgendaEventLayout.LIST,
    language,
    onSelect,
    openVod = false,
  } = props;
  const { t } = useTranslation();
  const { isAuthenticated } = useUser();

  const displayOptions = { ...defaultAgendaDisplayOptions, ...agendaDisplayOptions };

  const [more, setMore] = React.useState(displayOptions?.readmoreOpen);
  const [isClamped, setIsClamped] = React.useState(false);
  const descriptionRef = React.useRef<HTMLDivElement>(null);
  const { eventConfig } = useConfig();

  React.useEffect(() => {
    if (!descriptionRef.current) return;
    setIsClamped(descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight);
  }, []);

  const locationTag = React.useMemo(() => {
    const locationReplacement = locationCategories?.find((locCat) =>
      categories?.find((evCat) => locCat.category.id === evCat.id)
    );

    if (locationReplacement) {
      return {
        title: locationReplacement.category.title,
        color: locationReplacement.color,
        icon: locationReplacement.icon?.component,
        link: locationReplacement.link,
      };
    }

    if (hideLocation) {
      return null;
    }

    const loc = location || eventConfig.defaultLocation;

    return {
      title: loc,
      color: undefined,
      icon: DEFAULT_ICONS[loc as keyof typeof DEFAULT_ICONS],
    };
  }, [locationCategories, categories, location, hideLocation, eventConfig.defaultLocation]);

  const showReadMoreBtn =
    (!disabled &&
      !displayOptions.readmoreOpen &&
      descriptionRef.current &&
      isClamped &&
      Boolean(description)) ||
    (!displayOptions.readmoreOpen && displayOptions?.speakerList && Boolean(persons?.length));

  const isFutureEvent = TimeUtil.getNowUtc().isBefore(
    TimeUtil.getUtc(end).add(eventConfig.sessionAvailable, 'minutes')
  );
  const showSignupAndEventButtons =
    !disabled &&
    isAuthenticated &&
    displayOptions.signUpButtons &&
    isFutureEvent &&
    participants?.signUp;

  return (
    <Container
      className={`cue-agenda-event-wrapper${isMarked ? ' is-marked' : ''} ${
        layout === AgendaEventLayout.GRID ? ' cue-agenda-event-grid' : ' cue-agenda-event-list'
      }`}
      id={id}>
      <div className="cue-agenda-event-top-wrapper">
        <AgendaEventTime
          {...{
            start: startDisplay ? startDisplay : start,
            end: endDisplay ? endDisplay : end,
            customTimeRenderer,
            showTimeZone: displayOptions?.timezone,
          }}
          eventLanguage={language}
        />
        {displayOptions?.locationTag && locationTag ? (
          <AgendaTag tabIndex={-1} {...locationTag} className="cue-agenda-event-location-tag">
            {locationTag.title}
          </AgendaTag>
        ) : null}
      </div>

      <Content className="cue-agenda-event-content">
        <div className="cue-agenda-event-content-title-wrapper">
          <Heading
            type="h3"
            className="cue-agenda-event-title"
            style={{ cursor: onSelect ? 'pointer' : 'initial' }}
            onClick={onSelect ? () => onSelect && onSelect() : undefined}>
            {title}
          </Heading>
        </div>
        <Description className="cue-agenda-event-description-wrapper">
          <DescText isMore={more} ref={descriptionRef} className="cue-agenda-event-description">
            <Mdx>{description}</Mdx>
          </DescText>
          <div className="cue-agenda-event-speaker-list">
            {!disabled && displayOptions?.speakerList && persons?.length && more ? (
              <SpeakerList
                speakers={persons}
                agendaDisplayOptions={displayOptions}
                locationCategories={locationCategories}
                hideLocation={hideLocation}
              />
            ) : null}
          </div>
          {showReadMoreBtn && (
            <Button
              styling="readMore"
              icon={more ? 'close' : 'plus'}
              onClick={() => {
                const state = !more;
                setMore(state);
                if (state) {
                  AnalyticsTransformer.customEvent([
                    'agenda',
                    `readmore||${JSON.stringify({ uid: id })}`,
                  ]);
                }
              }}
              className="cue-agenda-event-description-show-more-btn">
              {more ? t('agenda.showless') : t('agenda.readmore')}
            </Button>
          )}
        </Description>

        {showSignupAndEventButtons && (
          <div className="cue-agenda-event-reservation">
            <AgendaReservationButtons eventId={id} />
          </div>
        )}
      </Content>

      <div className="cue-agenda-event-bottom-wrapper">
        {!disabled && displayOptions?.speakerPreview && Boolean(persons?.length) && !more && (
          <AgendaEventSpeakersPreview
            persons={persons}
            agendaDisplayOptions={displayOptions}
            locationCategories={locationCategories}
            hideLocation={hideLocation}
          />
        )}
        {displayOptions?.categories && (
          <AgendaEventCategories categories={categories} locationCategories={locationCategories} />
        )}
      </div>
      {displayOptions.sideButtons && !disabled && (
        <AgendaEventCta
          {...{ id, start, end, persons, title, includeCta, isVodVisibleFlag, links }}
          agendaSideButtonDisplayOptions={displayOptions.sideButtons}
          vodPublic={true}
          /*           vodPublic={displayOptions.vod_public || isAuthenticated}
           */ openVod={openVod}
        />
      )}

      {/* <Meta className="cue-agenda-event-meta" onClick={() => onSelect && onSelect()}>
        <AgendaEventTime
          {...{ start, end, customTimeRenderer, showTimeZone: displayOptions?.timezone }}
        /><
        {!disabled && displayOptions?.speakerPreview && (
          <AgendaEventSpeakersPreview persons={persons} agendaDisplayOptions={displayOptions} />
        )}
      </Meta>

     
      <div className="cue-agenda-event-action-btns">
        {displayOptions.sideButtons && !disabled && (
          <AgendaEventCta
            {...{ id, start, end, persons, title, includeCta, isVodVisibleFlag, links }}
            agendaSideButtonDisplayOptions={displayOptions.sideButtons}
          />
        )}
      </div> */}
    </Container>
  );
};

export type AgendaEventCSSOverride = {
  '.cue-agenda-event-wrapper'?: CSSObject;
  '.cue-agenda-event-meta'?: CSSObject;
  '.cue-agenda-event-content'?: CSSObject;
  '.cue-agenda-event-title'?: CSSObject;
  '.cue-agenda-event-description'?: CSSObject;
};

export enum AgendaEventLayout {
  LIST = 'AgendaEventLayout-List',
  GRID = 'AgendaEventLayout-Grid',
}

export const AgendaEventObserved: React.FC<
  AgendaEventProps & StyledComponent<AgendaEventCSSOverride>
> = (props) => {
  const [eventIsVisible, setEventIsVisible] = React.useState(false);
  const eventRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const eventWrapper = eventRef.current;

    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setEventIsVisible(true);
        if (eventWrapper) {
          observer.unobserve(eventRef.current);
        }
      }
    };

    const options = {
      root: null,
      rootMargins: '0px',
      threshold: 0.3,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (eventWrapper) {
      observer.observe(eventWrapper);
    }

    return () => {
      if (eventWrapper) {
        observer.unobserve(eventWrapper);
      }
    };
  }, [eventRef]);

  return (
    <>
      {eventIsVisible ? (
        <div ref={eventRef}>
          <AgendaEvent {...props} />
        </div>
      ) : (
        <div ref={eventRef}>
          <AgendaEvent {...props} disabled />
        </div>
      )}
    </>
  );
};

export interface AgendaEventProps
  extends ProgramEvent,
    Pick<AgendaEventCtaProps, 'includeCta'>,
    Pick<AgendaEventTimeProps, 'showTimeZone' | 'customTimeRenderer'> {
  key?: ProgramEvent['id'];
  locationCategories?: LocationCategory[];
  showActiveElements?: boolean;
  readmoreOpen?: boolean;
  showSpeaker?: boolean;
  showCategories?: boolean;
  hideLocation?: boolean;
  onSelect?: () => void;
  openVod?: boolean;
  isMarked?: boolean;
  isVodVisibleFlag?: Pick<Flag, 'slug'>;
  disabled?: boolean;
  agendaDisplayOptions?: AgendaDisplayOptions;
  layout?: AgendaEventLayout;
  startDisplay?: string;
  endDisplay?: string;
}
const Container = styled.div``;

const Content = styled.div``;
const Description = styled.div``;

export const DescText = styled.div<{ isMore?: boolean }>(
  ({ isMore }) => `

  overflow: hidden;
	display: -webkit-box;
  height: ${isMore ? 'initial' : '52px'};  
	-webkit-line-clamp: ${isMore ? 'initial' : '2'};
	-webkit-box-orient: vertical; 
  `
);
