import { ProgramEvent } from '@cue/api';
import { Button, LoadingSpinner } from '@cue/atoms';
import { Modal, useModal } from '@cue/organisms';
import { TimeUtil } from '@cue/utility';
import { EventFilter } from '@project/cms/modules';
import { useAgendaSettings } from '@project/hooks/use-agenda-settings';
import { useEvents } from '@project/hooks/use-events';
import { useResponsive } from '@project/hooks/use-responsive';
import { AgendaEvent, AgendaEventLayout } from '@project/site/modules/agenda';
import { AgendaEventCtaTypes } from '@project/site/modules/agenda/agenda-event/event-cta';
import { GridSlider } from '@project/site/modules/grid-slider/grid-slider';
import { navigate } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

const personalEventsFilter: EventFilter = {
  id: 'personal-events',
  name: 'Personal Events',
  json: {
    or: [
      {
        userHasBookmarked: [
          {
            var: 'id',
          },
        ],
      },
      {
        userIsParticipant: [
          {
            var: 'id',
          },
        ],
      },
    ],
  },
};

export const YourNextEvents: React.FC<CMS_Your_Next_Events_Content_Module_Data> = (props) => {
  const { event_count, title, sub_title, agenda_link } = props;
  const { events: personalEvents, isLoading } = useEvents({ filter: personalEventsFilter });
  const [currentEvent, setCurrentEvent] = React.useState<ProgramEvent>();
  const [eventModal] = useModal();
  const { t, i18n } = useTranslation();
  const { isLarge } = useResponsive();

  const myEventLink = `/${i18n.language}/my-event`;

  const openAgenda = (event: ProgramEvent) => {
    setCurrentEvent(event);
    eventModal?.current?.open();
  };

  const { getAgendaById } = useAgendaSettings();
  const agenda = React.useMemo(
    () => (props.agenda_settings?.id ? getAgendaById(props.agenda_settings?.id) : null),
    [props.agenda_settings?.id, getAgendaById]
  );

  // TODO: this is nor working properly -> check why
  const personalEventsInFuture = personalEvents
    ?.filter((e) => {
      return TimeUtil.getUtc(e.start).isAfter(TimeUtil.getNow());
    })
    .sort((a, b) => (TimeUtil.getUtc(a.start).isAfter(TimeUtil.getUtc(b.start)) ? 1 : -1));

  const sliderContent = personalEventsInFuture
    ?.map((event) => (
      <AgendaEvent
        layout={AgendaEventLayout.GRID}
        agendaDisplayOptions={{
          speakerAgenda: false,
          categories: false,
        }}
        includeCta={[
          AgendaEventCtaTypes.BOOKMARK,
          AgendaEventCtaTypes.MAIL,
          AgendaEventCtaTypes.ICS,
        ]}
        onSelect={() => openAgenda(event)}
        customTimeRenderer={
          !isLarge
            ? ({ start }) => (
                <>
                  {start.format('DD. MMM. YYYY')}
                  {' | '} {start.format('HH:mm')}
                </>
              )
            : undefined
        }
        key={event.id}
        locationCategories={agenda?.location_categories}
        hideLocation={agenda?.hide_location || false}
        {...event}
      />
    ))
    ?.slice(0, event_count) as React.ReactElement[];

  return (
    <div className="cue-your-next-events">
      {isLoading && <LoadingSpinner />}
      {title ? <h2 className="section-headline">{title}</h2> : null}
      {sub_title ? <p className="section-subline ">{sub_title}</p> : null}
      <div className="cue-your-next-events-slider">
        {personalEvents?.length ? (
          personalEventsInFuture?.length ? (
            <GridSlider
              breakpoints={{
                768: {
                  slidesPerView:
                    personalEventsInFuture.length >= 2 ? 2 : personalEventsInFuture.length,
                },
                1100: {
                  slidesPerView:
                    personalEventsInFuture.length >= 3 ? 3 : personalEventsInFuture.length,
                },
              }}
              spaceBetween={10}>
              {sliderContent}
            </GridSlider>
          ) : (
            <NoFuturePersonalEvents agenda_link={agenda_link} />
          )
        ) : (
          <NoPersonalEventsYet agenda_link={agenda_link} />
        )}
      </div>
      {!!personalEvents?.length && (
        <div>
          <Button styling="primary" onClick={() => navigate(myEventLink)}>
            {t('agenda.myschedule')}
          </Button>
        </div>
      )}
      <Modal
        ref={eventModal}
        overrideCSS={{
          '.cue-modal-content': { width: '95vw', maxWidth: 1280, background: 'transparent' },
          '.cue-modal-close-button-wrapper': { right: '30px', top: '30px' },
        }}>
        {currentEvent && (
          <AgendaEvent
            {...currentEvent}
            locationCategories={agenda?.location_categories}
            hideLocation={agenda?.hide_location || false}
          />
        )}
      </Modal>
    </div>
  );
};

type NoPersonalEventsYetProps = {
  agenda_link?: string;
};

const NoPersonalEventsYet: React.FC<NoPersonalEventsYetProps> = ({ agenda_link }) => {
  const { t } = useTranslation();
  return (
    <div className="cue-your-next-events-empty">
      <h3 className="cue-your-next-events-empty-title h5">{t('agenda.no-presonal-events')}</h3>
      <p className="cue-your-next-events-empty-description">
        {t('agenda.add-personal-events-teaser')}
      </p>
      {agenda_link ? (
        <Button styling="primary" onClick={() => navigate(agenda_link)}>
          {t('agenda.title')}
        </Button>
      ) : null}
    </div>
  );
};

const NoFuturePersonalEvents: React.FC<NoPersonalEventsYetProps> = ({ agenda_link }) => {
  const { t } = useTranslation();
  return (
    <div className="cue-your-next-events-empty">
      <h3 className="cue-your-next-events-empty-title h5">
        {t('agenda.no-future-presonal-events')}
      </h3>
      <p className="cue-your-next-events-empty-description">
        {t('agenda.add-personal-events-teaser')}
      </p>
      {agenda_link ? (
        <Button styling="primary" onClick={() => navigate(agenda_link)}>
          {t('agenda.title')}
        </Button>
      ) : null}
    </div>
  );
};
