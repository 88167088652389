import { CMS_Content_Module_FullscreenSloganData } from './types';
import { Heading, Image } from '@cue/atoms';
import { StyledComponent } from '@cue/theme';
import { Mdx } from '@cue/utility';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import parse from 'html-react-parser';

export const FullscreenSlogan: React.FC<
  FullscreenSloganProps & StyledComponent<FullscreenSloganCSSOverride>
> = (props) => {
  const { key_visual, focus_point_x, focus_point_y, slogan, title } = props;
  return (
    <Container className="cue-virtual-fullscreen-slogan">
      <Image src={key_visual?.src} focalPoint={{ x: focus_point_x, y: focus_point_y }} />
      <div className="cue-virtual-fullscreen-gradient"></div>
      <InnerContainer className="cue-virtual-fullscreen-container">
        <Content className="cue-virtual-fullscreen-content">
          {title && (
            <Heading type="h1"> {parse(title.replace(/(?:\r\n|\r|\n|\\n)/g, '<br />'))}</Heading>
          )}
          {slogan ? (
            <Heading type="h6">
              <Mdx>{slogan}</Mdx>
            </Heading>
          ) : null}
        </Content>
      </InnerContainer>
    </Container>
  );
};

const Content = styled.div``;
const InnerContainer = styled.div``;

export type FullscreenSloganCSSOverride = {
  '.cue-virtual-fullscreen-slogan'?: CSSObject;
  '.cue-virtual-fullscreen-content'?: CSSObject;
  '.cue-virtual-fullscreen-container'?: CSSObject;
};
export interface FullscreenSloganProps extends CMS_Content_Module_FullscreenSloganData {
  showTitle?: boolean;
  fallback?: React.ReactNode;
}

const Container = styled.div`
  width: 100%;
  position: relative;
`;
