import { useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';

export interface ChatClientConfig extends Partial<ChatClientConnectConfig> {
  url?: string;
  room?: string;
  autoConnect?: boolean;
}

export interface ChatClientConnectConfig {
  token?: string;
  url?: string;
  room?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: any; // TODO
}

/**
 * This is the data structure as defined for the interface of the chat ui components
 */
export interface ChatMessageProps {
  id: string;
  message: string;
  messageOwner?: string;
  userIsMessageOwner?: boolean;
  highlightMessage?: boolean;
  pending?: boolean;
  rejectedReason?: string;
  parent?: string | null;
  replies?: ChatMessageProps[];
}

/**
 * This is the data structure as it comes from the socket
 */
export interface ChatMessageSocket {
  id: string;
  rev: number;
  text: string | null;
  time: string;
  replies?: ChatMessageSocket[];
  username?: string;
  rejectedReason?: string;
  parent?: string | null;
  answers?: ChatMessageSocket[];
  reject?(reson: string): void;
  resolve?(): void;
}

export type ChatMessagesProps = ChatMessageProps[];

export type RecentEmoji = {
  time: number;
  emojiId: string;
};

export enum SOCKET_EVENTS {
  Reaction = 'reaction',
  Message = 'message',
  Welcome = 'welcome',
  Unapproved = 'unapproved',
  Accept = 'accept',
  Reject = 'reject',
  Error = 'err',
}
interface ChatState {
  username: string;
  chatToken?: string;
}

export const useChat = ({
  url: base_url,
  room: base_room,
  token: base_token,
  user: base_user,
  autoConnect = false,
}: ChatClientConfig): useChatReturn => {
  let url = base_url;
  let room = base_room;
  let user = base_user;
  let token = base_token;

  const [history, setHistory] = useState<ChatMessageSocket[]>([]);
  const [recentEmoji, setRecentEmoji] = useState<RecentEmoji | undefined>();
  const [pendingMessages, setPendingMessages] = useState<ChatMessageSocket[]>([]);

  const [, setChatState] = useState<ChatState>({
    chatToken: '',
    username: '',
  });

  const [socket, setSocket] = useState<Socket>();
  // create a socket and keep it during rerenderings as long as the ChatClientConfig
  // stays the same

  useEffect(() => {
    if (autoConnect && token && user) {
      connect({ token, user, url, room });
    }
  }, [token, user, url, room]);

  const connect = ({
    user: override_user = user,
    token: override_token = token,
    url: override_url = url,
    room: override_room = room,
  }: ChatClientConnectConfig) => {
    if (override_user !== user) user = override_user;
    if (override_token !== token) token = override_token;
    if (override_url !== url) url = override_url;
    if (override_room !== room) room = override_room;

    setChatState({
      username: `${user?.firstName} ${user?.lastName}`,
      chatToken: token,
    });
    try {
      const ioSocket = io(url || '', {
        auth: {
          room,
          token,
        },
      });

      setSocket(ioSocket);
    } catch (e) {
      console.error(e);
    }
  };

  const disconnect = () => {
    socket?.disconnect();
    console.debug('socket?.disconnect();');
  };

  /**
   * When the socket is initialized, process the incoming messages that have already been send
   * for this chat room
   *
   * @param previousHistory
   * @param previousPendingMessages
   * @param welcomeText
   */
  const onWelcome = (
    previousHistory: ChatMessageSocket[],
    previousPendingMessages: ChatMessageSocket[],
    welcomeText?: string
  ) => {
    // the welcome text wrapped in a ChatMessage object
    const welcomeMessage: ChatMessageSocket = {
      time: new Date().toUTCString(),
      text: welcomeText ? welcomeText : '',
      id: '',
      rev: 0,
    };

    // if a welcome text is given, add it as the first message, otherwise just use
    // the previousHistory as the new history
    const newHistory = welcomeMessage.text
      ? [welcomeMessage, ...previousHistory]
      : previousHistory.filter((m) => m?.text);

    setHistory((h) => [...h, ...newHistory]);
    setPendingMessages((p) => [...p, ...previousPendingMessages]);
  };

  /**
   * Sends a new message via the socket
   *
   * @param sendConfig
   */
  const send = ({
    text,
    username,
    parent = null,
  }: Pick<ChatMessageSocket, 'username' | 'text' | 'parent'>) => {
    console.debug('text', text, username, parent);
    socket?.emit(SOCKET_EVENTS.Message, text, parent, username);
  };

  /**
   * Sends a reaction via the socket
   *
   * @param sendConfig
   */
  const sendReaction = (emoji: string) => socket?.emit(SOCKET_EVENTS.Reaction, emoji);

  /**
   * When a message has been sent but not approved / rejected yet
   *
   * @param chatMessage
   * @param promise
   */
  const onUnapproved = async (chatMessage: ChatMessageSocket) =>
    setPendingMessages((p) => [...p, chatMessage]);

  /**
   * Moves a pending message into the history
   * If a rejectedReason is given, it will be added to the message
   *
   * @param id
   * @param rejectedReason
   */
  const moveFromPendingToHistory = (id: ChatMessageSocket['id'], rejectedReason?: string) => {
    const message = pendingMessages.find((p) => p.id === id);

    // mostly to keep typescript happy here
    if (!message) {
      return;
    }

    // pendingMessages = every pending message, but the one with the given id
    setPendingMessages((p) => p.filter((m) => m.id !== id));

    if (rejectedReason) {
      // if a rejected reason has been given, add it to the message...
      setHistory((h) => [...h, { ...message, rejectedReason: rejectedReason }]);
    }
  };

  /**
   * Gets called when a pending message has been accepted
   * @param id
   */
  const onAccept = (id: ChatMessageSocket['id']) => {
    moveFromPendingToHistory(id);
  };

  /**
   * Gets called when a pending message has been rejected
   *
   * @param id
   * @param rejectedReason
   */
  const onReject = (id: string, rejectedReason: string) => {
    console.warn('rejectReason', rejectedReason);
    moveFromPendingToHistory(id, rejectedReason);
  };

  /**
   * Gets called when a new message has been received
   * @param chatMessage
   */
  const onMessage = (chatMessage: ChatMessageSocket) => {
    const existingMessageIndex = history.findIndex((h) => h.id === chatMessage.id);

    if (existingMessageIndex !== -1) {
      const newHistory = [
        ...history.slice(0, existingMessageIndex),
        chatMessage,
        ...history.slice(existingMessageIndex + 1, history.length),
      ];

      setHistory(newHistory);

      return;
    }

    if (chatMessage?.text) {
      setHistory((h) => {
        const updatedMessageIndex = [...h].findIndex((m) => m.id === chatMessage.id);

        if (updatedMessageIndex !== -1) {
          const newHistory = [
            ...h.slice(0, updatedMessageIndex),
            chatMessage,
            ...h.slice(updatedMessageIndex + 1, h.length),
          ];

          return newHistory;
        }

        return [...h, chatMessage];
      });
    } else {
      setHistory((h) => [...h].filter((m) => m.id !== chatMessage.id));
    }
  };

  /**
   * Gets called when a new reaction has been received
   * @param emoji
   */
  const onReaction = (emoji: string) => {
    setRecentEmoji({
      emojiId: emoji,
      time: new Date().getTime(),
    });

    return emoji;
  };

  /**
   * disconnect socket on component unmount
   */
  useEffect(() => {
    if (!socket) return;
    socket.on(SOCKET_EVENTS.Welcome, onWelcome);
    socket.on(SOCKET_EVENTS.Unapproved, onUnapproved);
    socket.on(SOCKET_EVENTS.Message, onMessage);
    socket.on(SOCKET_EVENTS.Reaction, onReaction);
    socket.on(SOCKET_EVENTS.Error, console.error);
    socket.on('connect', function () {
      console.debug('Connected to WS server');
    });

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  /* const historyStacked = (messages: ChatMessagesProps, parentId?: string): ChatMessagesProps => {
    const filteredMessages = messages?.filter((message) => message.parent === parentId);
    const nestedArray = [];

    for (const message of filteredMessages) {
      const nestedReplies = historyStacked(messages, message.id);
      nestedArray.push({ ...message, replies: nestedReplies });
    }

    return nestedArray;
  }; */

  /**
   * since the callbacks are passed by value they need to be reassigned when pendingMessages changes
   */
  useEffect(() => {
    if (!socket) return;
    socket.on(SOCKET_EVENTS.Accept, onAccept);
    socket.on(SOCKET_EVENTS.Reject, onReject);

    return () => {
      socket.off(SOCKET_EVENTS.Accept, onAccept);
      socket.off(SOCKET_EVENTS.Reject, onReject);
    };
  }, [socket, pendingMessages]);

  const mergeChildMessages = (messages: ChatMessageSocket[]) => {
    return messages
      .map((message) => {
        const newMessage = message;
        //newMessage.rejectedReason = 'This is rejected';
        // has answers
        const answers = messages.filter((answer) => answer.parent === message.id);
        if (answers) {
          newMessage.answers = answers;
        }
        return newMessage;
      })
      .filter((message) => !message.parent);
  };

  // return the history, the pending messages and the send function to the hook's callee
  return {
    connect,
    disconnect,
    history: mergeChildMessages(history),
    pendingMessages,
    send,
    sendReaction,
    recentEmoji,
    connected: Boolean(socket?.connected),
  };
};
export interface useChatReturn {
  connect(props: ChatClientConnectConfig): void;
  disconnect(): void;
  history: ChatMessageSocket[];
  pendingMessages: ChatMessageSocket[];
  send(props: Pick<ChatMessageSocket, 'username' | 'text' | 'parent'>): void;
  sendReaction(emoji: string): void;
  recentEmoji: RecentEmoji | undefined;
  connected: boolean;
}
