import { Countdown, ThemedIcon } from '@cue/atoms';
import { TimeUtil } from '@cue/utility';
import { useTheme } from '@emotion/react';
import { CMS_Content_Module_LiveStreamData } from '@project/cms/modules/livestream';
import { LivestreamStatusType } from '@project/hooks/use-livestream-status';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface LivestreamStatusOverlayProps {
  status?: LivestreamStatusType;
  data: Partial<
    Pick<
      CMS_Content_Module_LiveStreamData,
      'no_stream_fallback' | 'no_stream_before' | 'no_stream_after'
    >
  >;
  showCountdown?: boolean;
  nextEvent?: string;
  small?: boolean;
  isThumb?: boolean;
}
export const LivestreamStatusOverlay = (props: LivestreamStatusOverlayProps) => {
  const {
    status = LivestreamStatusType.ACTIVE,
    data,
    showCountdown = false,
    nextEvent = TimeUtil.getNowUtc().format(),
    small = false,
    isThumb = false,
  } = props;

  const theme = useTheme();
  const { t } = useTranslation();

  const getLabel = (state: LivestreamStatusType) => {
    const labelMapping: Record<LivestreamStatusType, string | null> = {
      [LivestreamStatusType.ACTIVE]: null,
      [LivestreamStatusType.IDLE]: data?.no_stream_fallback || t('livestream.status.idle'),
      [LivestreamStatusType.BEFORE_EVENT]:
        data?.no_stream_before || t('livestream.status.before-event'),
      [LivestreamStatusType.AFTER_EVENT]:
        data?.no_stream_after || t('livestream.status.after-event'),
      [LivestreamStatusType.ISSUE]: t('livestream.status.issue'),
      [LivestreamStatusType.DEFAULT]: null,
    };
    return labelMapping[state];
  };
  const label = useMemo(() => getLabel(status), [status, data]);
  if (status === LivestreamStatusType.ACTIVE) return null;

  return (
    <div
      className={`cue-livestream-status-overlay cue-livestream-status-overlay-${status} ${
        small ? 'cue-livestream-status-overlay-small' : ''
      } ${isThumb ? 'cue-livestream-status-overlay-is-thumb' : ''}`}>
      {status === LivestreamStatusType.ISSUE && ThemedIcon({ icon: 'warning', theme })}
      {showCountdown && status === LivestreamStatusType.BEFORE_EVENT && (
        <Countdown end={TimeUtil.getUtc(nextEvent)} />
      )}
      <div>{label}</div>
    </div>
  );
};
