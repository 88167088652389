import { AgendaDisplayOptions } from '../../agenda';
import { AgendaSearch, AgendaSearchRef } from '../../agenda-search';
import { ProgramEvent } from '@cue/api';
import { Button, Checkbox, Heading, Toggle } from '@cue/atoms';
import { TagSelectionTagProps } from '@cue/molecules';
import { StyledComponent, generateCss } from '@cue/theme';
import { AnalyticsTransformer } from '@cue/utility';
import { css } from '@emotion/css';
import { CSSObject, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useResponsive } from '@project/hooks/use-responsive';
import { useUser } from '@project/hooks/use-user';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AgendaFilterDropdown = ({
  styling = 'default',
  selected,
  items,
  onChange,
  clearAll,
  onApply,
  agendaDisplayOptions,
  events,
  onSearched,
  agendaSearchRef,
  onToggleMySchedule,
  myScheduleOpened,
  scheduleEventsCount,
  onToggleSuggestions,
  suggestionsActive = false,
}: AgendaFilterDropdownProps & StyledComponent<AgendaFilterDropdownCSSOverride>) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openedItem, setOpenedItem] = useState('');
  const [scheduleOpen, setScheduleOpen] = useState(myScheduleOpened || false);
  const [showSuggestions, setShowSuggestions] = useState(suggestionsActive);
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const _styling = useMemo(() => {
    return generateCss({ type: 'custom.agendaDropdown.container', styling, theme }) || [];
  }, [styling, theme]);
  const { t } = useTranslation();
  const { isAuthenticated } = useUser();

  const toggleMySchedule = () => {
    const state = !scheduleOpen;
    setScheduleOpen(state);
    onToggleMySchedule(state);
    setShowSuggestions(false);
    onToggleSuggestions && onToggleSuggestions(false);
  };

  const toggleSuggestions = () => {
    const state = !showSuggestions;
    setShowSuggestions(state);
    onToggleSuggestions(state);
    setScheduleOpen(false);
    onToggleMySchedule(false);
  };

  useEffect(() => {
    let checkClose = false;

    function listener(ev: MouseEvent) {
      const path = ev.composedPath();
      if (openedItem) {
        if (checkClose && dropdownRef.current && !path.includes(dropdownRef.current)) {
          setOpenedItem('');
        }
        checkClose = true;
      } else {
        checkClose = false;
      }
    }
    if (openedItem) {
      document.addEventListener('click', listener);
    }

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [openedItem]);

  return (
    <StyledFilterDropdownContainer className="cue-agenda-filter-wrapper-container">
      <StyledFilterDropdownWrapper
        css={_styling}
        className={`cue-agenda-filter-wrapper ${css([_styling])}`}>
        <StyledFilterDropdownList className="cue-agenda-filter-list">
          {items.map((item, itemIndex) => {
            const isOpen = openedItem === item.title;
            const selectedItems = item.children.filter((c) => selected?.includes(c.value)) || [];
            if (isMobile) {
              return (
                <StyledFilterDropdownItemWrapper
                  key={itemIndex}
                  className="cue-agenda-filter-item-mobile">
                  <Heading type="h6" icon={item.icon}>
                    {item.title}
                  </Heading>
                  {item.children.map((child, i) => {
                    return (
                      <StyledDropdownChild key={i}>
                        <Checkbox
                          checked={selected?.includes(child.value)}
                          label={child.label}
                          onChange={() => onChange(child)}
                          iconChecked="check"
                        />
                      </StyledDropdownChild>
                    );
                  })}
                </StyledFilterDropdownItemWrapper>
              );
            }
            return (
              <StyledFilterDropdownItemWrapper
                key={itemIndex}
                className={`cue-agenda-filter-list-item ${
                  selectedItems.length > 0 ? 'cue-agenda-filter-list-item--active' : ''
                }`}>
                <Button
                  iconAlign="left"
                  icon={item.icon}
                  className={`cue-agenda-filter-item${
                    selectedItems.length > 0 ? ' cue-agenda-filter-item--active' : ''
                  }`}
                  styling={isOpen || isMobile ? 'filterDropdownItemOpened' : 'filterDropdownItem'}
                  onClick={() => setOpenedItem(!isOpen ? item.title : '')}>
                  {selectedItems.length > 0
                    ? selectedItems.map((s, i) => `${i > 0 ? ', ' : ''}${s.label}`)
                    : item.title}
                </Button>
                {(isOpen || isMobile) && (
                  <StyledDropdownChildrenWrapper
                    className="cue-agenda-filter-dropdown"
                    ref={dropdownRef}>
                    {item.children.map((child, i) => {
                      return (
                        <StyledDropdownChild key={i}>
                          <Checkbox
                            checked={selected?.includes(child.value)}
                            label={child.label}
                            onChange={() => onChange(child)}
                            iconChecked="check"
                          />
                        </StyledDropdownChild>
                      );
                    })}
                  </StyledDropdownChildrenWrapper>
                )}
              </StyledFilterDropdownItemWrapper>
            );
          })}

          {isAuthenticated &&
            (isMobile ? (
              <div className="cue-agenda-filter-item-mobile">
                <Heading type="h6" icon="monitor">
                  {t('agenda.suggestions')}
                </Heading>
                <Toggle checked={showSuggestions} onChange={() => toggleSuggestions()} />
              </div>
            ) : (
              <Button
                iconAlign="left"
                icon="monitor"
                styling={showSuggestions ? 'filterDropdownItemOpened' : 'filterDropdownItem'}
                className="cue-agenda-suggestions-button"
                onClick={toggleSuggestions}>
                {t('agenda.suggestions')}
              </Button>
            ))}

          {isAuthenticated && (
            <Button
              iconAlign="left"
              icon={scheduleEventsCount ? 'favouriteMarked' : 'favourite'}
              styling={scheduleOpen ? 'filterDropdownItemOpened' : 'filterDropdownItem'}
              className="cue-agenda-myschedule-button"
              onClick={() => {
                toggleMySchedule();
              }}>
              {t('agenda.myschedule')} {scheduleEventsCount ? ` (${scheduleEventsCount})` : ''}
            </Button>
          )}
        </StyledFilterDropdownList>
        <StyledFilterDropdownAction className="cue-agenda-filter-action">
          {selected && selected.length > 0 && (
            <>
              <Button
                onClick={() => clearAll()}
                styling={isMobile ? 'tertiary' : 'agendaActionButton'}>
                {t('agenda.clearall')}
              </Button>
            </>
          )}
          {isMobile && (
            <Button onClick={() => onApply && onApply()} styling="primary">
              {t('agenda.apply')}
            </Button>
          )}
        </StyledFilterDropdownAction>
        <StyledFilterDropdownSearch className="cue-agenda-filter-search">
          {agendaDisplayOptions.search && (
            <AgendaSearch
              ref={agendaSearchRef}
              search={{
                input: events,
              }}
              onSearched={(search) => {
                onSearched(search);
                AnalyticsTransformer.customEvent([
                  'agenda-search',
                  JSON.stringify({
                    searchterm: search.searchTerm,
                    numberofresults: search.result.length,
                  }),
                ]);
              }}
            />
          )}
        </StyledFilterDropdownSearch>
      </StyledFilterDropdownWrapper>
    </StyledFilterDropdownContainer>
  );
};
const StyledFilterDropdownContainer = styled.div``;
const StyledFilterDropdownWrapper = styled.div``;
const StyledFilterDropdownList = styled.div``;
const StyledFilterDropdownAction = styled.div``;
const StyledFilterDropdownSearch = styled.div``;

const StyledFilterDropdownItemWrapper = styled.div``;

const StyledDropdownChildrenWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const StyledDropdownChild = styled.div({
  '&:not(:first-of-type)': {
    marginTop: 8,
  },
});

interface AgendaFilterDropdownProps {
  styling?: string;
  items: { title: string; icon?: string; children: TagSelectionTagProps[] }[];
  selected?: string[];
  onChange: (tag: TagSelectionTagProps) => void;
  clearAll: () => void;
  onApply: () => void;
  agendaDisplayOptions: AgendaDisplayOptions;
  events: ProgramEvent[];
  onSearched: (search: { searchTerm: string; result: unknown[] }) => void;
  agendaSearchRef: RefObject<AgendaSearchRef>;
  onToggleMySchedule: (active: boolean) => void;
  onToggleSuggestions: (active: boolean) => void;
  suggestionsActive?: boolean;
  myScheduleOpened?: boolean;
  scheduleEventsCount?: number;
}

export type AgendaFilterDropdownCSSOverride = {
  '& .cue-agenda-filter-dropdown'?: CSSObject;
};
