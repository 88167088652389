import { eventConfig } from '../../../../config/event';
import { useFlag } from '../../../../providers/flags-provider';
import { AgendaSideButtonDisplayOptions, defaultAgendaDisplayOptions } from '../agenda';
import {
  getDocumentDownload,
  getDocumentDownloads,
  getVideo,
  getVideoStartTime,
} from '../util/agenda-transformer';
import { ProgramEvent } from '@cue/api';
import { Button, Tooltip } from '@cue/atoms';
import { useIcs, useMailto } from '@cue/hooks';
import { Video, VideoTypes } from '@cue/molecules';
import { Modal, useModal } from '@cue/organisms';
import { StyledComponent } from '@cue/theme';
import { AnalyticsTransformer, BrowserUtil, TimeUtil } from '@cue/utility';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaAnalytics } from '@project/hooks/use-media-analytics';
import { useUser } from '@project/hooks/use-user';
import { useUserSubscriptions } from '@project/hooks/use-user-subscriptions';
import { Flag } from '@project/providers/flags-provider/types';
import multiDownload from 'multi-download';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

export const AgendaEventCta: React.FC<
  AgendaEventCtaProps & StyledComponent<AgendaEventCtaCSSOverride>
> = (props) => {
  const {
    id,
    start,
    end,
    title,
    includeCta = [
      AgendaEventCtaTypes.BOOKMARK,
      AgendaEventCtaTypes.ICS,
      AgendaEventCtaTypes.MAIL,
      AgendaEventCtaTypes.DOCUMENTDOWNLOAD,
    ],
    isVodVisibleFlag,
    openVod = false,
    links,
    agendaSideButtonDisplayOptions,
    vodPublic = false,
  } = props;

  const displayOptions = {
    ...defaultAgendaDisplayOptions.sideButtons,
    ...agendaSideButtonDisplayOptions,
  };

  const isMobile = BrowserUtil.isMobile();
  const { isAuthenticated } = useUser();
  const { bookmarks, toggleBookmark } = useUserSubscriptions();
  const isBookmarked = bookmarks.data?.includes(id);
  const isFutureEvent = TimeUtil.getNowUtc().isBefore(TimeUtil.getUtc(end));
  const vodVisible = useFlag(String(isVodVisibleFlag));
  const videoStartTime = getVideoStartTime(links ?? []);
  const videoUrl = getVideo(links ?? []);
  const documentUrls = getDocumentDownloads(links ?? []);
  const [modalRef] = useModal();

  useEffect(() => {
    if (openVod) {
      setTimeout(() => modalRef.current?.open(), 500);
      // const url = new URL(window.location.href);
      // url.searchParams.delete('start-vod');
      // const newUrl = url.search ? url.href : url.href.replace('?', '');
      //window.history.replaceState({}, document.title, newUrl);
    }
  }, []);

  const ctaData = {
    title,
    url: `${location.origin + location.pathname}?uid=${id}`,
    regUrl: 'regUrl',
    date: TimeUtil.getUtc(start).format('LL'),
    time: `${TimeUtil.convertToTimeZone(TimeUtil.getUtc(start), eventConfig.timezone).format(
      'HH:mm'
    )}`,
  };
  const { t } = useTranslation();

  const { download: downloadIcs } = useIcs();
  const { send: sendMail } = useMailto();
  const toolTipFavId = useRef(`bookmark-tooltip${uuidv4()}`);
  const toolTipMailId = useRef(`mail-tooltip${uuidv4()}`);
  const toolTipCalId = useRef(`cal-tooltip${uuidv4()}`);
  const toolTipDownload = useRef(`download-tooltip${uuidv4()}`);

  return (
    <Container className="cue-agenda-event-cta-wrapper">
      {isAuthenticated &&
        includeCta.includes(AgendaEventCtaTypes.BOOKMARK) &&
        displayOptions?.bookmark && (
          <>
            <Button
              data-tooltip-id={toolTipFavId.current}
              icon={isBookmarked ? 'favouriteMarked' : 'favourite'}
              styling="agendaCta"
              className={`cue-agenda-event-cta-bookmark`}
              onClick={() => {
                toggleBookmark(id);
                AnalyticsTransformer.customEvent([
                  'bookmark',
                  `${!isBookmarked ? 'add' : 'remove'}||${JSON.stringify({
                    uid: id,
                  })}`,
                ]);
              }}
            />
            {!isMobile && (
              <Tooltip place="bottom" variant="light" id={toolTipFavId.current}>
                <strong>{t(`agenda.tooltip.bookmark${isBookmarked ? 'Remove' : ''}`)}</strong>
              </Tooltip>
            )}
          </>
        )}

      {includeCta.includes(AgendaEventCtaTypes.MAIL) && displayOptions?.shareViaMail && (
        <>
          <Button
            data-tooltip-id={toolTipMailId.current}
            icon="mailto"
            styling="agendaCta"
            className="cue-agenda-event-cta-mailto"
            onClick={() => {
              sendMail(
                {
                  subject: t('agenda.mail-to.subject'),
                  body: t('agenda.mail-to.message'),
                },
                ctaData
              );
              AnalyticsTransformer.customEvent([
                'share-ptn',
                `mail||${JSON.stringify({ uid: id })}`,
              ]);
            }}
          />
          <Tooltip place="bottom" variant="light" id={toolTipMailId.current}>
            <strong>{t('agenda.tooltip.mail-share')}</strong>
          </Tooltip>
        </>
      )}
      {includeCta.includes(AgendaEventCtaTypes.ICS) && isFutureEvent && displayOptions?.iCal && (
        <>
          <Button
            data-tooltip-id={toolTipCalId.current}
            icon="ics"
            styling="agendaCta"
            className="cue-agenda-event-cta-ics"
            onClick={() => {
              downloadIcs(
                {
                  subject: t('agenda.ics.subject'),
                  start: TimeUtil.getUtc(start),
                  end: TimeUtil.getUtc(end),
                  description: t('agenda.ics.message') || '',
                  alarm: 30,
                },
                ctaData
              );
              AnalyticsTransformer.customEvent([
                'share-ptn',
                `ics||${JSON.stringify({ uid: id })}`,
              ]);
            }}
          />
          <Tooltip place="bottom" variant="light" id={toolTipCalId.current}>
            <strong>{t('agenda.tooltip.ical')}</strong>
          </Tooltip>
        </>
      )}
      {vodVisible !== false &&
        vodPublic &&
        videoUrl &&
        displayOptions?.play &&
        TimeUtil.getUtc(end).isBefore(TimeUtil.getNowUtc().add(5, 'minutes')) && (
          <Button
            icon="play"
            styling="agendaCta"
            className="cue-agenda-event-cta-play-video"
            onClick={() => {
              modalRef.current?.open();
              AnalyticsTransformer.customEvent([
                'share-ptn',
                `vod||${JSON.stringify({ uid: id })}`,
              ]);
            }}></Button>
        )}

      {includeCta.includes(AgendaEventCtaTypes.DOCUMENTDOWNLOAD) &&
        displayOptions.documentDownload &&
        documentUrls && (
          <>
            {documentUrls.map((url, ind) => {
              return (
                /* url: url.href,
  name: url.href.substring(url.href.lastIndexOf('/') + 1), */
                <>
                  {' '}
                  <Button
                    data-tooltip-id={`${toolTipDownload.current}-${ind}`}
                    icon="download"
                    styling="agendaCta"
                    className="cue-agenda-event-cta-download"
                    onClick={() => {
                      window.open(url.href, '_blank');
                    }}
                  />
                  <Tooltip place="bottom" variant="light" id={`${toolTipDownload.current}-${ind}`}>
                    <span>
                      {t('agenda.tooltip.documentdownload')} <br />
                      {url.href.substring(url.href.lastIndexOf('/') + 1)}
                    </span>
                  </Tooltip>
                </>
              );
            })}
          </>
        )}

      <Modal
        ref={modalRef}
        overrideCSS={{
          '.cue-modal-content': {},
          '.cue-modal-close-button-wrapper': { right: '30px', top: '30px' },
        }}>
        <Video
          playing={!openVod}
          url={videoUrl}
          startTime={videoStartTime || 0}
          useMediaAnalytics={useMediaAnalytics}
          videoType={VideoTypes.VoD}
          trackingTitle={`vod-${id}`}
        />
      </Modal>
    </Container>
  );
};

export type AgendaEventCtaCSSOverride = {
  '.cue-agenda-event-cta-wrapper'?: CSSObject;
  '.cue-agenda-event-cta-bookmark'?: CSSObject;
  '.cue-agenda-event-cta-ics'?: CSSObject;
  '.cue-agenda-event-cta-mailto'?: CSSObject;
  '.cue-agenda-event-cta-download'?: CSSObject;
};
export interface AgendaEventCtaProps
  extends Pick<ProgramEvent, 'id' | 'start' | 'end' | 'title' | 'links'> {
  includeCta?: AgendaEventCtaTypes[];
  isVodVisibleFlag?: Pick<Flag, 'slug'>;
  openVod?: boolean;
  agendaSideButtonDisplayOptions?: AgendaSideButtonDisplayOptions;
  vodPublic?: boolean;
}
export enum AgendaEventCtaTypes {
  BOOKMARK = 'BOOKMARK',
  ICS = 'ICS',
  MAIL = 'MAIL',
  DOCUMENTDOWNLOAD = 'DOCUMENTDOWNLOAD',
}

const Container = styled.div``;
