export const FlagDe = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_585_10859)">
        <path
          d="M0.683594 14.8261C2.23782 19.0148 6.26979 22 10.9994 22C15.729 22 19.761 19.0148 21.3152 14.8261L10.9994 13.8696L0.683594 14.8261Z"
          fill="#FFDA44"
        />
        <path
          d="M10.9994 0C6.26979 0 2.23782 2.98513 0.683594 7.17393L10.9994 8.13042L21.3152 7.17389C19.761 2.98512 15.729 0 10.9994 0Z"
          fill="black"
        />
        <path
          d="M0.684191 7.17393C0.242043 8.36559 0 9.65448 0 11C0 12.3455 0.242043 13.6344 0.684191 14.8261H21.3159C21.758 13.6344 22 12.3455 22 11C22 9.65448 21.758 8.36559 21.3158 7.17393H0.684191Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_585_10859">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FlagEn = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_585_10875)">
        <path
          d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
          fill="#F0F0F0"
        />
        <path
          d="M2.27387 4.30297C1.40981 5.42717 0.758277 6.72289 0.378906 8.1305H6.1014L2.27387 4.30297Z"
          fill="#0052B4"
        />
        <path
          d="M21.6208 8.1305C21.2415 6.72293 20.5899 5.42721 19.7259 4.30302L15.8984 8.1305H21.6208Z"
          fill="#0052B4"
        />
        <path
          d="M0.378906 13.8696C0.75832 15.2772 1.40986 16.5729 2.27387 17.6971L6.10127 13.8696H0.378906Z"
          fill="#0052B4"
        />
        <path
          d="M17.6966 2.27395C16.5724 1.40989 15.2768 0.758355 13.8691 0.378941V6.10139L17.6966 2.27395Z"
          fill="#0052B4"
        />
        <path
          d="M4.30273 19.726C5.42693 20.5901 6.72265 21.2416 8.13022 21.6211V15.8987L4.30273 19.726Z"
          fill="#0052B4"
        />
        <path
          d="M8.13018 0.378941C6.72261 0.758355 5.42688 1.40989 4.30273 2.27391L8.13018 6.10135V0.378941Z"
          fill="#0052B4"
        />
        <path
          d="M13.8691 21.6211C15.2767 21.2416 16.5724 20.5901 17.6966 19.7261L13.8691 15.8987V21.6211Z"
          fill="#0052B4"
        />
        <path
          d="M15.8984 13.8696L19.7259 17.6971C20.5899 16.573 21.2415 15.2772 21.6208 13.8696H15.8984Z"
          fill="#0052B4"
        />
        <path
          d="M21.9069 9.56523H12.4349H12.4348V0.0931133C11.9651 0.0319688 11.4863 0 11 0C10.5136 0 10.0349 0.0319688 9.56523 0.0931133V9.56514V9.56519H0.0931133C0.0319688 10.0349 0 10.5137 0 11C0 11.4864 0.0319688 11.9651 0.0931133 12.4348H9.56514H9.56519V21.9069C10.0349 21.968 10.5136 22 11 22C11.4863 22 11.9651 21.9681 12.4348 21.9069V12.4349V12.4348H21.9069C21.968 11.9651 22 11.4864 22 11C22 10.5137 21.968 10.0349 21.9069 9.56523Z"
          fill="#D80027"
        />
        <path
          d="M13.8691 13.8696L18.7777 18.7782C19.0035 18.5525 19.2188 18.3166 19.4243 18.072L15.2219 13.8696H13.8691V13.8696Z"
          fill="#D80027"
        />
        <path
          d="M8.13128 13.8696H8.13119L3.22266 18.7782C3.44833 19.0039 3.68427 19.2193 3.92885 19.4248L8.13128 15.2222V13.8696Z"
          fill="#D80027"
        />
        <path
          d="M8.13134 8.13051V8.13042L3.22277 3.2218C2.99701 3.44747 2.78165 3.68341 2.57617 3.92799L6.77864 8.13047L8.13134 8.13051Z"
          fill="#D80027"
        />
        <path
          d="M13.8691 8.1305L18.7778 3.22184C18.5521 2.99608 18.3161 2.78072 18.0716 2.57529L13.8691 6.77776V8.1305Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_585_10875">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const flagMap = {
  de: FlagDe(),
  en: FlagEn(),
};
