import { AnalyticsTransformer } from '@cue/utility';
import { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';

const mediaAnalytics = AnalyticsTransformer.getMediaTracker();
export const useMediaAnalytics = ({
  player,
  title,
  src,
  type = 'VoD',
}: useMediaAnalyticsProps): useMediaAnalyticsReturn => {
  const [internalPlayer, setInternalPlayer] = useState<HTMLVideoElement>();
  const tracker = useMemo(
    () =>
      mediaAnalytics
        ? new mediaAnalytics.MediaTracker(type, mediaAnalytics.mediaType.VIDEO, `${src}`)
        : null,
    [src, title]
  );

  useEffect(() => {
    console.debug('UPDATE TITLE');
    onEnded();
  }, [title]);

  useEffect(() => {
    if (!internalPlayer && player?.getInternalPlayer()) {
      setInternalPlayer(player?.getInternalPlayer() as HTMLVideoElement);
    }
  }, [title, player, player?.getInternalPlayer()]);

  useEffect(() => {
    if (internalPlayer && mediaAnalytics) {
      tracker.setWidth(internalPlayer.clientWidth);
      tracker.setHeight(internalPlayer.clientHeight);
      tracker.setFullscreen(mediaAnalytics.element.isFullscreen(internalPlayer));
      tracker.setMediaTitle(title);
      if (type === 'Live') setTimeout(onPlay, 1000);
      console.debug('MATOMO resource', title);

      console.debug('MATOMO mediaAnalytics', mediaAnalytics, player, internalPlayer);

      removeListeners();
      const capture = true;
      internalPlayer.addEventListener('play', onPlay, capture);
      internalPlayer.addEventListener('pause', onPause, capture);
      internalPlayer.addEventListener('ended', onEnded, capture);
      internalPlayer.addEventListener('seeking', onSeeking, capture);
      internalPlayer.addEventListener('seeked', onSeeked, capture);
      internalPlayer.addEventListener('timeupdate', onTimeUpdate, capture);
      tracker.trackUpdate();
    }
    return () => {
      console.debug('MATOMO currentPlayer REMOVE', player);
      removeListeners();
    };
  }, [title, internalPlayer, mediaAnalytics]);

  const removeListeners = () => {
    if (internalPlayer) {
      internalPlayer.removeEventListener('play', onPlay);
      internalPlayer.removeEventListener('pause', onPause);
      internalPlayer.removeEventListener('ended', onEnded);
      internalPlayer.removeEventListener('seeking', onSeeking);
      internalPlayer.removeEventListener('seeked', onSeeked);
      internalPlayer.removeEventListener('timeupdate', onTimeUpdate);
    }
  };

  const onPlay = () => {
    tracker?.play();
    console.debug('MA - play');
  };
  const onPause = () => {
    tracker?.pause();
    console.debug('MA - pause');
  };
  const onEnded = () => {
    tracker?.finish();
    if (tracker) console.debug('MA - finish');
  };
  const onSeeked = () => {
    tracker?.setMediaProgressInSeconds(player?.getCurrentTime());
    tracker?.setMediaTotalLengthInSeconds(player?.getDuration());
    tracker?.seekFinish();
    console.debug('MA - seekFinish', player?.getCurrentTime(), player?.getDuration());
  };
  const onSeeking = () => {
    tracker?.seekStart();
    console.debug('MA - seekStart');
  };

  const onTimeUpdate = () => {
    tracker?.setMediaProgressInSeconds(player?.getCurrentTime());
    tracker?.setMediaTotalLengthInSeconds(player?.getDuration());
    tracker?.update();
    //console.debug('MA - timeUpdate', player?.getCurrentTime(), player?.getDuration());
  };

  const registerMedia = () => {};
  const removeMedia = () => {};

  return {
    registerMedia,
    removeMedia,
  };
};

export interface useMediaAnalyticsReturn {
  registerMedia(props: useMediaAnalyticsProps): void;
  removeMedia(): void;
}
interface useMediaAnalyticsProps {
  player: ReactPlayer | null;
  title?: string;
  src: string;
  type?: string;
}
