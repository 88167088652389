import { AgendaDateHeader } from '../agenda-date-header';
import { AgendaEvent, AgendaEventProps } from '../agenda-event';
import { agendaTransformer } from '../util/agenda-transformer';
import { fallBackImage } from './event-speaker';
import { ProgramCategory, ProgramEvent } from '@cue/api';
import { Avatar } from '@cue/atoms';
import { TagSelection } from '@cue/molecules';
import { LocationCategory } from '@project/cms/modules/agenda';
import { useEvents } from '@project/hooks/use-events';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SpeakerProps {
  // TODO: Fix Type
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  speaker: AgendaEventProps['persons'][0];
  /* eslint-enable @typescript-eslint/ban-ts-comment */
  locationCategories?: LocationCategory[];
  hideLocation?: boolean;
}

export const SpeakerAgenda: React.FC<SpeakerProps> = ({
  speaker,
  locationCategories,
  hideLocation,
}) => {
  const { t } = useTranslation();
  const { events } = useEvents();
  const [selectedCategories /*, setSelectedCategories*/] = React.useState<string[]>([]);

  const speakerEvents = React.useMemo(() => {
    if (!events) return;

    const filters = [];
    if (selectedCategories.length) {
      filters.push((events: ProgramEvent[]) => {
        return events?.filter((event) => {
          return event?.categories?.some((category) => selectedCategories.includes(category.slug));
        });
      });
    }

    return agendaTransformer(
      events?.filter((ev) => {
        return Boolean(ev.persons?.find((p) => p.id === speaker.id));
      }),
      filters
    );
  }, [speaker, events, selectedCategories]);

  const [activeDateIndex, setActiveDateIndex] = React.useState(0);

  const speakerTopics = React.useMemo(() => {
    const topics: Record<string, ProgramCategory> = {};
    speakerEvents?.events.map((group) => {
      group.forEach((event) => {
        event.categories?.forEach((cat) => {
          if (cat.id in topics) return;
          topics[cat.id] = cat;
        });
      });
    });
    return Object.values(topics);
  }, [speakerEvents]);

  if (!speaker) return null;

  return (
    <div className="speaker-agenda">
      <div className="speaker-agenda-speaker-wrapper">
        <Avatar speaker={{ ...speaker, image: speaker.profileImageURL || fallBackImage }} />
      </div>
      {speakerEvents ? (
        <>
          <div className="speaker-agenda-filters">
            <TagSelection
              selected={[]}
              // key={id}
              label={String(t('agenda.expertTopics'))}
              tags={speakerTopics.map(({ slug, title }) => ({ label: title, value: slug }))}
              // onChange={(tag, selected) => setSelectedCategories(selected)}
              overrideCSS={{
                '.cue-tag-selection-tag:hover': {
                  cursor: 'initial',
                },
              }}
            />
          </div>
          <div className="speaker-header-wrapper">
            <AgendaDateHeader
              dates={speakerEvents.dates}
              activeIndex={activeDateIndex}
              onDateChange={setActiveDateIndex}
            />
          </div>
          <div className="speaker-agenda-events">
            {speakerEvents.events[activeDateIndex]?.map((event) => (
              <AgendaEvent
                key={event.id}
                locationCategories={locationCategories}
                hideLocation={hideLocation}
                {...event}
              />
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};
