import { CMS_Content_Module_VideoOnDemand } from './types';
import { Video } from '@cue/molecules';
import React from 'react';

type VideoOnDemandProps = CMS_Content_Module_VideoOnDemand['data'];

export const VideoOnDemandPlayer: React.FC<VideoOnDemandProps> = (props) => {
  const { video_url: videoURL, tracking_title } = props;
  return (
    <>
      <Video
        url={getVideo(videoURL)}
        startTime={getVideoStartTime(videoURL)}
        trackingTitle={tracking_title}
        playing={false}
      />
    </>
  );
};

function getVideo(link: string) {
  return link?.split('?')?.[0];
}

function getVideoStartTime(link: string) {
  const searchParams = link ? new URLSearchParams(link.substring(link.indexOf('?'))) : undefined;
  const videoTime = searchParams?.get('t') || 0;
  return +videoTime;
}
