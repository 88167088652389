import type { SVGProps } from 'react';

const SvgUndo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5146 7.002H7.9996V3L2.9996 8L7.9996 13V9.002H14.5146C16.9946 9.002 19.0136 11.021 19.0136 13.501C19.0136 15.981 16.9946 18 14.5146 18H11.0046V20H14.5146C18.0986 20 21.0136 17.085 21.0136 13.501C21.0136 9.917 18.0986 7.002 14.5146 7.002Z"
    />
  </svg>
);
export default SvgUndo;
