import { EventTeaserContentModule, EventTeaserContentModuleProps } from './event-teaser';
import { Button, Heading } from '@cue/atoms';
import { Survey, SurveyProps } from '@project/cms/modules/survey';
import { navigate } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const SurveyWithEventTeaser: React.FC<CMS_Survey_With_EventTeaser_Data> = (props) => {
  const { survey, event_teaser } = props;
  const { t, i18n } = useTranslation();

  const agendaLink = `/${i18n.language}/agenda`;
  return (
    <div>
      <Heading type="h2">{t('agenda.ourSuggestions')}</Heading>
      <div style={{ margin: '40px 0' }}>
        <Survey {...(survey as SurveyProps)} />
      </div>

      <div style={{ margin: '40px 0' }}>
        <EventTeaserContentModule {...(event_teaser as EventTeaserContentModuleProps)} />
      </div>
      <div>
        <Button styling="primary" onClick={() => navigate(agendaLink)}>
          {t('agenda.allSuggestions')}
        </Button>
      </div>
    </div>
  );
};
