import { CMS_Content_Module_Text } from './types';
import { Mdx } from '@cue/utility';
import React from 'react';

type TextContentModuleProps = CMS_Content_Module_Text['data'];

export const TextContentModule: React.FC<TextContentModuleProps> = (props) => {
  const { mdx, css_class } = props;
  return <Mdx css_class={css_class}>{mdx}</Mdx>;
};
