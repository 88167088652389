import { eventConfig } from '../../../config';
import { ProgramEvent } from '@cue/api';
import { Button } from '@cue/atoms';
import { Tooltip } from '@cue/atoms';
import { useIcs } from '@cue/hooks';
import { TimeUtil } from '@cue/utility';
import { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import React from 'react';

const TIMECELLHEIGHT_PX = 50;
const SLOT_SIZE_IN_MIN = 5;
const GRID_SIZE_IN_MIN = 15;
const DEFAULT_START_HOUR_UTC = 9;
const DEFAULT_END_HOUR_UTC = 12;
const dayjs = TimeUtil.getDayjs();
dayjs.extend(isSameOrBefore);

export const Schedule: React.FC<ScheduleProps> = (props) => {
  const {
    title,
    events,
    timezone = eventConfig.timezone,
    day,
    slotSizeInMinutes = SLOT_SIZE_IN_MIN,
    gridSizeInMinutes = GRID_SIZE_IN_MIN,
    timeCellHeightInPx = TIMECELLHEIGHT_PX,
  } = props;

  // Schedule Range
  const [startHour, setStartHour] = React.useState(DEFAULT_START_HOUR_UTC);
  const [endHour, setEndHour] = React.useState(DEFAULT_END_HOUR_UTC);

  const eventsOnDay: QuantizedProgramEvent[] = React.useMemo(() => {
    return dayjs(day).isValid()
      ? events
          ?.filter((e) => dayjs(e.start).isSame(dayjs(day), 'day'))
          .map((e) => {
            const startMinutes =
              Math.round(
                TimeUtil.convertToTimeZone(dayjs(e.start), timezone).minute() / slotSizeInMinutes
              ) * slotSizeInMinutes;
            const endMinutes =
              Math.ceil(
                TimeUtil.convertToTimeZone(dayjs(e.end), timezone).minute() / slotSizeInMinutes
              ) * slotSizeInMinutes;

            return {
              ...e,
              startQuantized: dayjs(e.start).minute(startMinutes),
              endQuantized: dayjs(e.end).minute(endMinutes),
            };
          }) || []
      : [];
  }, [events, day, slotSizeInMinutes, timezone]);

  // Position Utilites
  const timeToYPosition = React.useCallback(
    (eventStartDate: Dayjs) => {
      const startTime = TimeUtil.convertToTimeZone(dayjs(day), timezone).hour(startHour).minute(0);
      const minutesFromGridStart = TimeUtil.convertToTimeZone(dayjs(eventStartDate), timezone).diff(
        startTime,
        'minutes'
      );
      const heightPerMinute = timeCellHeightInPx / gridSizeInMinutes;

      return Math.round(minutesFromGridStart * heightPerMinute);
    },
    [day, startHour, gridSizeInMinutes, timeCellHeightInPx, timezone]
  );

  const timeRangeToHeight = React.useCallback(
    ([eventStartDate, eventEndDate]: DateRange) => {
      const heightPerMinute = timeCellHeightInPx / gridSizeInMinutes;
      const eventLengthInMin = TimeUtil.convertToTimeZone(dayjs(eventEndDate), timezone).diff(
        eventStartDate,
        'minutes'
      );

      return Math.round(eventLengthInMin * heightPerMinute);
    },
    [gridSizeInMinutes, timeCellHeightInPx, timezone]
  );

  // Set Date Range from Events
  React.useEffect(() => {
    if (eventsOnDay.length === 0) {
      setStartHour(DEFAULT_START_HOUR_UTC);
      setEndHour(DEFAULT_END_HOUR_UTC);
      return;
    }
    const firstEventStart = eventsOnDay?.[0].start;
    const lastEventEnd = eventsOnDay?.[eventsOnDay?.length - 1].end;

    setStartHour(
      parseInt(TimeUtil.convertToTimeZone(dayjs(firstEventStart), timezone).format('H'))
    );
    setEndHour(parseInt(TimeUtil.convertToTimeZone(dayjs(lastEventEnd), timezone).format('H')));
  }, [eventsOnDay, timezone]);

  // Date Preparation with display Info
  const eventsWithDisplayInfo = React.useMemo(() => {
    const otpt: ScheduleEvent[] = [];

    for (let i = 0; i < eventsOnDay.length; i++) {
      const currentEvent = eventsOnDay[i];
      const collisions = otpt.filter((e) => eventsOverlap(e, currentEvent)).reverse();

      let left = collisions.length > 0 ? 100 / (collisions.length + 1) : 0;
      let skip = false;

      [...otpt].reverse().forEach((c) => {
        const cIndex = otpt.findIndex((otptEvent) => otptEvent.id === c.id);
        if (cIndex === -1 || skip) {
          return;
        }

        if (otpt[cIndex].left === 0) {
          if (!eventsOverlap(otpt[cIndex], currentEvent)) {
            left = 0;
          }
          skip = true;
        }

        if (eventsOverlap(otpt[cIndex], currentEvent)) {
          const w = 100 / (collisions.length + 1);
          if (w < otpt[cIndex].width) {
            otpt[cIndex].width = 100 / (collisions.length + 1);
          }
          if (left !== 0) {
            left = 100 / (collisions.length + 1);
          }
        }
      });

      const e = {
        ...currentEvent,
        top: timeToYPosition(TimeUtil.convertToTimeZone(dayjs(currentEvent.start), timezone)),
        height: timeRangeToHeight([
          TimeUtil.convertToTimeZone(dayjs(currentEvent.start), timezone),
          TimeUtil.convertToTimeZone(dayjs(currentEvent.end), timezone),
        ]),
        collisions: collisions,
        left: left,
        width: 100 / (collisions.length + 1),
      };

      otpt.push(e);
    }

    return otpt;
  }, [eventsOnDay, timeRangeToHeight, timeToYPosition, timezone]);

  const { download } = useIcs();

  return (
    <div className="schedule">
      <div className="schedule-header">
        <h3 className="schedule-title">{title ? title : null}</h3>
        <div className="schedule-top-buttons">
          <Button
            icon="download"
            styling="iconOnly"
            onClick={() =>
              download(
                eventsWithDisplayInfo.map((e) => ({
                  subject: e.title,
                  start: TimeUtil.getUtc(e.start),
                  end: TimeUtil.getUtc(e.end),
                  description: e.description || '',
                  alarm: 30,
                }))
              )
            }></Button>
        </div>
      </div>
      <div className="schedule-day-wrapper">
        <DayGrid {...props} startHour={startHour} endHour={endHour}>
          {eventsWithDisplayInfo.map((event, i) => {
            return <ScheduleEvent event={event} key={event.id} num={i} timezone={timezone} />;
          })}
        </DayGrid>
      </div>
    </div>
  );
};

const ScheduleEvent: React.FC<{ event: ScheduleEvent; num?: number; timezone: string }> = ({
  event,
  timezone = eventConfig.timezone,
}) => {
  const textRef = React.useRef<HTMLDivElement>(null);
  const textIsNotCompletelyVisible = textRef.current ? isEllipsisActive(textRef.current) : false;

  if (textIsNotCompletelyVisible) {
    return (
      <div
        key={event.id}
        className="schedule-day-event"
        style={{
          top: event.top,
          height: event.height,
          width: event.width + '%',
          left: event.left + '%',
        }}>
        <div className="schedule-day-event-title" data-tooltip-id={event.id} ref={textRef}>
          {event.title}
          <Tooltip place="top-start" variant="light" id={event.id}>
            <strong>
              {TimeUtil.convertToTimeZone(dayjs(event.start), timezone).format('HH:mm')} -{' '}
              {TimeUtil.convertToTimeZone(dayjs(event.end), timezone).format('HH:mm')}:
            </strong>{' '}
            {event.title}
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <div
      id={event.id}
      key={event.id}
      className="schedule-day-event"
      style={{
        top: event.top,
        height: event.height,
        width: event.width + '%',
        left: event.left + '%',
      }}>
      <div className="schedule-day-event-title" ref={textRef}>
        {event.title}
      </div>
    </div>
  );
};

type DateRange = [Dayjs, Dayjs];

function eventsOverlap(a: ProgramEvent, b: ProgramEvent) {
  return dateRangeOverlaps([dayjs(a.start), dayjs(a.end)], [dayjs(b.start), dayjs(b.end)]);
}

function dateRangeOverlaps(a: DateRange, b: DateRange) {
  if (a[0].isSameOrBefore(b[0]) && b[0].isBefore(a[1])) return true; // b starts in a
  if (a[0].isBefore(b[1]) && b[1].isSameOrBefore(a[1])) return true; // b ends in a
  if (b[0].isBefore(a[0]) && a[1].isBefore(b[1])) return true; // a in b
  if (b[0].isSame(a[0]) && a[1].isSame(b[1])) return true; // a in b
  return false;
}

type DayGridProps = {
  day?: string;
  startHour?: number;
  endHour?: number;
  gridSizeInMinutes?: number;
  timeCellHeightInPx?: number;
  children?: React.ReactNode;
  timezone?: string;
};

const DayGrid: React.FC<DayGridProps> = ({
  day = dayjs(),
  startHour = DEFAULT_START_HOUR_UTC,
  endHour = DEFAULT_END_HOUR_UTC,
  gridSizeInMinutes = GRID_SIZE_IN_MIN,
  timeCellHeightInPx = TIMECELLHEIGHT_PX,
  timezone = eventConfig.timezone,
  children,
}) => {
  const hoursToDisplay = TimeUtil.convertToTimeZone(dayjs(day), timezone)
    .hour(endHour)
    .diff(TimeUtil.convertToTimeZone(dayjs(day), timezone).hour(startHour), 'hours');

  const gridRows = Math.max(Math.ceil((hoursToDisplay * 60) / gridSizeInMinutes) + 2, 12);
  const startTime = TimeUtil.convertToTimeZone(dayjs(day), timezone).hour(startHour).minute(0);

  return (
    <div className="schedule-day-grid">
      {[...Array(gridRows).keys()].map((row, i) => {
        const time = startTime.add(i * gridSizeInMinutes, 'minutes');
        const fullHourIndicator = time.minute() === 0;
        const hourDisplay = time.format('HH:mm');

        return (
          <div
            className="schedule-day-grid-row"
            style={{ height: timeCellHeightInPx }}
            key={`grid-row-${i}`}>
            <div key={`time-indicator-${i}`} className={`schedule-time-indicator`}>
              {fullHourIndicator && <span>{hourDisplay}</span>}
            </div>
            <div
              className={`schedule-time-indicator-line ${fullHourIndicator ? 'full' : 'half'}`}
            />
          </div>
        );
      })}
      <div className="schedule-day-events">{children}</div>
    </div>
  );
};

function isEllipsisActive(e: HTMLElement) {
  return e.offsetWidth < e.scrollWidth;
}
