import { DisplayAgendaDate } from '../util/agenda-date';
import { AgendaData } from '../util/agenda-transformer';
import { Button } from '@cue/atoms';
import { StyledComponent } from '@cue/theme';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { useResponsive } from '@project/hooks/use-responsive';
import { Dayjs } from 'dayjs';
import debounce from 'lodash.debounce';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';

/* type DateOption = { value: number; label: string };
 */
export const AgendaDateHeader: React.FC<
  AgendaDateHeaderProps & StyledComponent<AgendaDateHeaderCSSOverride>
> = ({ dates, activeIndex = 0, onDateChange }) => {
  const { windowWidth, isMobile } = useResponsive();
  const tabsContainerRef = React.useRef<HTMLDivElement>(null);
  const {
    i18n: { language },
  } = useTranslation();

  const [, setShowFullDateString] = React.useState(true);
  const [needsScrolling, setNeedsScrolling] = React.useState(false);
  const [, setIsSelectOpen] = React.useState(false);
  const selectTriggerRef = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(selectTriggerRef, () => setIsSelectOpen(false));

  const swipeHandlers = useSwipeable({
    onSwiping: (e) => {
      const { deltaX, event } = e;
      event.preventDefault();

      const scrollContainer = swipeContainerRef.current;
      if (!scrollContainer) return;
      const offsetWidth = scrollContainer.offsetWidth;
      const scrollWidth = scrollContainer.scrollWidth;

      if (scrollWidth > offsetWidth) {
        scrollContainer.scrollLeft += -deltaX;
      }
    },
  });
  const swipeContainerRef = React.useRef<HTMLDivElement>();
  const refPassthrough = (el: HTMLDivElement) => {
    swipeHandlers.ref(el);
    swipeContainerRef.current = el;
  };

  function contentFits() {
    if (!tabsContainerRef.current) return;

    const scrollContainer = tabsContainerRef.current?.querySelector(
      '.cue-agenda-date-header-tab-btns'
    ) as HTMLDivElement;

    if (!scrollContainer) return;
    const offsetWidth = scrollContainer.offsetWidth;
    const scrollWidth = scrollContainer.scrollWidth;
    if (scrollWidth > offsetWidth) {
      return false;
    }
    return true;
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  const checkIfScrollingNeeded = React.useCallback(
    debounce(() => {
      setNeedsScrolling(!contentFits());
    }, 200),
    [setNeedsScrolling]
  );

  const checkIfFullDateStringFits = React.useCallback(
    debounce(() => {
      setShowFullDateString(contentFits() || false);
    }, 100),
    [setShowFullDateString]
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  React.useEffect(() => {
    if (!tabsContainerRef.current) return;

    checkIfFullDateStringFits();
    checkIfScrollingNeeded();
  }, [windowWidth, dates, checkIfScrollingNeeded, checkIfFullDateStringFits]);

  React.useEffect(() => {
    if (!tabsContainerRef.current) return;

    const scrollContainer = tabsContainerRef.current?.querySelector(
      '.cue-agenda-date-header-tab-btns'
    ) as HTMLDivElement;
    if (!scrollContainer) return;

    const wheelScrollTabs = (e: WheelEvent) => {
      e.preventDefault();
      scrollContainer.scrollLeft += e.deltaY + e.deltaX;
    };

    scrollContainer.addEventListener('wheel', wheelScrollTabs);

    return () => {
      scrollContainer.addEventListener('wheel', wheelScrollTabs);
    };
  }, [needsScrolling]);

  /* const dateOptions = dates.map((date, index) => ({
    value: index,
    label: date.format(`dddd, ${language === 'de' ? 'Do MMMM' : 'MMMM Do'}`),
  })); */

  return (
    <Container className="cue-agenda-date-header-wrapper" ref={tabsContainerRef}>
      {/* {isMobile ? (
        <Select
          value={dateOptions[activeIndex]}
          options={dateOptions}
          onChange={(option) => {
            const { value } = option as DateOption;
            setIsSelectOpen(false);
            onDateChange && onDateChange(value);
          }}
        />
      ) : ( */}
      <>
        <div className="cue-agenda-date-header-tab-btns" {...swipeHandlers} ref={refPassthrough}>
          {dates.map((date, index) => (
            <Button
              styling="agendaHeaderDay"
              key={index}
              className={`cue-agenda-date-header-button ${activeIndex === index ? 'active' : ''}`}
              onClick={() => {
                onDateChange && onDateChange(index, date);
              }}>
              {DisplayAgendaDate({ date, language, short: isMobile, showWeekday: true })}
              {/*  {showFullDateString ? date.format(`LL`) : date.format(`LL`)} */}
            </Button>
          ))}
        </div>
        {/* {needsScrolling ? (
          <div className="cue-agenda-date-header-select" ref={selectTriggerRef}>
            <Button onClick={() => setIsSelectOpen(!isSelectOpen)}>...</Button>

            <Select
              value={dateOptions[activeIndex]}
              menuIsOpen={isSelectOpen}
              options={dateOptions}
              onChange={(option) => {
                const { value } = option as DateOption;
                setIsSelectOpen(false);
                onDateChange && onDateChange(value);
              }}
              styles={{
                control: () => ({
                  display: 'none',
                }),
                menu: (baseStyle) => ({ ...baseStyle, right: 0, width: 350 }),
              }}
            />
          </div>
        ) : null} */}
      </>
      {/* )} */}
    </Container>
  );
};

const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (e: MouseEvent | TouchEvent) => void
) => {
  React.useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || (ref.current && ref.current.contains(event.target as Element))) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export type AgendaDateHeaderCSSOverride = {
  '.cue-agenda-date-header-wrapper'?: CSSObject;
  '.cue-agenda-date-header-button'?: CSSObject;
};
export interface AgendaDateHeaderProps {
  dates: AgendaData['dates'];
  activeIndex?: number;
  onDateChange: (index: number, date?: Dayjs) => void;
}
const Container = styled.div``;
