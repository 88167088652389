import { ChatMessage, ChatMessageProps } from './chatMessage';
import styled from '@emotion/styled';
import React from 'react';

type DivProps = JSX.IntrinsicElements['div'];

const DEFAULT_VALUE_SCROLL_TO_BOTTOM = true;

export const ChatMessages: React.FC<ChatMessagesProps> = ({
  scrollToBottom = DEFAULT_VALUE_SCROLL_TO_BOTTOM,
  history,
  pendingMessages,
  user,
  ...rest
}) => {
  const refContainerDiv = React.useRef<HTMLDivElement>();
  // this is needed due to timing issues if you'd just use the useEffect
  // so the callback ref is given into the div, the scrollTop gets set, if scrollToBottom is truthy...
  const refContainerCallback = React.useCallback((div: HTMLDivElement | null) => {
    if (!div) {
      return;
    }

    // ... and the ref is passed on to the MutableRefObject which is used later on in the useEffect
    refContainerDiv.current = div;

    if (!scrollToBottom) {
      return;
    }

    div.scrollTop = div.scrollHeight;
  }, []);

  /*   React.useEffect(() => {
    if (!scrollToBottom || !refContainerDiv.current) {
      return;
    }

    refContainerDiv.current.scrollTop = refContainerDiv.current.scrollHeight;
  }, [chatMessages.length, pendingMessages.length, scrollToBottom]); */
  return (
    <Container {...rest} ref={refContainerCallback}>
      <ChatMessagesContainer>
        {/* render already accepted messages first */}
        {history?.map((p, i) => (
          <ChatMessage user={user} key={i} {...p}>
            {/*   {JSON.stringify(p)} */}
          </ChatMessage>
        ))}

        {/* render pending messages after the already accepted ones */}
        {pendingMessages?.map((p, i) => (
          <ChatMessage user={user} key={i} {...p} pending></ChatMessage>
        ))}
      </ChatMessagesContainer>
    </Container>
  );
};

export interface ChatMessagesProps extends DivProps {
  chatMessages: ChatMessageProps[];
  pendingMessages?: ChatMessageProps[];
  // if set to true, the container will scroll to the bottom automatically for every
  // change in chatMessages or pendingMessages
  scrollToBottom?: boolean;
}

const Container = styled.div``;

const ChatMessagesContainer = styled.div``;
