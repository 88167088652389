import { ProgramEvent } from '@cue/api';
import { Dayjs, TimeUtil } from '@cue/utility';

export interface AgendaData {
  dates: Dayjs[];
  events: ProgramEvent[][];
}
export interface ProgramLinks {
  type: string;
  href: string;
  title: string | null;
}

const sortEventsByDate = (events: ProgramEvent[], additionalCategorySortingProp?: string) => {
  return events.sort((a, b) => {
    if (additionalCategorySortingProp && a.start === b.start) {
      return (
        a.categories?.find((a) => a.slug.startsWith(additionalCategorySortingProp))?.slug || ''
      ).localeCompare(
        b.categories?.find((b) => b.slug.startsWith(additionalCategorySortingProp))?.slug || ''
      );
    }

    return TimeUtil.getUtc(a.start).isAfter(b.start) ? 1 : -1;
  });
};

export const agendaTransformer = (
  inputEvents: ProgramEvent[],
  filterFunction?: { (events: ProgramEvent[]): ProgramEvent[] }[],
  additionalCategorySortingProp?: string
): AgendaData => {
  const toDateString = (date: string) => TimeUtil.getUtc(date).format('YYYY-MM-DD');

  let events = inputEvents;

  filterFunction?.forEach((filterFnc) => {
    events = filterFnc(events);
  });

  // Collect unique dates
  let dates =
    [...new Set(events?.map((event) => toDateString(event.start)))].map((d) =>
      TimeUtil.getUtc(d)
    ) || [];

  // Sort dates by date
  dates = dates.sort((a, b) => (a.isAfter(b) ? 1 : -1));

  const sortedEvents: ProgramEvent[][] = dates.map(() => []);

  dates.forEach((date, index) => {
    sortedEvents[index].push(
      ...sortEventsByDate(
        events.filter((event) => toDateString(event.start) === toDateString(date.toString())),
        additionalCategorySortingProp
      )
    );
  });

  return {
    dates,
    events: sortedEvents,
  };
};

export const getDocumentDownload = (links: Array<ProgramLinks>) => {
  const { href } = links.find((link) => link.type === 'document') || {};
  return href?.split('?')?.[0];
};
export const getDocumentDownloads = (links: Array<ProgramLinks>) => {
  return links.filter((link) => link.type === 'document');
};

export const getVideo = (links: Array<ProgramLinks>) => {
  const { href } = links.find((link) => link.type === 'video') || {};
  return href?.split('?')?.[0];
};

export const getVideoStartTime = (links: Array<ProgramLinks>) => {
  const { href } = links.find((link) => link.type === 'video') || {};

  const searchParams = href ? new URLSearchParams(href.substring(href.indexOf('?'))) : undefined;
  const videoTime = searchParams?.get('t') || 0;

  return +videoTime;
};
