import {
  ArrowDoubleRight,
  ArrowRight,
  ArrowheadLeft,
  ArrowheadRight,
  Back,
  Bookmark,
  BookmarkMarked,
  Buildings,
  Calendar,
  Capacity,
  Chat1,
  Check,
  CheckCheckbox,
  Clock,
  Close,
  CloseSmall,
  ConnectedRadioWaves,
  Download,
  Dropdown,
  Edit,
  Email,
  Facebook,
  Filter2,
  FullScreen,
  FullScreenExit,
  Home,
  Information,
  Instagram,
  Language,
  LinkExtern,
  LinkedIn,
  Live,
  Login,
  Menu,
  Minus,
  Monitor,
  Pause,
  Play,
  Plus,
  Search,
  Star,
  StarFilled,
  Twitter,
  Undo,
  User,
  Virtual,
  VolumeLoud,
  VolumeMute,
  Warning,
  Youtube,
} from './icons/dist';
import SvgInformation from './icons/dist/Information';
import _colors from './scss/colors/colors';
import type { Styles as ColorsStyles } from './scss/colors/colors.module.scss';
import _mq, { mqTypes } from './scss/mq/mq';
import type { Styles as MqStyles } from './scss/mq/mq.module.scss';
import { defaultTheme } from '@cue/theme';
import { Theme } from '@emotion/react';

// TODO: Replace this. This is to make the imports from the scss modules work with external webpack builds,
// so we can share the theme with all settings for the AEM builds.
// I cannot make the imports work like all other imports for some reason and it is probably a setting in webpack config.
const mediaQueries = (_mq as unknown as { default: MqStyles })?.default || _mq;
const colors = (_colors as unknown as { default: ColorsStyles })?.default || _colors;

export const mq = <T extends keyof mqTypes>(size: T): string => {
  return mediaQueries[size] ? `@media (min-width: ${mediaQueries[size]}px)` : '';
};
// TODO
const templateMq = {};
for (const key in mediaQueries) {
  const value = mediaQueries[key as keyof mqTypes];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (typeof value === 'string') templateMq[key] = mq(key);
}
const spacer = 0.25;
const SPACING = [
  0, // 0
  `${spacer * 0.25}rem`, // 1
  `${spacer * 0.5}rem`, // 2
  `${spacer}rem`, // 3
  `${spacer * 1.5}rem`, // 4
  `${spacer * 3}rem`, // 5
];

const GRID_COLUMNS = 36;

// Override default Theme
export const siemensTheme: Theme = {
  ...defaultTheme,
  colorScheme: 'dark',
  mq: templateMq,
  atoms: {
    button: {
      searchBarClear: {
        icon: Close,
      },

      'videoStory-DefaultButton': {
        icon: ArrowheadRight,
        iconAlign: 'right',
      },
      'videoStory-EnterButton': {
        extend: ['videoStory-DefaultButton'],
        icon: ArrowheadRight,
      },
      'videoStory-PlayButton': {
        extend: ['videoStory-DefaultButton'],
        icon: Play,
      },
      'videoStory-InfoButton': {
        extend: ['videoStory-DefaultButton'],
        icon: Information,
      },
      'videoStory-StoryApp': {
        extend: ['videoStory-DefaultButton'],
        icon: Capacity,
      },
      'videoStory-IconButton': {
        extend: ['videoStory-DefaultButton'],
      },
      stepsNavigation: {
        icon: ArrowheadLeft,
      },
    },

    icons: {
      default: ArrowheadRight,
      clock: Clock,
      close: Close,
      tagSelected: CloseSmall,
      check: CheckCheckbox,
      arrow: ArrowheadRight,
      arrowRight: ArrowheadRight,
      arrowRightSecondary: ArrowRight,
      arrowLeft: ArrowheadLeft,
      buildings: Buildings,
      download: Download,
      home: Home,
      user: User,
      back: Back,
      filter: Filter2,
      videostoryClose: ArrowDoubleRight,
      indicatorActive: Check,
      language: Language,
      play: Play,
      plus: Plus,
      minus: Minus,
      pause: Pause,
      soundOn: VolumeLoud,
      soundOff: VolumeMute,
      fullscreenOn: FullScreenExit,
      fullscreenOff: FullScreen,
      ics: Calendar,
      mailto: Email,
      favourite: Bookmark,
      favouriteMarked: BookmarkMarked,
      search: Search,
      virtual: Virtual,
      twitter: Twitter,
      linkedin: LinkedIn,
      facebook: Facebook,
      youtube: Youtube,
      instagram: Instagram,
      star: Star,
      starFilled: StarFilled,
      login: Login,
      menu: Menu,
      radio: ConnectedRadioWaves,
      chat1: Chat1,
      monitor: Monitor,
      edit: Edit,
      live: Live,
      dropdown: Dropdown,
      warning: Warning,
      undo: Undo,
      link_extern: LinkExtern,
      information: SvgInformation,
    },
  },
  colors: {
    ...defaultTheme.colors,
    bodyBackground: colors.DEEP_BLUE,
    notifications: {
      background: colors.DEEP_BLUE_80,
      default: colors.DEEP_BLUE_30,
      info: colors.PETROL,
      error: colors.FEEDBACK_RED,
      warning: colors.FEEDBACK_ORANGE,
      success: colors.FEEDBACK_GREEN,
    },
  },
  fonts: {
    ...defaultTheme.fonts,
    fontSize: '18px',
    fontFamily: 'Siemens Sans Roman',
    fontFamilyBlack: 'Siemens Sans Black',
    headingSizes: {
      ...defaultTheme.fonts.headingSizes,
      h1: '60px',
      h2: '40px',
      h3: '36px',
      h4: '32px',
      h5: '24px',
      h6: '18px',
    },
    headingSizesMobile: {
      ...defaultTheme.fonts.headingSizes,
      h1: '40px',
      h2: '32px',
      h3: '30px',
      h4: '26px',
      h5: '24px',
      h6: '16px',
    },
    text: {
      sizes: {
        14: '14px',
        16: '16px',
        17: '17px',
        18: '18px',
        20: '20px',
        24: '24px',
        26: '26px',
        30: '30px',
      },
      lineHeight: '26px',
    },
  },
  margin: {
    t: {
      '0': { marginTop: SPACING[0] },
      '1': { marginTop: SPACING[1] },
      '2': { marginTop: SPACING[2] },
      '3': { marginTop: SPACING[3] },
      '4': { marginTop: SPACING[4] },
      '5': { marginTop: SPACING[5] },
    },
    r: {
      '0': { marginRight: SPACING[0] },
      '1': { marginRight: SPACING[1] },
      '2': { marginRight: SPACING[2] },
      '3': { marginRight: SPACING[3] },
      '4': { marginRight: SPACING[4] },
      '5': { marginRight: SPACING[5] },
    },
    b: {
      '0': { marginBottom: SPACING[0] },
      '1': { marginBottom: SPACING[1] },
      '2': { marginBottom: SPACING[2] },
      '3': { marginBottom: SPACING[3] },
      '4': { marginBottom: SPACING[4] },
      '5': { marginBottom: SPACING[5] },
    },
    l: {
      '0': { marginLeft: SPACING[0] },
      '1': { marginLeft: SPACING[1] },
      '2': { marginLeft: SPACING[2] },
      '3': { marginLeft: SPACING[3] },
      '4': { marginLeft: SPACING[4] },
      '5': { marginLeft: SPACING[5] },
    },
  },
  dimensions: {
    maxWidth: '1440px',
    maxContentWidth: '1024px',
  },
  grid: {
    columns: GRID_COLUMNS,
  },
};
