import { EventTeaserContentModule, EventTeaserContentModuleProps } from '../modules/event-teaser';
import styled from '@emotion/styled';
import {
  ACSModule,
  ACSModuleProps,
  AccordionContentModule,
  AccordionContentModuleProps,
  AgendaContentModule,
  AgendaContentModuleProps,
  AnchorContentModule,
  CMS_Content_Module_AnchorData,
  CMS_Content_Module_HeadlineSeparatorData,
  CMS_Content_Module_IFrameData,
  CMS_Content_Module_TextData,
  CMS_Content_Module_Text_ImageData,
  CMS_Content_Module_VideoOnDemandData,
  CMS_Content_Modules_Enum,
  CardDeck,
  CardDeckProps,
  EventDisplayContentModule,
  EventDisplayContentModuleProps,
  FullscreenSlogan,
  FullscreenSloganProps,
  HeadlineSeparator,
  IframeContentModule,
  LivestreamContentModule,
  LivestreamContentModuleProps,
  LivestreamTeaser,
  LivestreamTeaserProps,
  Survey,
  SurveyProps,
  TextContentModule,
  TextImageContentModule,
  VideoOnDemandPlayer,
  VirtualExperience,
  VirtualExperienceProps,
} from '@project/cms/modules';
import { useFlag } from '@project/providers/flags-provider';
import { PersonalSchedule, PersonalScheduleProps } from '@project/site/modules/schedule';
import {
  AdditionalCMSContentBlock,
  CMS_Content_Modules_Data_Additional,
  CMS_Content_Modules_Enum_Additional,
} from '@project/specific/cms/additional-modules';

export type CMS_Content_Modules_Enum_Extended =
  | CMS_Content_Modules_Enum
  | CMS_Content_Modules_Enum_Additional;

export const CmsModuleNotFound: React.FC<{ type: string }> = ({ type }) => {
  if (process.env.NODE_ENV === 'production') return null;

  return (
    <h2
      style={{
        background: 'red',
        display: 'inline-block',
        padding: '5px 10px',
      }}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any, react/no-unescaped-entities */}
      What is a "{type}"? Is it one of those internets?
    </h2>
  );
};

export const ContentBlock: React.FC<ContentBlockProps> = (props) => {
  // TODO CMS_Content_Module should be Generic
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const { data, type, options, isRegistered, wrapper = true } = props;

  const flagState = useFlag(options?.flag);
  if (options?.user_status === 'registered' && !isRegistered) return <></>;
  if (options?.user_status === 'not_registered' && isRegistered) return <></>;
  if (flagState === false) return null;

  const exludeWrapper: string[] = [
    CMS_Content_Modules_Enum.Anchor,
    CMS_Content_Modules_Enum.FullscreenSlogan,
  ];

  const moduleType = String(type);
  let content = null;
  /*  if ((Object.values(CMS_Content_Modules_Enum) as string[]).includes(moduleType)) {
    switch (
      moduleType as CMS_Content_Modules_Enum
     
    ) {
    }
  }
 */
  if ((Object.values(CMS_Content_Modules_Enum) as string[]).includes(moduleType)) {
    switch (moduleType as CMS_Content_Modules_Enum) {
      case CMS_Content_Modules_Enum.Anchor:
        content = <AnchorContentModule {...(data as CMS_Content_Module_AnchorData)} />;
        break;
      case CMS_Content_Modules_Enum.IFrame:
        content = <IframeContentModule {...(data as CMS_Content_Module_IFrameData)} />;
        break;
      case CMS_Content_Modules_Enum.Text:
        content = <TextContentModule {...(data as CMS_Content_Module_TextData)} />;
        break;
      case CMS_Content_Modules_Enum.TextImage:
        content = <TextImageContentModule {...(data as CMS_Content_Module_Text_ImageData)} />;
        break;
      case CMS_Content_Modules_Enum.VideoOnDemand:
        content = <VideoOnDemandPlayer {...(data as CMS_Content_Module_VideoOnDemandData)} />;
        break;
      case CMS_Content_Modules_Enum.HeadlineSeperator:
        content = <HeadlineSeparator {...(data as CMS_Content_Module_HeadlineSeparatorData)} />;
        break;
      case CMS_Content_Modules_Enum.Agenda:
        content = <AgendaContentModule {...(data as AgendaContentModuleProps)} />;
        break;
      case CMS_Content_Modules_Enum.Livestream:
        content = <LivestreamContentModule {...(data as LivestreamContentModuleProps)} />;
        break;
      case CMS_Content_Modules_Enum.EventDisplay:
        content = <EventDisplayContentModule {...(data as EventDisplayContentModuleProps)} />;
        break;
      case CMS_Content_Modules_Enum.VirtualExperience:
        content = <VirtualExperience {...(data as VirtualExperienceProps)} />;
        break;
      case CMS_Content_Modules_Enum.FullscreenSlogan:
        content = <FullscreenSlogan {...(data as FullscreenSloganProps)} />;
        break;
      case CMS_Content_Modules_Enum.CardDeck:
        content = <CardDeck {...(data as CardDeckProps)} />;
        break;
      case CMS_Content_Modules_Enum.LivestreamTeaser:
        content = <LivestreamTeaser {...(data as LivestreamTeaserProps)} />;
        break;
      case CMS_Content_Modules_Enum.Accordion:
        content = <AccordionContentModule {...(data as AccordionContentModuleProps)} />;
        break;
      case CMS_Content_Modules_Enum.Survey:
        content = <Survey {...(data as SurveyProps)} />;
        break;
      case CMS_Content_Modules_Enum.EventTeaser:
        content = <EventTeaserContentModule {...(data as EventTeaserContentModuleProps)} />;
        break;

      case CMS_Content_Modules_Enum.PersonalSchedule:
        content = <PersonalSchedule {...(data as PersonalScheduleProps)} />;
        break;
      case CMS_Content_Modules_Enum.ACS:
        content = <ACSModule {...(data as ACSModuleProps)} />;
        break;
    }
  }

  if ((Object.values(CMS_Content_Modules_Enum_Additional) as string[]).includes(moduleType)) {
    content = (
      <AdditionalCMSContentBlock
        type={moduleType as CMS_Content_Modules_Enum_Additional}
        data={data as CMS_Content_Modules_Data_Additional}
      />
    );
  }

  if (!content) content = <></>; //<CmsModuleNotFound type={moduleType} />;

  // FullSize ContentModule

  return wrapper && !exludeWrapper.includes(moduleType) ? (
    <ContentBlockWrapper className={`cue-project-content-block-wrapper ${moduleType}`}>
      {content}
    </ContentBlockWrapper>
  ) : (
    content
  );
};

const ContentBlockWrapper = styled.div`
;
`;
// TODO CMS_Content_Module should be Generic
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export interface ContentBlockProps extends CMS_Content_Module {
  wrapper?: boolean;
  isRegistered?: boolean;
}
