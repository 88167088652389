import { DisplayAgendaDate } from '../util/agenda-date';
import { flagMap } from './flags';
import { ProgramEvent } from '@cue/api';
import { StyledComponent } from '@cue/theme';
import { AvailableLanguages, Dayjs, TimeUtil, getAbbr } from '@cue/utility';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { useConfig } from '@project/hooks/use-config';
import { useUser } from '@project/hooks/use-user';
import { availableLanguages } from '@project/i18n/config';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const AgendaEventTime: React.FC<
  AgendaEventTimeProps & StyledComponent<AgendaEventTimeCSSOverride>
> = (props) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { start, end, showTimeZone, customTimeRenderer, eventLanguage } = props;
  const eventUTCStart = TimeUtil.getUtc(start);
  const eventUTCEnd = TimeUtil.getUtc(end);

  const { preferredTimezone } = useUser();
  const { eventConfig } = useConfig();

  const displayTimezone = preferredTimezone || eventConfig.timezone;

  const eventTime = useMemo(() => {
    if (!start || !end) return;
    if (customTimeRenderer) {
      return customTimeRenderer({ start: eventUTCEnd, end: eventUTCEnd });
    }

    return (
      <>
        <div>
          {DisplayAgendaDate({ date: TimeUtil.convertToTimeZone(eventUTCStart, displayTimezone) })}
        </div>
        <div>{' | '}</div>
        <div>
          {TimeUtil.convertToTimeZone(eventUTCStart, displayTimezone).format('HH:mm')}
          {eventUTCStart &&
            ` - ${TimeUtil.convertToTimeZone(eventUTCEnd, displayTimezone).format('HH:mm')}`}
        </div>
      </>
    );
  }, [eventUTCStart, eventUTCEnd, displayTimezone, customTimeRenderer, end, start]);

  return (
    <Container className="cue-agenda-event-time-wrapper">
      <Time className="cue-agenda-event-time-time">{eventTime}</Time>
      {showTimeZone && start && (
        <TimeZone className="cue-agenda-event-time-timezone">
          {getAbbr(
            displayTimezone,
            language as AvailableLanguages,
            TimeUtil.getUtc(eventUTCStart.format('YYYY-MM-DD'))
          )}
        </TimeZone>
      )}
      {availableLanguages.length > 1 && (
        <div className="cue-agenda-event-flag">
          {flagMap?.[(eventLanguage || 'en') as keyof typeof flagMap]}
        </div>
      )}
    </Container>
  );
};
export type AgendaEventTimeCSSOverride = {
  '.cue-agenda-event-time-wrapper'?: CSSObject;
  '.cue-agenda-event-time-time'?: CSSObject;
  '.cue-agenda-event-time-timezone'?: CSSObject;
};
export interface AgendaEventTimeProps extends Pick<ProgramEvent, 'start' | 'end'> {
  showTimeZone?: boolean;
  customTimeRenderer?({ start, end }: { start: Dayjs; end: Dayjs }): React.ReactNode;
  eventLanguage?: string;
}

const Container = styled.div``;
const Time = styled.div``;
const TimeZone = styled.div``;
