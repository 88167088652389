import { Slider, SliderProps } from '@cue/organisms';

export const GridSlider = ({ children }: SliderProps) => {
  return (
    <Slider
      className="cue-grid-slider"
      spaceBetween={10}
      breakpoints={{
        768: {
          slidesPerView: 2,
        },
        1100: {
          slidesPerView: 3,
        },
      }}>
      {children}
    </Slider>
  );
};
