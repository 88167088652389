import { CMS_Content_Module_Agenda } from './types';
import { Agenda } from '@project/site/modules/agenda';
import { navigate, useLocation } from '@reach/router';

export const AgendaContentModule: React.FC<AgendaContentModuleProps> = (props) => {
  const location = useLocation();
  return <Agenda {...props} location={location} navigate={navigate} />;
};

export type AgendaContentModuleProps = CMS_Content_Module_Agenda['data']['agenda'];
