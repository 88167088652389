import { ProgramEvent } from '@cue/api';
import { StyledComponent } from '@cue/theme';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { LocationCategory } from '@project/cms/modules';
import { useMemo } from 'react';

export const AgendaEventCategories: React.FC<
  AgendaEventCategoriesProps & StyledComponent<AgendaEventCategoriesCSSOverride>
> = (props) => {
  const { categories, locationCategories } = props;
  const eventCategoriesRow = useMemo(() => {
    const locationCategoryIds = locationCategories?.map((locCat) => locCat.category.id);

    const eventCategoriesWithoutLocationCategories = categories?.filter((evCat) =>
      locationCategoryIds ? !locationCategoryIds?.includes(evCat.id) : true
    );

    return eventCategoriesWithoutLocationCategories
      ? eventCategoriesWithoutLocationCategories.map((c, i) => <div key={i}>{c.title}</div>)
      : null;
  }, [categories, locationCategories]);
  return (
    <Container className="cue-agenda-event-categories-wrapper">{eventCategoriesRow}</Container>
  );
};
export type AgendaEventCategoriesCSSOverride = {
  '.cue-agenda-event-categories-wrapper'?: CSSObject;
};
export interface AgendaEventCategoriesProps extends Pick<ProgramEvent, 'categories'> {
  locationCategories?: LocationCategory[];
}

const Container = styled.div``;
