import { Heading } from '@cue/atoms';
import { mustache } from '@cue/utility';
import styled from '@emotion/styled';
import { useUser } from '@project/hooks/use-user';
import { useTranslation } from 'react-i18next';

export const WelcomeText: React.FC = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  return (
    <Container className="cue-project-welcome-text">
      {user && (
        <Heading type="h4" className="light roman top-hd">
          {mustache(t('content.welcome.user'), user as unknown as Record<string, unknown>)},
        </Heading>
      )}
      <Heading type="h4" className="bottom-hd">
        {t('content.welcome.subline')}
      </Heading>
    </Container>
  );
};
const Container = styled.div`
  text-align: center;
  .top-hd {
    margin-bottom: 0;
  }
  .bottom-hd {
    margin-top: 0;
  }
`;
