import { CMS_Content_Module_HeadlineSeparator } from './types';
import { Heading, Paragraph } from '@cue/atoms';
import { StyledComponent } from '@cue/theme';
import {
  CSSObject,
  /*, useTheme */
} from '@emotion/react';
import styled from '@emotion/styled';

/* import { useMemo } from 'react';
 */
type HeadlineSeparatorProps = CMS_Content_Module_HeadlineSeparator['data'];

export const HeadlineSeparator: React.FC<
  HeadlineSeparatorProps & StyledComponent<HeadlineSeparatorCSSOverride>
> = (props) => {
  const { pre_headline, main_headline, subline, /* styling, */ overrideCSS } = props;

  /*  const theme = useTheme();
  const _themeStyle = useMemo(() => {
    return generateCss({ type: 'cms.headlineSeparator', styling, theme }) || [];
  }, [styling, theme]); */

  return (
    <StyleWrapper css={[/* _themeStyle, */ overrideCSS]} className="cue-headline-seperator-wrapper">
      {pre_headline ? (
        <Paragraph className="cue-headline-seperator-pre">{pre_headline}</Paragraph>
      ) : null}
      {main_headline ? (
        <Heading className="cue-headline-seperator-main">{main_headline}</Heading>
      ) : null}
      {subline ? <Paragraph className="cue-headline-seperator-subline">{subline}</Paragraph> : null}
    </StyleWrapper>
  );
};

export type HeadlineSeparatorCSSOverride = {
  '.cue-headline-seperator-wrapper'?: CSSObject;
  '.cue-headline-seperator-pre'?: CSSObject;
  '.cue-headline-seperator-main'?: CSSObject;
  '.cue-headline-seperator-subline'?: CSSObject;
};
const StyleWrapper = styled.div``;
