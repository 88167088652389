import { apiConfig } from '../../../config';
import { UserStreamNotification } from '../user-stream-notification';
import { EmojiReactions } from './emotion-reactions';
import { LivestreamStatusOverlay } from './livestream-status-overlay';
import { Image } from '@cue/atoms';
import { useFullscreen, useHash } from '@cue/hooks';
import { Tabs, TabsItemData, Video } from '@cue/molecules';
import styled, { CSSObject } from '@emotion/styled';
import { DialogContact, EventDisplayContentModule } from '@project/cms/modules';
import { Chat, ChatUser } from '@project/cms/modules/chat';
import { CMS_Content_Module_LiveStreamData } from '@project/cms/modules/livestream';
import {
  LivestreamTeaserElement,
  LivestreamTeaserElementDisplay,
} from '@project/cms/modules/livestream-teaser/livestream-teaser-element';

/* import {
  LivestreamTeaserElement,
  LivestreamTeaserElementDisplay,
} from '@project/cms/modules/livestream-teaser/livestream-teaser-element'; */
import { LocalUserData } from '@project/cue-api/auth';
import { ProgramEvent } from '@project/cue-api/program';
import { useChat, useChatReturn } from '@project/hooks/use-chat';
import { useEventDataProvider } from '@project/hooks/use-event-data';
import {
  LivestreamStatusDefaultState,
  LivestreamStatusType,
  useLivestreamStatus,
} from '@project/hooks/use-livestream-status';
import { useMediaAnalytics } from '@project/hooks/use-media-analytics';
import { useUser } from '@project/hooks/use-user';
import { livestreams_modules } from '@project/local/livestream.json';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const alias = livestreams_modules.map((lm) => ({
  id: lm.id,
  channel: lm.livestream_channel.categories[0].categories_id.slug,
}));

export const Livestream: React.FC<LivestreamProps> = (props) => {
  const {
    onSelect,
    room,
    reactions,
    chat: sidebarChat,
    contact_form,
    adhoc_appointment,
    no_stream_fallback,
    no_stream_before,
    no_stream_after,
    show_countdown,
    offset_start,
    offset_end,
    no_session_video_timed,
    no_session_video_timed_start,
    no_session_video_timed_end,
    otherStreams,
    card_image,
    livestream_channel: data,
    session_display,
  } = props;
  const videoWrapper = useRef<HTMLDivElement>(null);

  const { statusByCategories } = useLivestreamStatus() || LivestreamStatusDefaultState;
  const { nextEvents, currentEvent, inter } = useEventDataProvider();
  const [muted, setMuted] = useState<boolean>(true);
  const [chatToken, setChatToken] = useState<string | null>();
  const [sidebarItems, setSidebarItems] = useState<TabsItemData[]>([]);
  const [activeSidebarTab, setActiveSidebarTab] = useState(2);
  const [sidebarLoading, setSidebarLoading] = useState(true);
  const { getChatToken, user } = useUser();
  const { updateHash, hash } = useHash();
  const { isFullscreen, exit: exitFullscreen } = useFullscreen();
  const { firstName = '', lastName = '' } = (user as LocalUserData) || {
    firstName: '',
    lastName: '',
  };
  const chatUser: ChatUser = {
    firstName,
    lastName,
    chatToken: '',
  };

  const categories = data?.category_slugs || [];

  const { t } = useTranslation();

  const chat: useChatReturn = useChat({ url: apiConfig.chat });

  const { connected: chatConnected, sendReaction, recentEmoji, connect } = chat;

  const status = useMemo(
    () =>
      statusByCategories(categories, {
        offset_start,
        offset_end,
        no_session_video_timed,
        no_session_video_timed_start,
        no_session_video_timed_end,
      }),
    [
      statusByCategories,
      data,
      offset_start,
      offset_end,
      no_session_video_timed,
      no_session_video_timed_start,
      no_session_video_timed_end,
      chatConnected,
    ]
  );
  const nextEventDate: ProgramEvent | undefined = useMemo(() => {
    return data
      ? nextEvents({
          slugs: 'agenda', // TODO: SLUG
          categories: data?.category_slugs || [],
          only_today: false,
        })?.[0]
      : undefined;
  }, [data, status]);

  const currentEventId: string | null = status?.event || null;
  const tabItems = useMemo(() => {
    const basicTabs: TabsItemData[] = [];

    if (adhoc_appointment) {
      basicTabs.push({
        title: t('livestream.tab.adhoc_appointment'),
        item: <>TODOD: ADHOC</>,
      });
    }

    if (sidebarChat) {
      basicTabs.push({
        title: t('livestream.tab.chat'),
        item: (
          <Chat roomId={room} chat={chat} username={`${chatUser.firstName} ${chatUser.lastName}`} />
        ),
      });
    }
    if (contact_form) {
      basicTabs.push({ title: t('livestream.tab.contact_form'), item: <DialogContact /> });
    }
    if (otherStreams && otherStreams?.length > 1) {
      basicTabs.push({
        title: t('livestream.tab.more_streams'),
        item: otherStreams
          ?.filter((stream) => stream.livestream_channel.id !== data?.id)
          .map((livestream) => (
            <>
              <LivestreamTeaserElement
                display={LivestreamTeaserElementDisplay.COLUMN}
                small
                id={livestream.livestream_channel.id}
                livestream={livestream}
                onSelectStream={(stream) => {
                  updateHash(stream.id);
                  document.location.reload();
                }}
              />
            </>
          )),
      });
    }

    return basicTabs;
  }, [chat, room, hash, status?.status, chatConnected]);

  useEffect(() => {
    setSidebarItems([]);
    setTimeout(() => {
      setActiveSidebarTab(0); // Fix Ellipsis renderbug
      setSidebarLoading(false);
    }, 500);
  }, []);

  const hasSidebar = useMemo(() => tabItems.length, [tabItems]);

  useEffect(() => {
    if (!chatToken && (reactions || chat) && room) {
      getChatToken(room).then((token) => setChatToken(token));
    }
  }, [room, chatToken]);

  useEffect(() => {
    // Connect if recieved token
    connect({ user: chatUser, token: chatToken || '', room });
  }, [chatToken, hash]);

  useEffect(() => {
    if (isFullscreen && status?.status !== LivestreamStatusType.ACTIVE) {
      exitFullscreen();
    }
  }, [status?.status]);

  const video_src =
    (status?.status !== LivestreamStatusType.ACTIVE && status?.status !== LivestreamStatusType.IDLE
      ? props?.no_session_video
      : data?.source) || undefined;

  return (
    <>
      <UserStreamNotification
        excludeChannels={data?.category_slugs || []}
        channels={['channel-1', 'channel-2', 'channel-3']}
      />
      <Container className={`cue-livestream ${!hasSidebar ? 'cue-livestream-no-sidebar' : ''}`}>
        <div
          className={`cue-livestream-player ${
            isFullscreen ? 'cue-livestream-player-fullscreen' : ''
          }`}
          ref={videoWrapper}>
          {status?.status !== LivestreamStatusType.ACTIVE && (
            <LivestreamStatusOverlay
              status={status?.status || LivestreamStatusType.DEFAULT}
              data={{ no_stream_fallback, no_stream_before, no_stream_after }}
              showCountdown={show_countdown}
              nextEvent={nextEventDate?.start}
            />
          )}

          {[
            LivestreamStatusType.ACTIVE,
            LivestreamStatusType.NO_SESSION,
            LivestreamStatusType.ISSUE,
            LivestreamStatusType.BEFORE_EVENT,
            LivestreamStatusType.AFTER_EVENT,
            LivestreamStatusType.IDLE,
          ].includes(status?.status || LivestreamStatusType.DEFAULT) && (
            <>
              {video_src ? (
                <Video
                  playerName="Live"
                  useMediaAnalytics={useMediaAnalytics}
                  showUnmuteButton={muted}
                  onUnmuteButtonClick={() => setMuted(false)}
                  muted={muted}
                  autoPlay
                  isLive
                  url={video_src}
                  trackingTitle={JSON.stringify({
                    event: status?.event || currentEventId,
                    channel: data?.category_slugs?.[0],
                  })}
                  playsinline={true}
                  customWrapper={videoWrapper}
                />
              ) : (
                <Image
                  src={card_image?.src}
                  className={`cue-livestream-teaser-content-module-image `}
                />
              )}
            </>
          )}
        </div>
        {reactions && (
          <div className="cue-livestream-emotions">
            <EmojiReactions
              recentEmoji={recentEmoji}
              loading={
                !chatConnected ||
                ![LivestreamStatusType.ACTIVE, LivestreamStatusType.IDLE].includes(
                  status?.status || LivestreamStatusType.DEFAULT
                )
              }
              sendReaction={sendReaction}
              currentSessionId={currentEventId || null}
              additionalProps={{ channel: data?.category_slugs?.[0] }}
            />
          </div>
        )}
        <div
          className={`cue-livestream-sidebar ${
            sidebarLoading ? 'cue-livestream-sidebar-loading' : ''
          }`}>
          {tabItems.length === 1 ? (
            tabItems?.[0].item
          ) : (
            <Tabs active={activeSidebarTab} items={tabItems} />
          )}
        </div>
      </Container>
      {session_display?.map((sd) => (
        <EventDisplayContentModule key={sd.id} {...sd} />
      ))}
    </>
  );
};
export type LivestreamContenCSSOverride = {
  '.cue-livestream-wrapper'?: CSSObject;
  '.cue-livestream-player'?: CSSObject;
};
export interface LivestreamProps extends Partial<CMS_Content_Module_LiveStreamData> {
  id: string;
  onSelect?(id: string): void;
  otherStreams?: CMS_Content_Module_LiveStreamData[];
}
const Container = styled.div``;
