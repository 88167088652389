import { AgendaDisplayOptions } from './agenda';
import { AgendaEvent } from './agenda-event';
import { DisplayAgendaDate } from './util/agenda-date';
import { AgendaData } from './util/agenda-transformer';
import { Global, css } from '@emotion/react';
import { IAgenda } from '@project/cms/modules';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PrintAgendaProps = {
  eventData: AgendaData;
  activeTabIndex?: number;
  agendaDisplayOptions: AgendaDisplayOptions;
  agendaSettings?: Partial<IAgenda>;
  showAll: boolean;
};
export const PrintAgenda: React.FC<PrintAgendaProps> = ({
  eventData,
  activeTabIndex,
  agendaDisplayOptions,
  agendaSettings,
  showAll = true,
}) => {
  const { i18n } = useTranslation();

  const daysToShow =
    showAll || activeTabIndex === undefined ? eventData.dates : [eventData.dates[activeTabIndex]];
  const eventsToShow =
    showAll || activeTabIndex === undefined ? eventData.events : [eventData.events[activeTabIndex]];

  return (
    <div className="agenda print">
      <div>
        {daysToShow.map((day, dayIndex) => {
          const events = eventsToShow[dayIndex];

          return (
            <React.Fragment key={dayIndex}>
              {events?.length > 0 && (
                <>
                  <h2 className="agenda-print-day">
                    {DisplayAgendaDate({
                      date: day,
                      language: i18n.language,
                      short: false,
                      showWeekday: true,
                    })}
                  </h2>
                  <div className="agenda-event-list">
                    {events?.map((event) => (
                      <AgendaEvent
                        agendaDisplayOptions={{ ...agendaDisplayOptions, readmoreOpen: true }}
                        key={`print-${event.id}`}
                        locationCategories={agendaSettings?.location_categories}
                        hideLocation={agendaSettings?.hide_location || false}
                        isVodVisibleFlag={agendaSettings?.vod_visibility_condition?.slug}
                        {...event}
                      />
                    ))}
                  </div>
                </>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <Global
        styles={css`
          @media screen {
            .agenda.print {
              display: none;
            }
          }

          @media print {
            body {
              background: #fff !important;
              color: #333 !important;
            }

            .cue-project-menu,
            .cue-button,
            .content_module_headline_separator,
            .cue-agenda-filter-header-wrapper,
            .cue-agenda-header-wrapper,
            .cue-agenda-event-cta-wrapper,
            .cue-agenda-events-wrapper,
            .cue-project-footer,
            .schedule,
            .cue-project-content-container-element:not(.agenda) {
              display: none !important;
            }
            .cue-agenda-event-wrapper {
              background: #fff !important;
              color: #333 !important;
              margin: 0 !important;
            }

            .agenda-event-list > div {
              page-break-inside: avoid;
              &:nth-of-type(odd) {
                .cue-agenda-event-wrapper {
                  // background: #eee !important;
                }
              }

              &:nth-of-type(4) {
              }
            }
            * {
              line-height: 1.4;
            }
            .cue-project-header {
              position: relative !important;
              height: auto !important;
              background: transparent !important;
              &-inner {
                padding: 0 !important;
              }
            }

            .cue-agenda-event-wrapper {
              border-bottom: 1px solid #ccc;
              margin-bottom: 0;
              padding-left: 0;
              padding-right: 0;
              .cue-agenda-event-top-wrapper {
                grid-area: 1/1/1/4;
                margin-bottom: 0 !important;

                .cue-agenda-event-time-time,
                .cue-agenda-event-time-timezone {
                  font-size: 10px;
                  color: gray;
                }
              }

              .cue-agenda-event-content {
                grid-area: 2/1/2/4 !important;
                .cue-agenda-event-content-title-wrapper {
                  margin: 5px 0 !important;
                  .cue-agenda-event-title {
                    margin-bottom: 0 !important;
                  }
                }
              }
            }

            .cue-agenda-event-bottom-wrapper {
              grid-area: 3/1/3/4 !important;
            }

            .cue-project-content-container {
              margin-top: 0px !important;
            }

            .cue-project-brand:before {
              background: #333 !important;
            }

            .cue-agenda-event-categories-wrapper {
              margin-top: 10px;
              color: #fff;
              > div {
                font-size: 10px !important;
              }
            }
            .cue-agenda-event-categories-wrapper(:empty) {
              display: none;
            }

            .agenda {
              &.print {
                display: block;
                .cue-agenda-event-title {
                  font-size: 1rem !important;
                  font-size: 1rem !important;
                  line-height: 20px !important;
                  // margin-bottom: 20px !important;
                }

                .agenda-print-day:not(:first-of-type) {
                  margin-top: 80px;
                }
                .agenda-print-day {
                  font-size: 26px !important;
                }

                .cue-agenda-event-flag {
                  width: 10px;
                }
              }
            }
            .cue-agenda-event-description {
              line-height: 1;
              font-size: px !important;
              display: none !important;
            }

            .cue-agenda-event-speaker-list {
              width: 100%;
              ul {
                display: inline-flex;
                flex-direction: row !important;
                flex-wrap: wrap;
                gap: 0 !important;
                margin: 0 !important;

                li {
                  display: flex;
                  padding-right: 5px;
                  &:not(:last-of-type):after {
                    content: ',';
                    font-size: 10px !important;
                  }
                }
              }

              .cue-avatar-wrapper {
                .cue-image-element {
                  display: none;
                }
                .cue-avatar-meta {
                  display: inline-flex;
                  flex-direction: row;
                  width: auto;

                  .cue-avatar-name,
                  .cue-avatar-occupation {
                    font-size: 10px !important;
                  }

                  .cue-avatar-name {
                    margin-right: 5px !important;
                  }

                  .cue-avatar-occupation:before {
                    content: '(';
                  }
                  .cue-avatar-occupation:after {
                    content: ')';
                  }
                }
              }
            }

            .cue-project-content-block-wrapper {
              padding: 0;
              margin-top: 0 !important;
            }
          }
        `}
      />
    </div>
  );
};
