import { Dayjs } from '@cue/utility';

interface DisplayAgendaDateProps {
  date: Dayjs;
  language?: string;
  short?: boolean;
  showWeekday?: boolean;
}

export const DisplayAgendaDate = ({
  date,
  language = 'en',
  short,
  showWeekday = false,
}: DisplayAgendaDateProps): string => {
  if (short) {
    return date.format(
      language === 'de'
        ? `${showWeekday ? 'dd, ' : ''}D. MMM`
        : `${showWeekday ? 'dd, ' : ''}Do MMM`
    );
  }
  return date.format(
    language === 'de'
      ? `${showWeekday ? 'dddd, ' : ''}D. MMMM`
      : `${showWeekday ? 'dddd, ' : ''}Do MMMM`
  );
};
