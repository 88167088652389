import { TimeUtil } from '@cue/utility';
import React from 'react';
import { useTranslation } from 'react-i18next';

type LanguageChangeWrapper = {
  children?: React.ReactNode;
  pageLanguage: string;
};

async function getDayjsLocale(langKey: string) {
  try {
    const locale = await import(`dayjs/locale/${langKey}`);
    return locale.default;
  } catch (e) {
    return undefined;
  }
}

export const LanguageChangeWrapper: React.FC<LanguageChangeWrapper> = ({
  children,
  pageLanguage,
}) => {
  const { i18n } = useTranslation();

  React.useEffect(() => {
    pageLanguage && i18n.changeLanguage(pageLanguage);
    getDayjsLocale(pageLanguage).then((locale) => {
      if (locale) {
        TimeUtil.setLocale(locale);
      }
    });
  }, [i18n, pageLanguage]);

  return <>{children}</>;
};
