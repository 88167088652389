export enum CMS_Content_Modules_Enum {
  Anchor = 'content_module_anchor',
  IFrame = 'content_module_iframe',
  Text = 'content_module_text',
  TextImage = 'content_module_image_with_text',
  HeadlineSeparator = 'content_module_headline_separator',
  LiveStream = 'content_module_live_stream',

  Accordion = 'content_module_accordion',
  HeadlineSeperator = 'content_module_headline_separator',
  Agenda = 'agenda',
  Livestream = 'content_module_live_stream',
  LivestreamTeaser = 'content_module_livestream_teaser',
  EventDisplay = 'content_module_event_display',
  VirtualExperience = 'content_module_virtual_experience',
  FullscreenSlogan = 'content_module_fullscreen_slogan',
  CardDeck = 'content_module_card_deck',
  Custom = 'content_module_custom',
  Survey = 'content_module_survey',
  EventTeaser = 'content_module_event_teaser',
  PersonalSchedule = 'content_module_personal_schedule',
  ACS = 'content_module_acs',
  VideoOnDemand = 'content_module_video_on_demand',
}
export type Image = {
  src: string;
  srcSet?: string;
  id?: string;
  type?: string;
  title?: string;
  width?: number;
  height?: number;
  filesize?: string;
};
