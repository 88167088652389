import { CMS_Content_Module_IFrame } from './types';
import { ExternalContent } from '@cue/molecules';
import React from 'react';

type IframeContentModuleProps = CMS_Content_Module_IFrame['data'];

export const IframeContentModule: React.FC<IframeContentModuleProps> = (props) => {
  const { url, ratio } = props;
  if (!url) {
    console.warn('IFrame: No url for iframe');
    return null;
  }

  return <ExternalContent uri={url} ratio={ratio} />;
};
