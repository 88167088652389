import { CMS_Content_Modules_Enum, Image } from '../types';

export type CMSIcon = {
  id: string;
  component: string;
};

export type CMSImage = {
  src: string;
  srcSet?: string;
  id?: string;
  type?: string;
  title?: string;
  width?: number;
  height?: number;
  filesize?: string;
};

export interface CMS_Content_Module_Survey {
  type: CMS_Content_Modules_Enum.Survey;
  data: CMS_Content_Module_SurveyData;
}

export interface CMS_Content_Module_SurveyData {
  id: string;
  auto_save: boolean;
  wrapper_css_class?: string;
  questions: SurveyQuestions[];
}

export interface SurveyItemProps {
  options: SurveyQuestion[];
  selected?: string[];
  onChange: (tag: { value: string }) => void;
}

export interface SurveyQuestion {
  id: string;
  status: string;
  value: string;
  title: string;
  sub_title?: string | null;
  image: Image | null;
  icon?: {
    name: string;
    component: string;
  };
}

export enum SurveyQuestionType {
  TAGS = 'select--tags',
  IMAGE_CARD = 'select--image-card',
  ICON_CARD = 'select--icon-card',
  TEXT_CARD = 'select--text-card',
}

export interface SurveyQuestions {
  id: string;
  title: string;
  type: SurveyQuestionType;
  allow_multiple_selections: boolean;
  property_field: string;
  question: string;
  options: SurveyQuestion[];
}
