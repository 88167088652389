import { SurveyItemProps, SurveyQuestion } from './types';
import React, { useEffect, useState } from 'react';

export const TextCards: React.FC<SurveyItemProps> = (props) => {
  const { options, onChange, selected } = props;

  return (
    <div className="cue-survey-cards-wrapper">
      {options.map((o, i) => (
        <TextCard
          options={o}
          key={i}
          selected={selected !== undefined && selected?.includes(o.value)}
          onChange={() => {
            onChange({
              value: o.value,
            });
          }}
        />
      ))}
    </div>
  );
};

const TextCard: React.FC<{ options: SurveyQuestion; selected: boolean; onChange: () => void }> = ({
  options,
  onChange,
  selected,
}) => {
  const [active, setActive] = useState(selected);

  useEffect(() => {
    setActive(selected);
  }, [selected]);
  return (
    <div
      className={`cue-survey-text-card${active ? ' is-selected' : ''}`}
      onClick={() => onChange()}>
      <div>{options.title}</div>
      {options.sub_title ? <div>{options.sub_title}</div> : null}
    </div>
  );
};
