import { ContentModuleFragment } from '@project/cms/fragments';

export const CMS_Content_Module_Text_Fragment: ContentModuleFragment[] = [
  {
    type: 'content_module_text',
    available_on_page_content: true,
    fragment: `{
                id
                translations {
                  mdx
                  languages_code {
                    code
                  }
                }
                css_class
              }`,
  },
];
