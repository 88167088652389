import { IconCards } from './icon-card';
import { ImageCards } from './image-cards';
import { getNestedProperty, parseJSON } from './survey-helpers';
import { TextCards } from './text-card';
import { CMS_Content_Module_SurveyData, SurveyQuestion, SurveyQuestionType } from './types';
import { Button, LoadingSpinner } from '@cue/atoms';
import { TagSelection } from '@cue/molecules';
import { StyledComponent } from '@cue/theme';
import { Mdx, mustache } from '@cue/utility';
import styled, { CSSObject } from '@emotion/styled';
import { LocalUserData } from '@project/cue-api/auth';
import { useUser } from '@project/hooks/use-user';
import { cloneDeep } from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ILocalUser {
  user: LocalUserData;
}

export const Survey = forwardRef<
  HTMLDivElement,
  SurveyProps & StyledComponent<SurveyCSSOverride> & React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const { questions, className, onChange, hideWhenSelected, auto_save, ...rest } = props;
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedUser, setSelectedUser] = React.useState<ILocalUser>();
  const { t } = useTranslation();
  const { updateFields, user, loading } = useUser();

  useEffect(() => {
    if (user) {
      const clonedUser = cloneDeep(user);
      for (const key in clonedUser.fields) {
        clonedUser.fields[key] = parseJSON(clonedUser.fields[key]);
      }
      setSelectedUser({ user: clonedUser });
      setShowSuggestions(true);
    } else {
      setSelectedUser(undefined);
    }
  }, [user]);

  const updateSelection = (tag: { value: string }, property_field: string) => {
    const clonedUser = cloneDeep(selectedUser);
    const { value: selectedValue } = tag;
    if (clonedUser) {
      const result = getNestedProperty(clonedUser, property_field);

      if (result) {
        const { value, prop } = result;
        if (typeof value === 'object') {
          value[prop] =
            Array.isArray(value[prop]) && value[prop]?.includes(selectedValue)
              ? value[prop].filter((tagSlug: string) => tagSlug !== selectedValue)
              : value[prop]
              ? [...value[prop], selectedValue]
              : [selectedValue];
        }
      }

      setSelectedUser(clonedUser);

      if (auto_save) {
        saveSurvey(clonedUser);
      }
    }
  };

  const saveSurvey = async (updatedUser: ILocalUser) => {
    const clonedUser = cloneDeep(updatedUser);
    if (clonedUser) {
      for (const key in clonedUser.user.fields) {
        clonedUser.user.fields[key] = JSON.stringify(clonedUser.user.fields[key]);
      }
      setSaving(true);
      await updateFields(clonedUser.user.fields);
      setSaving(false);
      onChange && onChange();
    }
  };

  const renderOptions = ({
    type,
    options,
    property_field,
  }: {
    type: SurveyQuestionType;
    options: SurveyQuestion[];
    property_field: string;
  }) => {
    const result =
      selectedUser !== undefined ? getNestedProperty(selectedUser, property_field) : undefined;
    const seletedValue = result ? result.value[result.prop] : undefined;

    switch (type) {
      case SurveyQuestionType.TAGS:
        return (
          <TagSelection
            selected={seletedValue}
            onChange={(tag) => updateSelection(tag, property_field)}
            tags={options.map(({ value, title }) => ({
              label: title,
              value,
            }))}
          />
        );
      case SurveyQuestionType.IMAGE_CARD:
        return (
          <ImageCards
            options={options}
            selected={seletedValue}
            onChange={(tag) => updateSelection(tag, property_field)}
          />
        );
      case SurveyQuestionType.TEXT_CARD:
        return (
          <TextCards
            options={options}
            selected={seletedValue}
            onChange={(tag) => updateSelection(tag, property_field)}
          />
        );

      case SurveyQuestionType.ICON_CARD:
        return (
          <IconCards
            options={options}
            selected={seletedValue}
            onChange={(tag) => updateSelection(tag, property_field)}
          />
        );
    }
  };

  if (loading) return <LoadingSpinner />;

  if (!user || (showSuggestions && hideWhenSelected)) return false;

  return (
    <Container ref={ref} className={`cue-survey ${className}`} {...rest}>
      {questions.map(({ id, question, options, type, property_field }) => (
        <Question key={id} className="cue-survey-question">
          <div className="cue-survey-question-headline">
            <Mdx>{mustache(question, user as unknown as Record<string, unknown>) || ''}</Mdx>
          </div>
          <div className="cue-survey-options">
            {renderOptions({
              type,
              options,
              property_field: property_field,
            })}
          </div>
        </Question>
      ))}

      {auto_save === false && (
        <div className="cue-survey-save">
          <Button
            onClick={() => {
              if (selectedUser) {
                saveSurvey(selectedUser);
              }
            }}
            styling="primary"
            disabled={saving}>
            {t('user-interest.start-journey')}
          </Button>
        </div>
      )}
    </Container>
  );
});
Survey.displayName = 'Survey';

export type SurveyCSSOverride = {
  '.cue-survey'?: CSSObject;
  '.cue-survey-question'?: CSSObject;
  '.cue-survey-save'?: CSSObject;
};

export interface SurveyProps extends CMS_Content_Module_SurveyData {
  onChange?(): void;
  hideWhenSelected?: boolean;
}

const Container = styled.div``;
const Question = styled.div``;
