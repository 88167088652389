import { AgendaDisplayOptions, NavigateFunction, WindowLocation } from '../agenda';
import { AgendaSearchRef } from '../agenda-search';
import { AgendaFilterDropdown } from './agenda-filter-dropdown/agenda-filter-dropdown';
import { ProgramEvent } from '@cue/api';
import { Heading } from '@cue/atoms';
import { useQueryParams } from '@cue/hooks';
import { TagSelectionTagProps } from '@cue/molecules';
import { StyledComponent } from '@cue/theme';
import { AnalyticsTransformer } from '@cue/utility';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { Category_Tree_Element, IAgenda } from '@project/cms/modules';
import { useCategories } from '@project/hooks/use-categories';
// import { navigate, useLocation } from '@reach/router';
import React, { ReactNode, RefObject, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const FILTER_PARAM_KEY = 'tag-filter';

export interface AgendaFilterHandle {
  reset(): void;
}

export const AgendaFilterHeader = React.forwardRef<
  AgendaFilterHandle,
  AgendaFilterHeaderProps & StyledComponent<AgendaFilterHeaderCSSOverride>
>((props, ref) => {
  const {
    agendaSettings,
    preSelect = [],
    onChange,
    children,
    agendaDisplayOptions,
    events,
    onSearched,
    agendaSearchRef,
    onToggleMySchedule,
    myScheduleOpened,
    scheduleEventsCount,
    onToggleSuggestions,
    suggestionsActive = false,
    navigate,
    location,
    onApply,
  } = props;
  const { t } = useTranslation();
  const { categories } = useCategories();
  const [filterParams, setFilterParams] = useQueryParams({ navigate, location });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [localStateParams, setFilterLocalState] = React.useState<Record<string, any> | undefined>({
    'tag-filter': null,
  });
  const [selected, setSelected] = React.useState<string[]>();

  const setParams = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: Record<string, any> | undefined) => {
      if (agendaSettings.disable_url_params) {
        setFilterLocalState(state);
        return;
      }
      setFilterParams(state);
    },
    [agendaSettings.disable_url_params, setFilterParams, setFilterLocalState]
  );

  const params = React.useMemo(
    () => (agendaSettings.disable_url_params ? localStateParams : filterParams),
    [filterParams, localStateParams, agendaSettings.disable_url_params]
  );

  const resetSearch = React.useCallback(() => {
    setSelected([]);
    setParams({ ...params, [FILTER_PARAM_KEY]: null });
  }, [params, setParams]);

  React.useImperativeHandle(
    ref,
    () => {
      const impReturn: AgendaFilterHandle = {
        reset: () => resetSearch(),
      };
      return impReturn;
    },
    [resetSearch]
  );

  React.useEffect(() => {
    if (selected === undefined) {
      if (params?.[FILTER_PARAM_KEY]) {
        setSelected([...params[FILTER_PARAM_KEY], ...preSelect]);
        return;
      }
      setSelected(preSelect);
    }
  }, [selected, params, preSelect]);

  const filteredCategories = useMemo(() => {
    const cats: Category_Tree_Element[] = [];
    agendaSettings?.categories?.forEach((settingsCategory: { id: string }) => {
      const cat = categories.find((c) => c.id === settingsCategory?.id);
      if (cat) {
        cats.push(cat);
      }
    });

    // Sort

    return cats;
  }, [categories, agendaSettings]);
  const updateSelection = (tag: TagSelectionTagProps) => {
    const { value } = tag;
    if (selected === undefined) return;
    const isFilterAlreadyAdded = selected.includes(value);
    const updatedSelection = isFilterAlreadyAdded
      ? selected.filter((tagSlug) => tagSlug !== value)
      : [...selected, value];

    setSelected(updatedSelection);
    setParams({
      ...params,
      [FILTER_PARAM_KEY]: updatedSelection.length ? updatedSelection : undefined,
    });
    AnalyticsTransformer.customEvent([
      isFilterAlreadyAdded ? 'reset-filter' : 'set-filter',
      JSON.stringify({
        key: tag.value,
        label: tag.label,
      }),
    ]);
  };

  useEffect(() => {
    selected && onChange && onChange(selected);
  }, [selected, onChange]);

  return (
    <Container className="cue-agenda-filter-header-wrapper">
      <Heading type="h5">{t('agenda.filters')}</Heading>
      <AgendaFilterDropdown
        agendaSearchRef={agendaSearchRef}
        agendaDisplayOptions={agendaDisplayOptions}
        events={events}
        myScheduleOpened={myScheduleOpened}
        scheduleEventsCount={scheduleEventsCount}
        onToggleMySchedule={onToggleMySchedule}
        onToggleSuggestions={onToggleSuggestions}
        suggestionsActive={suggestionsActive}
        items={filteredCategories.map((fc) => ({
          title: fc.title,
          icon: fc.icon?.component,
          children: fc.children.map((c) => ({
            label: c.title,
            value: c.slug,
          })),
        }))}
        onSearched={onSearched}
        onChange={(tag) => updateSelection(tag)}
        clearAll={() => {
          setSelected([]);
          setParams({ ...params, [FILTER_PARAM_KEY]: null });
        }}
        onApply={() => onApply && onApply()}
        selected={selected}
      />
      {children && children}
    </Container>
  );
});

AgendaFilterHeader.displayName = 'AgendaFilter';

export type AgendaFilterHeaderCSSOverride = {
  '.cue-agenda-filter-header-wrapper'?: CSSObject;
  '.cue-agenda-filter-header-tag-selection'?: CSSObject;
};
export interface AgendaFilterHeaderProps {
  agendaSettings: IAgenda;
  preSelect?: TagSelectionTagProps['value'][];
  onChange?(selected: TagSelectionTagProps['value'][]): void;
  children?: ReactNode;
  agendaDisplayOptions: AgendaDisplayOptions;
  events: ProgramEvent[];
  onSearched: (search: { searchTerm: string; result: unknown[] }) => void;
  agendaSearchRef: RefObject<AgendaSearchRef>;
  onToggleMySchedule: (active: boolean) => void;
  onToggleSuggestions: (active: boolean) => void;
  suggestionsActive?: boolean;
  myScheduleOpened?: boolean;
  scheduleEventsCount?: number;
  navigate?: NavigateFunction;
  location?: WindowLocation;
  onApply: () => void;
}
const Container = styled.div``;
