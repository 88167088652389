import { eventConfig } from '../../../config';
import { PersonalSchedule } from '../schedule';
import { PrintAgenda } from './print-agenda';
import { TimezoneSwitch } from './timezone-switch';
import { agendaTransformer } from './util/agenda-transformer';
import { ProgramEvent } from '@cue/api';
import { Button, LoadingSpinner } from '@cue/atoms';
import { useQueryParams } from '@cue/hooks';
import { TagSelection, TagSelectionMode } from '@cue/molecules';
import { SlideIn, useSlideIn } from '@cue/organisms';
import { AnalyticsTransformer, TimeUtil } from '@cue/utility';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { EventFilter } from '@project/cms/modules';
import { CMS_Content_Module_Agenda, IAgenda } from '@project/cms/modules/agenda';
import { useAgendaSettings } from '@project/hooks/use-agenda-settings';
import { useCategories } from '@project/hooks/use-categories';
import { useEvents } from '@project/hooks/use-events';
import { UseEventOptions } from '@project/hooks/use-events/types';
import { useResponsive } from '@project/hooks/use-responsive';
import { useUser } from '@project/hooks/use-user';
import { useUserSubscriptions } from '@project/hooks/use-user-subscriptions';
import {
  AgendaEventObserved as AgendaEvent,
  AgendaFilterHandle,
} from '@project/site/modules/agenda';
import { AgendaDateHeader } from '@project/site/modules/agenda/agenda-date-header';
import { AgendaFilterHeader } from '@project/site/modules/agenda/agenda-filter-header';
import { AgendaSearchRef } from '@project/site/modules/agenda/agenda-search';
import { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const dayjs = TimeUtil.getDayjs();

enum ContentType {
  Agenda,
  MySchedule,
}

type FilterFunction = (events: ProgramEvent[]) => ProgramEvent[];
type FilterGroup = { parentId: string | null; filter: string[] };

const suggestionsEventsFilter: EventFilter = {
  id: 'suggestions',
  name: 'Suggestions',
  json: {
    and: [
      {
        isInUsersInterests: [
          {
            var: 'categories',
          },
        ],
      },
      {
        userHasNotBookmarked: [
          {
            var: 'id',
          },
        ],
      },
      {
        userIsNotParticipant: [
          {
            var: 'id',
          },
        ],
      },
    ],
  },
};

const personalEventsFilter: EventFilter = {
  id: 'personal-events',
  name: 'Personal Events',
  json: {
    or: [
      {
        userHasBookmarked: [
          {
            var: 'id',
          },
        ],
      },
      {
        userIsParticipant: [
          {
            var: 'id',
          },
        ],
      },
    ],
  },
};

export const Agenda: React.FC<AgendaProps> = ({
  slug,
  navigate,
  location,
  displayOptions: displayoptionsProp,
  agendaSettingsOverride,
}) => {
  const { getAgendaBySlug } = useAgendaSettings();
  const agendaSettings = React.useMemo(
    () =>
      agendaSettingsOverride
        ? { ...getAgendaBySlug(slug), ...agendaSettingsOverride }
        : getAgendaBySlug(slug),
    [agendaSettingsOverride, getAgendaBySlug, slug]
  );

  const agendaDisplayOptions = React.useMemo(() => {
    const customDisplayOptions = agendaSettings?.display_options;
    try {
      return displayoptionsProp
        ? { ...defaultAgendaDisplayOptions, ...displayoptionsProp }
        : customDisplayOptions
        ? mergeCustomDisplayOptions(defaultAgendaDisplayOptions, customDisplayOptions)
        : defaultAgendaDisplayOptions;
    } catch (error) {
      console.error(error);
      return defaultAgendaDisplayOptions;
    }
  }, [agendaSettings, displayoptionsProp]);

  const defaultEventOptions: UseEventOptions = {
    slug: agendaSettings?.slot_pattern?.map((sp: { slug: string }) => sp.slug),
    filter: agendaSettings?.event_filter,
  };
  const [eventOptions, setEventOptions] = useState(defaultEventOptions);
  const suggestionActiveRef = useRef(false);
  const { events, isLoading } = useEvents(eventOptions);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeDate, setActiveDate] = useState<Dayjs>();
  const [searchValue, setSearchValue] = useState('');
  const [searchedResult, setSearchedResult] = useState<ProgramEvent[] | undefined>(events);
  const agendaSearchRef = useRef<AgendaSearchRef>(null);
  const { categoriesFlat } = useCategories();
  const [queryParams] = useQueryParams();

  const { bookmarks, participations } = useUserSubscriptions();
  const { isAuthenticated } = useUser();
  const [slectedCategories, setSelectedCategories] = useState<string[]>([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const [showBookmarksOnly, setshowBookmarksOnly] = useState(false);
  const { isMobile } = useResponsive();
  const [slideInRef] = useSlideIn();

  const [showContent, setShowContent] = useState<ContentType>(() =>
    agendaSettings?.initial_schedule_open ? ContentType.MySchedule : ContentType.Agenda
  );
  /*   useEffect(() => {
    if (isMobile) slideInRef.current?.open();
  }, []); */

  const scheduleEventsCount =
    (bookmarks?.data?.length || 0) +
    (participations?.data ? Object.keys(participations.data).length : 0);

  const isAgendaActive = showContent === ContentType.Agenda;
  const isMyScheduleActive = showContent === ContentType.MySchedule;
  const switchView = (contentType?: ContentType) => {
    if (!contentType) {
      setShowContent(ContentType.Agenda);
      return;
    }
    setShowContent(contentType);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSearched = ({ result, searchTerm }: any) => {
    setSearchedResult(result as ProgramEvent[]);
    setSearchValue(searchTerm);
  };

  const onToggleSuggestions = (active: boolean) => {
    suggestionActiveRef.current = active;

    setEventOptions((currentEventOptions) => {
      return active
        ? {
            ...currentEventOptions,
            filter: suggestionsEventsFilter,
          }
        : defaultEventOptions;
    });
  };

  const onSearchReset = () => {
    setSearchValue('');
    setSearchedResult(events);
  };

  React.useEffect(() => {
    if (isMyScheduleActive) {
      setEventOptions((currentEventOptions) => ({
        ...currentEventOptions,
        filter: personalEventsFilter,
      }));
    }
  }, [isMyScheduleActive]);

  const eventData = useMemo(() => {
    const filterGroups: FilterGroup[] = slectedCategories.reduce((res, selectedCatSlug) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cat: any | null =
        categoriesFlat.find((c: { slug: string }) => c.slug === selectedCatSlug) || null;
      if (cat) {
        const parentId = cat?.parent_id;
        const filterGroupIndex = res.findIndex((fGroup) => fGroup.parentId === parentId);

        if (filterGroupIndex === -1) {
          return [
            ...res,
            {
              parentId,
              filter: [cat.slug],
            },
          ];
        }

        res[filterGroupIndex].filter.push(cat.slug);
      }

      return res;
    }, [] as FilterGroup[]);

    // Filter by category
    const filter: FilterFunction[] = [];

    if (showBookmarksOnly) {
      filter.push((events: ProgramEvent[]) => {
        return events.filter((ev) => bookmarks.data?.includes(ev.id));
      });
    }

    // categorie
    filterGroups.forEach((fg) => {
      filter.push((events: ProgramEvent[]) => {
        if (slectedCategories.length < 1) return events;
        return events?.filter((event) => {
          const eventCategorySlugs = event?.categories?.map((category) => category.slug);
          return eventCategorySlugs?.some((catSlug) => fg.filter.includes(catSlug));
        });
      });
    });
    return agendaTransformer(
      searchedResult || (events as ProgramEvent[]),
      suggestionActiveRef.current ? [] : filter,
      agendaSettings?.additional_category_sorting
    );
  }, [
    agendaSettings?.additional_category_sorting,
    bookmarks.data,
    categoriesFlat,
    events,
    searchedResult,
    showBookmarksOnly,
    slectedCategories,
  ]);

  useEffect(() => {
    if (showBookmarksOnly && !bookmarks.data?.length) {
      setshowBookmarksOnly(false);
    }
  }, [showBookmarksOnly, bookmarks.data]);

  useEffect(() => {
    if (eventData) {
      const activeTab = eventData.dates.findIndex((tabDate) =>
        dayjs(tabDate).isSame(activeDate, 'day')
      );

      setActiveTabIndex(activeTab >= 0 ? activeTab : 0);
    }
  }, [eventData, activeTabIndex, activeDate]);

  const filterRef = React.useRef<AgendaFilterHandle>(null);
  function resetFilters() {
    filterRef.current?.reset();
  }

  // handle uid param
  React.useEffect(() => {
    if (!queryParams.uid) return;
    if (!eventData.events?.length) return;

    const tabIndex = eventData.events.findIndex((eventArray) =>
      eventArray.find((event) => event.id === queryParams.uid)
    );

    if (tabIndex >= 0) {
      setActiveTabIndex(tabIndex);
      setActiveDate(eventData.dates[tabIndex]);
      setTimeout(() => {
        const el = document.getElementById(queryParams.uid);
        if (el) {
          const rect = el?.getBoundingClientRect();
          window.scrollTo({
            top: rect.top - 20,
            // behavior: 'smooth',
          });
        }
      }, 50);
    }
  }, [queryParams.uid, eventData]);

  const calcuateVodTimes = (data: ProgramEvent[], offset = 0) => {
    const startTime = TimeUtil.getUtc(data[0].start);
    let ret = '';
    data.forEach((event) => {
      const off = TimeUtil.getUtc(event.start).diff(startTime) / 1000 + offset;
      const seconds = new Date(off * 1000).toISOString().slice(11, 19);
      console.log(
        'eventData',
        TimeUtil.getUtc(event.start).format(),
        TimeUtil.getUtc(event.start).diff(startTime)
      );
      ret += `${event.id}; ${event.title};${off};${seconds} \n`;
    });
    console.log('eventData', ret);

    console.log();
  };

  useEffect(() => {
    if (eventData?.events[0]) {
      const vodTimes = calcuateVodTimes(eventData?.events[0] as ProgramEvent[0], 2715);
    }
  }, [eventData]);

  if (!eventData) return <></>;

  const Filter = (
    <AgendaFilterHeader
      ref={filterRef}
      scheduleEventsCount={scheduleEventsCount}
      myScheduleOpened={isMyScheduleActive}
      agendaSearchRef={agendaSearchRef}
      events={events as ProgramEvent[]}
      agendaDisplayOptions={agendaDisplayOptions}
      onChange={setSelectedCategories}
      onSearched={onSearched}
      onToggleMySchedule={(active) => switchView(active ? ContentType.MySchedule : undefined)}
      onToggleSuggestions={onToggleSuggestions}
      suggestionsActive={suggestionActiveRef.current}
      agendaSettings={agendaSettings as IAgenda}
      navigate={navigate}
      location={location}
      onApply={() => slideInRef?.current?.close()}>
      {searchValue && (
        <TagSelection
          key={searchValue} /* Force Render When result changes */
          selected={[searchValue]}
          label={t('agenda.searchResultsFor') || ''}
          tags={[{ label: searchValue, value: searchValue }]}
          mode={TagSelectionMode.ADD_REMOVE}
          onChange={(_, values) => {
            onSearchReset();
            if (values.length < 1) agendaSearchRef?.current?.reset?.();
          }}
        />
      )}
    </AgendaFilterHeader>
  );

  return (
    <AgendaContainer className="cue-agenda-container">
      {agendaSettings && agendaDisplayOptions.filter && !isMobile && Filter}
      {agendaSettings?.initial_schedule_open && !isMobile && (
        <ChangeYourSchedule navigate={navigate} />
      )}
      <AgendaHeader className="cue-agenda-header-wrapper" style={{ position: 'sticky', top: 0 }}>
        <AgendaDateHeader
          dates={eventData.dates}
          activeIndex={activeTabIndex}
          onDateChange={(i, date) => {
            setActiveTabIndex(i);
            date && setActiveDate(date);
          }}
        />

        {agendaDisplayOptions.timezoneSwitch && (
          <div className="cue-agenda-action-btns">
            {isAuthenticated && agendaDisplayOptions.timezoneSwitch && <TimezoneSwitch />}
          </div>
        )}
      </AgendaHeader>

      <MobileSwitch>
        <MobileSwitchInner>
          <Button
            iconAlign="left"
            icon="clock"
            styling={isAgendaActive ? 'agendaMobileSwitchActive' : 'agendaMobileSwitch'}
            onClick={() => {
              switchView(ContentType.Agenda);
            }}>
            {t('agenda.agenda')}
          </Button>
          {isAuthenticated && (
            <Button
              iconAlign="left"
              icon={scheduleEventsCount ? 'favouriteMarked' : 'favourite'}
              styling={isMyScheduleActive ? 'agendaMobileSwitchActive' : 'agendaMobileSwitch'}
              onClick={() => {
                switchView(ContentType.MySchedule);
              }}>
              {t('agenda.myschedule')} {scheduleEventsCount ? `(${scheduleEventsCount})` : null}
            </Button>
          )}
        </MobileSwitchInner>
        <Button
          styling="agendaMobileFilter"
          icon="filter"
          onClick={() => slideInRef.current?.open()}></Button>
        <SlideIn
          ref={slideInRef}
          width={'100vw'}
          onVisibilityChange={(visibility) => {
            if (visibility) {
              document.body.classList.add('no-scroll');
            } else {
              document.body.classList.remove('no-scroll');
            }
          }}
          overrideCSS={{
            '.cue-slide-in-container': {
              paddingTop: '80px',
              background: theme.colors.bodyBackground,
              height: '100%',
              overflow: 'hidden',
            },
            '.cue-modal-close-button-wrapper': {
              right: '30px',
              position: 'fixed',
            },
            '.cue-slide-in-content': {
              height: '100%',
              marginTop: '-54px',
            },
          }}
          customCloseRenderer={({ onClose }) => (
            <Button
              icon="close"
              styling="modalClosePlain"
              onClick={() => {
                AnalyticsTransformer.customEvent(['agendaFilter', `close`]);
                onClose && onClose();
              }}
            />
          )}>
          <MobileFilterWrapper className="cue-agenda-mobile-filter">{Filter}</MobileFilterWrapper>
        </SlideIn>
      </MobileSwitch>

      {isLoading ? (
        <AgendaNoEventsFilter>
          <LoadingSpinner />
        </AgendaNoEventsFilter>
      ) : (
        <AgendaInnerWrapper showSchedule={isMyScheduleActive}>
          {eventData.events.length === 0 && (
            <AgendaNoEventsFilter className="agenda-no-results">
              <p>{t('agenda.noresults')}</p>
              {slectedCategories.length > 0 && (
                <Button styling="primary" onClick={() => resetFilters()}>
                  {t('agenda.noresults-reset')}
                </Button>
              )}
            </AgendaNoEventsFilter>
          )}
          {(!isMobile || isAgendaActive) && eventData.events.length > 0 && (
            <EventsWrapper className="cue-agenda-events-wrapper">
              {eventData.events[activeTabIndex]?.map((event) => (
                <AgendaEvent
                  agendaDisplayOptions={agendaDisplayOptions}
                  isMarked={Boolean(queryParams.uid && queryParams.uid === event.id)}
                  openVod={Boolean(queryParams['start-vod'] && queryParams.uid === event.id)}
                  key={event.id}
                  locationCategories={agendaSettings?.location_categories}
                  hideLocation={agendaSettings?.hide_location || false}
                  isVodVisibleFlag={agendaSettings?.vod_visibility_condition?.slug}
                  {...event}
                />
              ))}
            </EventsWrapper>
          )}

          {isMyScheduleActive && (
            <div style={{ gridRow: 1 }}>
              {agendaSettings?.initial_schedule_open && isMobile && <ChangeYourSchedule />}
              <PersonalSchedule day={eventData.dates[activeTabIndex]?.toISOString()} />
            </div>
          )}
        </AgendaInnerWrapper>
      )}
      <PrintAgenda
        eventData={eventData}
        activeTabIndex={activeTabIndex}
        agendaDisplayOptions={agendaDisplayOptions}
        agendaSettings={agendaSettings}
        showAll={isMyScheduleActive}
      />
    </AgendaContainer>
  );
};

const ChangeYourSchedule: React.FC<{ navigate?: NavigateFunction }> = ({ navigate }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const agendaUrl = eventConfig.mainAgendaUrl?.[i18n.language];
  if (!agendaUrl) return null;
  return (
    <div className="cue-agenda-change-your-schedule">
      <Button
        styling="readMore"
        icon="edit"
        onClick={() => (navigate ? navigate(agendaUrl) : window.open(agendaUrl))}>
        {t('agenda.changeYourSchedule')}
      </Button>
    </div>
  );
};

export type AgendaDisplayOptions = {
  filter?: boolean;
  bookmarksFilterToggle?: boolean;
  timezoneSwitch?: boolean;
  search?: boolean;
  timezone?: boolean;
  locationTag?: boolean;
  categories?: boolean;
  speakerPreview?: boolean;
  speakerList?: boolean;
  speakerAgenda?: boolean;
  readmoreOpen?: boolean;
  signUpButtons?: boolean;
  sideButtons?: AgendaSideButtonDisplayOptions | false;
  scheduleOpen?: boolean;
};

export type AgendaProps = Omit<CMS_Content_Module_Agenda['data']['agenda'], 'id'> & {
  navigate?: NavigateFunction;
  location?: WindowLocation;
  agendaSettingsOverride?: Partial<IAgenda>;
  displayOptions?: AgendaDisplayOptions;
};

export type AgendaSideButtonDisplayOptions = {
  bookmark?: boolean;
  shareViaMail?: boolean;
  play?: boolean;
  iCal?: boolean;
  documentDownload?: boolean;
};

export type NavigateFunction = (url: string) => void;
export type WindowLocation = {
  ancestorOrigins: DOMStringList;
  hash: string;
  host: string;
  hostname: string;
  href: string;
  origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
  assign(url: string): void;
};

export const defaultAgendaDisplayOptions: AgendaDisplayOptions = {
  filter: true,
  bookmarksFilterToggle: true,
  timezoneSwitch: true,
  search: true,
  timezone: true,
  locationTag: true,
  categories: true,
  speakerPreview: true,
  speakerList: true,
  speakerAgenda: true,
  readmoreOpen: false,
  signUpButtons: true,
  scheduleOpen: true,
  sideButtons: {
    documentDownload: true,
    bookmark: true,
    shareViaMail: true,
    play: true,
    iCal: true,
  },
};

export function mergeCustomDisplayOptions(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>,
  customDisplayOptions: string[] = [],
  prevKeys: string[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const output: Record<string, any> = {};

  for (const key in obj) {
    if (typeof obj[key as keyof AgendaDisplayOptions] === 'object') {
      output[key] = mergeCustomDisplayOptions(obj[key], customDisplayOptions, [...prevKeys, key]);
    } else {
      const optionKeyWithDotSyntax = [...prevKeys, key].join('.');
      output[key] = customDisplayOptions.includes(optionKeyWithDotSyntax);
    }
  }

  return output;
}

const AgendaNoEventsFilter = styled.div({
  textAlign: 'center',
  padding: 40,
  background: 'rgba(255,255,255,.1)',
  gridRow: 1,
});

const AgendaContainer = styled.div({});

const EventsWrapper = styled.div(() => ({ gridRow: 1 }));

const AgendaInnerWrapper = styled.div<{ showSchedule: boolean }>(
  ({ showSchedule, theme: { mq } }) => ({
    [mq?.['md']]: {
      display: 'grid',
      gridTemplateColumns: showSchedule ? 'repeat(2, 1fr)' : undefined,
      gridColumnGap: '20px',
    },
  })
);

const AgendaHeader = styled.div``;

const MobileSwitch = styled.div(({ theme: { mq } }) => ({
  display: 'flex',
  marginBottom: 16,
  justifyContent: 'space-between',
  [mq?.['md']]: {
    display: 'none',
  },
}));

const MobileSwitchInner = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  alignSelf: 'flex-start',
}));

const MobileFilterWrapper = styled.div();
