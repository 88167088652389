import { CMS_Content_Module_VirtualExperienceData } from './types';
import { Button, Label } from '@cue/atoms';
import { useFullscreen, useHash } from '@cue/hooks';
import { ExternalContent } from '@cue/molecules';
import { Modal, useModal } from '@cue/organisms';
import { StyledComponent } from '@cue/theme';
import { AnalyticsTransformer } from '@cue/utility';
import {
  IConfigDto,
  INavigationMenuItems,
  IVideoAction,
  IVideoEvent,
  IVideoScene,
  TDisplayMode,
  VideoEventType,
  VideoPlatformContainer,
  VideoPlatformContextProvider,
  VideoPlatformRef,
} from '@cuevideostory/core';
import { VideoTour } from '@cuevideotour/core';
import {
  VirtualTourConfig,
  VirtualTourConfigSource,
  VirtualTourStepSource,
} from '@cuevideotour/core/dist/video-tour/types';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import videoConfigJSON from '@project/local/video-story.json';
import tourConfigJSON from '@project/local/virtual-tours.json';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

export const SHOWROOM_TRACKING_PRE = 'experience';

const parseUrls = (config: IConfigDto) => {
  return config;
};
const loadedConfig = parseUrls(videoConfigJSON as unknown as IConfigDto);
const parseTourConfig = (
  { tours, toursteps }: VirtualTourConfigSource,
  lang: string
): VirtualTourConfig => {
  return {
    toursteps,
    tours: tours?.map((tour) => {
      return {
        id: tour.id,
        title:
          tour?.translations.find(({ languages_code }) => languages_code?.code?.includes(lang))
            ?.title || '',
        steps: parseTourSteps(tour.steps, lang),
      };
    }),
  };
};
const parseTourSteps = (scenes: VirtualTourStepSource[], lang: string) => {
  return scenes.map((scene) => {
    const trans = scene?.translations.find(({ languages_code }) =>
      languages_code?.code?.includes(lang)
    );
    return {
      id: scene.id,
      sort: scene?.sort,
      scene: scene?.scene,
      title: trans?.title,
      tour_title: trans?.tour_title,
      description: trans?.description,
      audio_description: trans?.audio_description,
    };
  });
};
export interface ShowroomTrackingBase {
  sessionId: string;
}
export const VirtualExperience: React.FC<
  VirtualExperienceProps & StyledComponent<VirtualExperienceCSSOverride>
> = ({
  disableAudio,
  disableTourDescriptions,
  disableFullscreen,
  video_app: { start_scene: default_start_scene },
  start_scene,
  url_pattern,
  ratio = '16/9',
  displayMode = 'contain',
}) => {
  const [tourTitle, setTourTitle] = useState<string | undefined>();
  const [trackingBase, setTrackingbase] = useState<ShowroomTrackingBase | null>({
    sessionId: uuidv4(),
  });
  const [trackExit, setTrackExit] = useState(true);
  const [trackingStarted, setTrackingStarted] = useState(false);
  const [currentTour, setCurrentTour] = useState<string | null>();
  const [fairtouchUrl, setFairtouchUrl] = useState('');
  const fsRef = useRef<HTMLDivElement>(null);
  const [startScene] = useState<string>(start_scene.id ? start_scene.id : default_start_scene.id);
  const [currentScene, setCurrentScene] = useState<IVideoScene>();
  const { hash, updateHash } = useHash();
  const videoPlatformRef = useRef<VideoPlatformRef>();
  const [modalRef] = useModal();
  const { exit: exitFullscreen, isFullscreen } = useFullscreen();
  const {
    i18n: { language },
  } = useTranslation();
  const loadedTourConfig = parseTourConfig(
    tourConfigJSON as unknown as VirtualTourConfigSource,
    language
  );

  const onVideoEvent = (event: IVideoEvent) => {
    // Dont track loop videos
    if (!event.video?.loop) {
      const tourId =
        (tourTitle && loadedTourConfig?.toursteps?.[event.scene.sceneKey]?.tourId) || null;
      if (trackingStarted && event.event === VideoEventType.VideoEnd) {
        AnalyticsTransformer.customEvent([
          SHOWROOM_TRACKING_PRE,
          `enterScene||${JSON.stringify({
            ...trackingBase,
            sceneName: event.scene.name,
            sceneId: event.scene.sceneKey,
            tourId,
          })}`,
        ]);
        setTrackExit(true);
      }
      if (
        trackingStarted &&
        event.event === VideoEventType.VideoStart &&
        event?.scene?.name &&
        event?.scene?.sceneKey
      ) {
        if (trackExit) {
          AnalyticsTransformer.customEvent([
            SHOWROOM_TRACKING_PRE,
            `exitScene||${JSON.stringify({
              ...trackingBase,
              sceneName: event.scene.name,
              sceneId: event.scene.sceneKey,
              tourId,
            })}`,
          ]);
        }
        setTrackExit(false);
      }
    }
  };

  const onAction = ({ action, params }: IVideoAction) => {
    if (action) {
      const url = params.href[language];
      switch (action) {
        case 'fairtouch':
          setFairtouchUrl(url);

          AnalyticsTransformer.customEvent([
            SHOWROOM_TRACKING_PRE,
            `openContent||${JSON.stringify({
              ...trackingBase,
              sceneName: currentScene?.name,
              sceneId: currentScene?.sceneId,
              contentId: params.linkId,
            })}`,
          ]);
          break;
        case 'modal':
          setFairtouchUrl(url);

          AnalyticsTransformer.customEvent([
            SHOWROOM_TRACKING_PRE,
            `openContent||${JSON.stringify({
              ...trackingBase,
              sceneName: currentScene?.name,
              sceneId: currentScene?.sceneId,
              contentId: params.linkId,
            })}`,
          ]);
          break;
        case 'https':
          window.open(url);
          exitFullscreen();

          AnalyticsTransformer.customEvent([
            SHOWROOM_TRACKING_PRE,
            `openLink||${JSON.stringify({
              ...trackingBase,
              sceneName: currentScene?.name,
              sceneId: currentScene?.sceneId,
              url,
            })}`,
          ]);

          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (url_pattern) {
      const match = document.location.pathname.match('/[A-Za-z]+/showroom/([A-Za-z0-9-]+)/');
      if (match?.[1]) {
        updateHash(match[1]);
      }
    }
  }, [url_pattern, updateHash]);

  useEffect(() => {
    if (fairtouchUrl) {
      modalRef.current?.open();
    } else {
      modalRef.current?.close();
    }
  }, [fairtouchUrl, modalRef]);

  const initTracking = () => {
    if (!trackingStarted) {
      AnalyticsTransformer.customEvent([
        SHOWROOM_TRACKING_PRE,
        `startSession||${JSON.stringify({
          ...trackingBase,
          sceneId: 0,
          sceneName: startScene,
        })}`,
      ]);
    }
    setTrackingStarted(true);
  };

  useEffect(() => {
    if (fsRef?.current) {
      fsRef.current.addEventListener('click', initTracking);
    }
    return () => {
      if (fsRef?.current) {
        fsRef.current.removeEventListener('click', initTracking);
      }
    };
  }, [fsRef, trackingStarted]);

  useEffect(() => {
    if (!hash?.trim()) {
      updateHash('6d3e73b3-f58e-42fa-8403-b7aaa1734946');
    }
  }, [hash]);

  const secondaryMenuExlude = [
    INavigationMenuItems.HOME,
    INavigationMenuItems.BACK,
    INavigationMenuItems.QUIT,
    INavigationMenuItems.LANGUAGE,
    INavigationMenuItems.HISTORYBACK,
  ];
  if (disableAudio) {
    secondaryMenuExlude.push(INavigationMenuItems.SOUND);
  }
  if (disableFullscreen) {
    secondaryMenuExlude.push(INavigationMenuItems.FULLSCREEN);
  }

  return (
    <VirtualExperienceWrapper className="cue-virtual-experience-wrapper">
      <a id="virtual-experience" className="cue-content-module-anchor"></a>
      <VideoPlatformContextProvider>
        <FullscreenWrapper ref={fsRef} ratio={ratio}>
          <div
            className={`cue-virtual-experience-fairtouch-modal-container ${
              isFullscreen ? 'cue-virtual-experience-fairtouch-modal-container-fullscreen' : ''
            } `}>
            <Modal
              ref={modalRef}
              className="fairtouch"
              maxWidth={'100vw'}
              renderInPlace={isFullscreen}
              onVisibilityChange={(show) => {
                if (!show) setFairtouchUrl('');
              }}
              customCloseRenderer={({ onClose }) => (
                <Button
                  icon="close"
                  styling="modalClosePlain"
                  onClick={() => {
                    setFairtouchUrl('');
                  }}
                />
              )}>
              <div className="cue-virtual-experience-fairtouch-modal">
                <ExternalContent uri={fairtouchUrl} />
              </div>
            </Modal>
          </div>
          <VideoPlatformContainer
            ref={videoPlatformRef}
            config={loadedConfig}
            disableAudio={disableAudio}
            dimSound={Boolean(fairtouchUrl)}
            startSceneKey={startScene}
            initSceneKey={'6d3e73b3-f58e-42fa-8403-b7aaa1734946'}
            showSecondaryMenu={true}
            showPrimaryMenu={true}
            fsWrapper={fsRef}
            onSceneChanged={(event) => {
              setCurrentScene(event.scene);
            }}
            onVideoEvent={onVideoEvent}
            displayMode={displayMode}
            onAction={onAction}
            mobileActionIconsBottom={true}
            primaryMenuExtraRenderer={
              tourTitle && loadedTourConfig?.toursteps?.[currentScene?.sceneKey] ? (
                <Label>{tourTitle}</Label>
              ) : (
                <></>
              )
            }
            primaryMenuExclude={[
              INavigationMenuItems.LANGUAGE,
              INavigationMenuItems.SOUND,
              INavigationMenuItems.FULLSCREEN,
              INavigationMenuItems.QUIT,
              ...[currentScene?.sceneKey === startScene && INavigationMenuItems.HISTORYBACK],
            ]}
            secondaryMenuExclude={secondaryMenuExlude}
            buttonSafeZone={[20, 20, 20, 20]}
          />
          <VideoTour
            onChangeTour={(tourstep) => {
              setTourTitle(tourstep?.tour_title || '');
              if (loadedTourConfig?.toursteps?.[currentScene?.sceneKey]) {
                // ENTER TOUR
                if (!currentTour) {
                  AnalyticsTransformer.customEvent([
                    SHOWROOM_TRACKING_PRE,
                    `enterTour||${JSON.stringify({
                      ...trackingBase,
                      tourName: tourstep?.tour_title,
                      tourId: tourstep?.tour_id,
                    })}`,
                  ]);
                }
                if (tourstep) setCurrentTour(tourstep.tour_id);
              } else {
                // EXIT TOUR

                setCurrentTour(null);
              }
            }}
            onExitTour={({ is_last }) => {
              if (currentTour && is_last) {
                AnalyticsTransformer.customEvent([
                  SHOWROOM_TRACKING_PRE,
                  `exitTour||${JSON.stringify({
                    ...trackingBase,
                    tourId: currentTour,
                  })}`,
                ]);
              }
              setCurrentTour(null);
            }}
            config={loadedTourConfig}
            displayMode={displayMode}
            currentScene={currentScene}
            onChangeTourStep={updateHash}
            showDescriptions={!disableTourDescriptions}
          />
        </FullscreenWrapper>
      </VideoPlatformContextProvider>
    </VirtualExperienceWrapper>
  );
};
export type VirtualExperienceCSSOverride = {
  '.cue-virtual-experience-wrapper'?: CSSObject;
};
export interface VirtualExperienceProps extends CMS_Content_Module_VirtualExperienceData {
  showTitle?: boolean;
  fallback?: React.ReactNode;
  disableAudio?: boolean;
  disableFullscreen?: boolean;
  disableTourDescriptions?: boolean;
  ratio?: string;
  displayMode?: TDisplayMode;
}

const VirtualExperienceWrapper = styled.div``;
const FullscreenWrapper = styled.div<{ ratio?: string }>(
  ({ ratio }) => `
    position:relative;
    aspect-ratio:${ratio || '16/9'};
    ${ratio === 'auto' ? 'height:100%;' : ''}
    &:fullscreen{
      aspect-ratio:inherit;
    }

  `
);
