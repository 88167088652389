import { LivestreamIssue } from '../components/livestream-issue';
import { AdditionalExampleModule } from './additional-expample-module';
import { SurveyWithEventTeaser } from './survey-with-event-teaser';
import { WelcomeText } from './welcome-text';
import { CMS_Welcome_Text_Content_Module_Data } from './welcome-text/types';
import { YourNextEvents } from './your-next-events';
import { CmsModuleNotFound } from '@project/cms/content-sections/content-block';

export const AdditionalCMSContentBlock: React.FC<AdditionalCMSContentBlockProps> = (props) => {
  const { data, type } = props;
  const moduleType = String(type);

  if ((Object.values(CMS_Content_Modules_Enum_Additional) as string[]).includes(moduleType)) {
    switch (moduleType as CMS_Content_Modules_Enum_Additional) {
      case CMS_Content_Modules_Enum_Additional.CMS_Example_Content_Module: {
        return <AdditionalExampleModule {...(data as CMS_Example_Content_Module_Data)} />;
      }
      // add a case for your new CMS content module here

      case CMS_Content_Modules_Enum_Additional.CMS_Custom_Content_Module: {
        switch (data?.identifier) {
          case 'welcome_text':
            return <WelcomeText />;
          case 'livestream_issue':
            return <LivestreamIssue />;
        }
        break;
        /* return <AdditionalExampleModule {...(data as CMS_Example_Content_Module_Data)} />; */
      }

      case CMS_Content_Modules_Enum_Additional.CMS_Your_Next_Events: {
        return <YourNextEvents {...(data as CMS_Your_Next_Events_Content_Module_Data)} />;
      }

      case CMS_Content_Modules_Enum_Additional.CMS_Survey_With_EventTeaser: {
        return <SurveyWithEventTeaser {...(data as CMS_Survey_With_EventTeaser_Data)} />;
      }
    }
  }

  return <CmsModuleNotFound type={moduleType} />;
};

type AdditionalCMSContentBlockProps = {
  type: CMS_Content_Modules_Enum_Additional;
  data: CMS_Content_Modules_Data_Additional;
};

// add your new data type here
export type CMS_Content_Modules_Data_Additional = CMS_Example_Content_Module_Data &
  CMS_Welcome_Text_Content_Module_Data &
  CMS_Your_Next_Events_Content_Module_Data &
  CMS_Survey_With_EventTeaser_Data;

// add your new enum here
export enum CMS_Content_Modules_Enum_Additional {
  CMS_Example_Content_Module = 'content_module_added_example',
  CMS_Custom_Content_Module = 'content_module_custom',
  CMS_Your_Next_Events = 'content_module_your_next_events',
  CMS_Survey_With_EventTeaser = 'content_module_survey_with_event_teaser',
}
