import { EventTypeBehaviour, GoLocationString, eventConfig } from '../../../../config/event';
import { useFlag } from '../../../../providers/flags-provider';
import { AgendaEventProps } from '../agenda-event';
import { AgendaReservationFrom } from './reservation-form';
import { ProgramEvent } from '@cue/api';
import { Button, Tooltip } from '@cue/atoms';
import { SlideIn, useSlideIn } from '@cue/organisms';
import { StyledComponent } from '@cue/theme';
import { TimeUtil } from '@cue/utility';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { useEvents } from '@project/hooks/use-events';
import { useUserSubscriptions } from '@project/hooks/use-user-subscriptions';
import timezone from 'dayjs/plugin/timezone';
import diff from 'deep-diff';
import React from 'react';
import { useTranslation } from 'react-i18next';

// TODO: Move to core
const dayjs = TimeUtil.getDayjs();
dayjs.extend(timezone);

const TIMER_INTERVAL = 5000;

function getEventBehaviour(location: string | null): EventTypeBehaviour {
  const { defaultLocation, locationToBevahiourMap } = eventConfig;

  if (location && Object.keys(locationToBevahiourMap).includes(location)) {
    return locationToBevahiourMap[location as GoLocationString] as EventTypeBehaviour;
  }

  return locationToBevahiourMap[defaultLocation] as EventTypeBehaviour;
}

export const AgendaReservationButtons: React.FC<
  AgendaReservationButtonsProps & StyledComponent<AgendaReservationButtonsCSSOverride>
> = (props) => {
  const { eventId } = props;
  const { events } = useEvents();
  const { participations } = useUserSubscriptions();

  const meetingUrl = participations ? participations?.data[eventId]?.meetingURL : undefined;

  const event = React.useMemo(() => {
    return events?.find((ev) => ev.id === eventId);
  }, [eventId, events]);

  const { t } = useTranslation();
  const [slideInRef] = useSlideIn();

  const checkTimeDependentProperties = React.useCallback(() => {
    if (!event) {
      return {
        isFutureOrPresentEvent: false,
        isCancellable: false,
        isSessionAvailable: false,
      };
    }

    const start = TimeUtil.getUtc(event.start);
    const end = TimeUtil.getUtc(event.end);
    const now = TimeUtil.getNowUtc();

    const isFutureOrPresentEvent = end.add(eventConfig.sessionAvailable, 'minutes').isAfter(now);
    const isCancellable = start.add(eventConfig.reservationCancelable, 'minutes').isAfter(now);

    const isSessionAvailable =
      start.subtract(eventConfig.sessionAvailable, 'minutes').isBefore(now) &&
      end.add(eventConfig.sessionAvailable, 'minutes').isAfter(now);

    const currentState = {
      isFutureOrPresentEvent,
      isCancellable,
      isSessionAvailable,
    };
    return currentState;
  }, [event]);

  const [eventTimerState, setEventTimerState] = React.useState<EventTimerBooleans>(() =>
    checkTimeDependentProperties()
  );

  // Check time dependent
  React.useEffect(() => {
    if (!event) return;

    const interval = setInterval(() => {
      const newTimerPropsState = checkTimeDependentProperties();
      if (diff(eventTimerState, newTimerPropsState)) {
        setEventTimerState(newTimerPropsState);
        if (!newTimerPropsState.isFutureOrPresentEvent) {
          clearInterval(interval);
        }
      }
    }, TIMER_INTERVAL);

    return () => clearInterval(interval);
  }, [event, eventTimerState, checkTimeDependentProperties]);

  if (!event) return null;
  const { participants, location } = event;

  const eventBehaviour = getEventBehaviour(location);
  const isUserRegistered =
    Boolean(participations.data) && Object.keys(participations.data)?.includes(eventId);
  const capacityWarning = Boolean(
    participants?.capacityRemaining &&
      participants?.capacityRemaining < eventConfig.participationWarning
  );

  const eventButtonProps = {
    event,
    eventBehaviour,
    isUserRegistered,
    capacityWarning,
    url: meetingUrl,
    ...eventTimerState,
  };

  if (!eventTimerState?.isFutureOrPresentEvent) return null;

  return (
    <Container className="cue-agenda-reservation-wrapper">
      {isUserRegistered ? (
        <>
          {eventBehaviour === EventTypeBehaviour.OnlineSession && (
            <OnlineSessionEventButtons {...eventButtonProps} />
          )}

          {eventBehaviour === EventTypeBehaviour.Offline && (
            <OfflineEventButtons {...eventButtonProps} />
          )}

          {eventTimerState.isCancellable && (
            <Button styling="primary" onClick={() => slideInRef.current?.open()}>
              {t('agenda.cancel-reservation.label')}
            </Button>
          )}
        </>
      ) : (
        <>
          {participants?.capacityRemaining ? (
            <Button styling="primary" onClick={() => slideInRef.current?.open()}>
              {t('agenda.signup.label')}
            </Button>
          ) : (
            <Button styling="primary" disabled>
              {t('agenda.signup.label')}
            </Button>
          )}
          {participants?.capacityRemaining
            ? capacityWarning
              ? t('agenda.seats.few-available')
              : t('agenda.seats.available')
            : t('agenda.seats.not-available')}
        </>
      )}

      <SlideIn ref={slideInRef}>
        <AgendaReservationFrom
          eventId={eventId}
          onCancel={() => slideInRef.current?.close()}
          onReservation={() => slideInRef.current?.close()}
          onCancelReservation={() => slideInRef.current?.close()}
        />
      </SlideIn>
    </Container>
  );
};

type EventTimerBooleans = {
  isFutureOrPresentEvent: boolean;
  isCancellable: boolean;
  isSessionAvailable: boolean;
};

type EventButtonProps = EventTimerBooleans & {
  event: ProgramEvent;
  eventBehaviour: EventTypeBehaviour;
  isUserRegistered: boolean;
  capacityWarning: boolean;
  enableTestSession?: boolean | null;
  url?: string;
};

const OnlineSessionEventButtons: React.FC<EventButtonProps> = (props) => {
  const { t } = useTranslation();
  const enableTestSession = useFlag('event-enable-test-session');

  const { event, isUserRegistered, isSessionAvailable, url } = props;
  const joinSessionIsDisabledToolipId = `joins-session-is-disabled-${event.id}`;

  function joinMeeting(url: string) {
    if (url) {
      window && window.open(url, '_blank');
    }
  }

  return (
    <>
      {isUserRegistered && (
        <>
          {isSessionAvailable ? (
            <Button
              styling="primary"
              disabled={!isSessionAvailable}
              onClick={() => url && joinMeeting(url)}>
              {t('agenda.joinsession')}
            </Button>
          ) : (
            <>
              <div data-tooltip-id={joinSessionIsDisabledToolipId}>
                <Button styling="primary" disabled={!isSessionAvailable}>
                  {t('agenda.joinsession')}
                </Button>
              </div>

              <Tooltip place="top" variant="light" id={joinSessionIsDisabledToolipId}>
                {t('agenda.joinsessionIsDisabled')}
              </Tooltip>
            </>
          )}
          {enableTestSession && (
            <Button
              styling="secondary"
              onClick={() =>
                eventConfig.testSession ? joinMeeting(eventConfig.testSession) : undefined
              }>
              {t('agenda.jointestsession')}
            </Button>
          )}
        </>
      )}
    </>
  );
};

const OfflineEventButtons: React.FC<EventButtonProps> = () => {
  return null;
};

export type AgendaReservationButtonsCSSOverride = {
  '.cue-agenda-reservation-wrapper'?: CSSObject;
};
/* export const AgendaEvent: React.FC<AgendaEventProps & StyledComponent<AgendaEventCSSOverride>> = (
 */
export type AgendaReservationButtonsProps = { eventId: AgendaEventProps['id'] };
const Container = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
`;
