import { eventConfig } from '../../config/event';
import { useEventFilter } from '../use-event-suggestions/utilities/use-json-logic';
import { UseEventOptions } from './types';
import { ProgramEvent } from '@cue/api';
import { useProgramService } from '@project/cue-api/use-program-service';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

/* This should be THE ONLY place where we use the ProgramService.getProgram() is used! */
/* useEvents() will keept he events updated with minimal requests. */

/* eslint-disable cuenect-web-rules/no-direct-use-of-program-service */
export const useEvents = (options?: UseEventOptions) => {
  const { source: sourceFromProps, slug, showHidden, staticSource, filter } = options || {};
  const { i18n } = useTranslation();
  const source = eventConfig.sources?.[i18n.language];

  const ProgramService = useProgramService();

  const {
    data: events,
    isLoading,
    error,
    fetchStatus,
  } = useQuery({
    queryKey: ['events'],
    refetchOnMount: false,
    queryFn: () =>
      ProgramService?.getProgram({
        source: sourceFromProps || source || undefined,
        slug: slug,
        showHidden: showHidden,
        staticSource: staticSource,
      }) || [],
  });

  const [eventsFiltered, setEventsFiltered] = React.useState<ProgramEvent[]>();
  const { filterEvents } = useEventFilter();

  React.useEffect(() => {
    if (!events) return;
    if (!filter) {
      setEventsFiltered(events);
      return;
    }
    setEventsFiltered(filterEvents(events, filter?.json));
  }, [events, setEventsFiltered, filter, filterEvents]);

  return {
    events: eventsFiltered,
    isLoading,
    error,
    fetchStatus,
  };
};
/* eslint-enable cuenect-web-rules/no-direct-use-of-program-service */
